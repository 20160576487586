import React, { Component, useEffect, useState } from 'react';
import constants from '../Constants';
import Canonical from '../Canonical';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import SelectSearch from '../SelectSearch';
import { userSelectSearch, getSelectTutors } from '../redux/actions';

import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import parse from 'html-react-parser';
import DocumentMeta from 'react-document-meta';

function time(unixTimeStamp) {
    let date = new Date(+unixTimeStamp);
    return ('0' + date.getDate()).slice(-2) + '.' + ('0' + (date.getMonth() + 1)).slice(-2) + '.' + date.getFullYear() + ' ' + ('0' + date.getHours()).slice(-2) + ':' + ('0' + date.getMinutes()).slice(-2);
}
function kitcut(text, limit) {
    text = text.trim();
    if (text.length <= limit) return text;
    text = text.slice(0, limit); // тупо отрезать по лимиту
    let lastSpace = text.lastIndexOf(" ");
    if (lastSpace > 0) { // нашлась граница слов, ещё укорачиваем
        text = text.substr(0, lastSpace);
    }
    return text + "...";
}

function EditorApp() {
    // const parse = require('html-react-parser');
    const es = (localStorage.getItem('draftRaw') !== "undefined" && localStorage.getItem('draftRaw') !== null) ? JSON.parse(localStorage.getItem('draftRaw')) : ''
    const [editorState, setEditorState] = useState(() => EditorState.createEmpty()
        //(es)?convertFromRaw(es):EditorState.createEmpty()
    );
    // function HtmlDecode(){
    //     var e = document.createElement('div');
    //     e.innerHTML = stateToHTML(editorState.getCurrentContent());
    //     return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
    //   }
    useEffect(() => {
        console.log(editorState);
        let contentRaw = convertToRaw(editorState.getCurrentContent());
        console.log(contentRaw);
        localStorage.setItem('draftRaw', JSON.stringify(contentRaw));
        console.log("editorState=" + JSON.stringify(editorState.getCurrentContent()).length);
        console.log(stateToHTML(editorState.getCurrentContent()));
        console.log("stateToHTML=" + JSON.stringify(stateToHTML(editorState.getCurrentContent())).length);
        // parse()
        // a(JSON.stringify(stateToHTML(editorState.getCurrentContent())))
    }, [editorState]);

    return (
        <div>
            <div style={{ border: "1px solid black", padding: '2px', minHeight: '400px' }}>
                <FormattedMessage id="text_of_article" />
                <Editor
                    editorState={editorState}
                    onEditorStateChange={setEditorState}
                />
            </div>

            <div className="readonly-editor">
            </div>
        </div>
    );
}

class Blogs extends Component {
    constructor(props) {
        super(props);
    }
    state = {
        path: "",
        articles: []
    }
    setEditorState = (editorState) => {
        console.log("setEditorState")
        console.log(editorState)
        let text = stateToHTML(editorState.getCurrentContent())
        this.setState({ editorState: editorState, text: text })
    }
    editName = () => {
        console.log("edit: ")
    }

    getIntl = (id) => {
        const { intl } = this.props;
        return intl.formatMessage({ id: id })
    }

    getArticles = () => {
        let path = this.getPath();
        let get = (path) ? `url=${path}` : `offset=0`;
        const fetch_url = constants.location
        fetch(fetch_url + "/handler/get_articles.php?" + get, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'JWT': `${this.props.currentUser.jwt}`
            }
        })
            .then(resp => resp.json())
            .then(data => {
                if (data.error) {
                    // Будет ошибка если token не дествительный
                    console.log(data.error)
                    this.setState({ formError: data.error })
                } else {
                    console.log(data)
                    this.setState({ load: false, articles: data?.articles, path: path })
                }
            })
            .catch(err => {
                console.log(err)
            })
    }

    deleteArticles = (id) => {
        const obj = {
            "jwt": this.props.currentUser.jwt
        }
        const fetch_url = constants.location
        fetch(fetch_url + `/handler/add_article.php?delete=true&id=${id}`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify({ obj })
        })
            .then(resp => resp.json())
            .then(data => {
                if (data.error) {
                    // Будет ошибка если token не дествительный
                    console.log(data.error)
                    this.setState({ formError: data.error })
                    this.getArticles();
                } else {
                    console.log(data)
                    this.setState({ load: false, articles: data?.articles })
                    this.getArticles();
                }
            })
            .catch(err => {
                console.log(err)
            })
    }

    getPath = () => {
        let path = window.location.pathname.split("/");
        return path?.[3];
    }
    componentDidMount() {
        this.getArticles();
    }
    Breadcrumbs = () => {
        let intl = this.props.intl;
        let url = constants.location + "/" + constants.locale + "/blogs/";
        let content = 1;
        let name = intl.formatMessage({ id: 'blogs' });
        console.log("this.state.breadcrumbs: ", this.state.breadcrumbs)




        return (<ol className="breadcrumbs" itemscope itemtype="https://schema.org/BreadcrumbList">

            <li itemprop="itemListElement" itemscope itemtype="https://schema.org/ListItem">
                <a itemprop="item" href={url}>
                    <span itemprop="name">{name}</span></a>
                <meta itemprop="position" content={content} />
            </li>

            {this.state.path ?
                (<li itemprop="itemListElement" itemscope itemtype="https://schema.org/ListItem">
                    <a itemprop="item" href={url + this.state.path + "/"}>
                        <span itemprop="name">{intl.formatMessage({ id: 'blog' }) + ": " + this.state.articles?.[0]?.title}</span></a>
                    <meta itemprop="position" content={content++} />
                </li>)
                : ""}

        </ol>)
    }

    render() {
        const intl = this.props.intl;
        if (this.state.path !== this.getPath()) this.getArticles();
        if (this.state.path) {


            // <Canonical
            //     author={key['name'] + ' ' + key['lastname']}
            //     title={`${key['title']} ${intl.formatMessage({ id: 'document.title.blogs' })}`}
            //     description={kitcut(parse(key['text']).replace(/<[^>]+>/g, '').replace(/&nbsp;/g, ' ').replace(/  /g, ' '), 155)}
            // />
            const key = this.state.articles?.[0];
            const meta = {
                title: `${key['title']} - ${key['name'] + ' ' + (key['lastname'].substring(0, 1)) + '.'} ${intl.formatMessage({ id: 'document.title.blogs' })}`,
                description: kitcut(`${parse(key['text']).replace(/<[^>]+>/g, '').replace(/&nbsp;/g, ' ').replace(/  /g, ' ')}`, 155),
                canonical: constants.location + window.location.pathname,
                meta: {
                    charset: 'utf-8',
                    name: {
                        author: key['name'] + ' ' + (key['lastname'].substring(0, 1)) + '.',
                        keywords: `${intl.formatMessage({ id: 'subjects.' + key['subjects'] })}`,
                    }
                }
            };
            //             <meta name="title" content="Goal">
            // <meta name="description" content="Як швидко вивчити англійську мову? Блоги и статьи репетиторов - GOAL">
            // <meta name="keywords" content="англійську мову">
            // <meta name="robots" content="index, follow">
            // <meta http-equiv="Content-Type" content="text/html; charset=utf-8">
            // <meta name="language" content="Russian">
            // <meta name="author" content="Игорь Морганюк"></meta>

            return (
                <main className="main_wrapper search_tutors">
                    <div className="main_wrapper_block_content_stretch public_text">
                        <DocumentMeta {...meta} />
                        {this.Breadcrumbs()}
                        <div className="article_result article_blog">
                            {this.state.articles?.map((key, a) => {
                                if (+this.props.currentUser.role !== 3 && key['status']) return "";
                                return <article key={key + "-" + a} className={"article_body" + (key['status'] ? " deleted" : "")}>
                                    <div className="article_author">
                                        <Link to={`/${constants.locale}/profile/id${key['tutor']}`}>
                                            <div className="article_author_media" style={{ backgroundImage: "url('" + key['photo'] + "')" }} title={key['name'] + ' ' + (key['lastname'].substring(0, 1)) + '.'} />
                                        </Link>
                                        <div className="article_author_descr">
                                            <div className="article_date">
                                                <FormattedMessage id="author" /> <Link to={`/${constants.locale}/profile/id${key['tutor']}`} className="article_author_name">{key['name'] + ' ' + (key['lastname'].substring(0, 1)) + '.'}
                                                </Link>
                                            </div>
                                            <div className="article_date">
                                                <FormattedMessage id="subject_of_article" />: <FormattedMessage id={'subjects.' + key['subjects']} />
                                                <br />
                                                <time className="label-date" datetime={(key['time'] * 1000)}>{time(key['time'] * 1000)}</time>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="article_content">
                                        <div className="article_header">
                                            <h1>{key['title']}</h1>
                                        </div>
                                        <div className="article_description ql-snow"><section className="ql-editor" dangerouslySetInnerHTML={{ __html: parse(key['text'].replaceAll("rel=\"noopener noreferrer\" target=\"_blank\"", "")) }} /></div>
                                    </div>
                                    {(+this.props.currentUser.role === 3 && !key['status']) ? <div className="search-button_green" onClick={this.deleteArticles.bind(this, key['id'])}>Delete articles</div> : ""}
                                </article>
                            })}
                        </div>
                    </div>
                </main>
            );
        } else
            return (
                <main className="main_wrapper search_tutors">
                    <div className="main_wrapper_block_content_stretch public_text">
                        {this.Breadcrumbs()}
                        <Canonical title={intl.formatMessage({ id: 'document.title.blogs' })} />
                        {/* <h1 className="main_wrapper_block_content_stretch_title"><FormattedMessage id="blogs" /></h1> */}
                        <p><FormattedMessage id="blogs_title" /></p>
                        <p><FormattedMessage id="blogs_text" /></p>
                        <div className="header_image_block">
                            <div className="header_image_block_inner">
                                <h3 className="heading"><FormattedMessage id="for_students.button_h" /></h3>
                                <h3 className="heading"><FormattedMessage id="for_students.button_p" /></h3>
                                <a className="search-button_green" href="./#root"><FormattedMessage id="for_students.button" /></a>
                            </div>
                            <div className="header_image_block_inner">
                                <h3 className="heading"><FormattedMessage id="for_tutors.button_h" /></h3>
                                <h3 className="heading"><FormattedMessage id="for_tutors.button_p" /></h3>
                                <a className="search-button_green" href="./register"><FormattedMessage id="for_tutors.button" /></a>
                            </div>
                        </div>
                        <div className="article_result article_blog">
                            {/* 
                    id: "1"
lastname: "box"
name: "racoon"
photo: "/handler/uploads/55_1636550531.png"
subject: "1"
text: "&lt;p&gt;Текст статьи&lt;/p&gt;\n&lt;p&gt;&lt;br&gt;&lt;/p&gt;\n&lt;p&gt;&lt;br&gt;&lt;/p&gt;\n&lt;ol&gt;\n  &lt;li&gt;ророрлр&lt;/li&gt;\n  &lt;li&gt;иориориорио&lt;/li&gt;\n  &lt;li&gt;оииорио&lt;/li&gt;\n&lt;/ol&gt;"
time: "1638823157"
title: "Тема статьи"
tutor: "55" */}
                            {this.state.articles?.map((key, a) => {
                                if (+this.props.currentUser.role !== 3 && key['status']) return "";
                                return <article key={key + "-" + a} className={"article_body" + (key['status'] ? " deleted" : "")}>
                                    <div className="article_author">
                                        <Link to={`/${constants.locale}/profile/id${key['tutor']}`}>
                                            <div className="article_author_media" style={{ backgroundImage: "url('" + key['photo'] + "')" }} title={key['name'] + ' ' + (key['lastname'].substring(0, 1)) + '.'} />
                                        </Link>
                                        <div className="article_author_descr">
                                            <div className="article_date">
                                                <FormattedMessage id="author" /> <Link to={`/${constants.locale}/profile/id${key['tutor']}`} className="article_author_name">{key['name'] + ' ' + (key['lastname'].substring(0, 1)) + '.'}
                                                </Link>
                                            </div>
                                            <Link to={`/${constants.locale}/blogs/${key['url']}`} className="article_date">
                                                <FormattedMessage id="subject_of_article" />: <FormattedMessage id={'subjects.' + key['subjects']} />
                                                <br />
                                                <time className="label-date" datetime={(key['time'] * 1000)}>{time(key['time'] * 1000)}</time>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="article_content">
                                        <div className="article_header">
                                            <h1><Link to={`/${constants.locale}/blogs/${key['url']}`}>{key['title']}</Link></h1>
                                        </div>

                                        <div className="article_description ql-snow"><section className="ql-editor" dangerouslySetInnerHTML={{ __html: kitcut(parse(key['text']).replace(/<[^>]+>/g, ''), 300) }} /></div>

                                        <Link to={`/${constants.locale}/blogs/${key['url']}`} className="read_more"><FormattedMessage id="read_completely" /></Link>
                                        {(+this.props.currentUser.role === 3 && !key['status']) ? <div className="search-button_green" onClick={this.deleteArticles.bind(this, key['id'])}>Delete articles</div> : ""}

                                        {/* <div className="article_description"><section className="ql-editor" dangerouslySetInnerHTML={{ __html: parse(key['text'].replaceAll("rel=\"noopener noreferrer\" target=\"_blank\"", "")) }} /></div> */}
                                    </div>

                                    {/* <div className="article_left">
                                            <Link to={`/${constants.locale}/profile/id${key['tutor']}`}><div className="avatar_big" style={{ backgroundImage: "url('" + key['photo'] + "')" }} title={key['name'] + ' ' + (key['lastname'].substring(0, 1)) + '.'} /></Link>
                                            <Link to={`/${constants.locale}/profile/id${key['tutor']}`}><h2>{key['name'] + ' ' + (key['lastname'].substring(0, 1)) + '.'}</h2></Link>
                                        </div>

                                        <div className="row_wrap"><p><FormattedMessage id="subjects" />:</p><p>
                                            <FormattedMessage id={'subjects.' + key['subjects']} />
                                        </p></div>
                                        <div className="row_wrap"><p>{time(key['time'])}</p></div>
                                        <div className="article_right">
                                            <Link to={`/${constants.locale}/blogs/${key['url']}`}><h1>{key['title']}</h1></Link>
                                            <div className="seo_content ql-snow"><section className="ql-editor" dangerouslySetInnerHTML={{ __html: parse(key['text'].replaceAll("rel=\"noopener noreferrer\" target=\"_blank\"", "")) }} /></div>
                                        </div> */}
                                </article>
                            })}

                        </div>


                        {/* <span className="edit_button" onClick={this.editName}></span> */}
                        <div className="container"></div>
                        {/* 
id: "1"
lastname: "box"
name: "racoon"
photo: "/handler/uploads/55_1636550531.png"
subject: "1"
text: "&lt;p&gt;Текст статьи&lt;/p&gt;\n&lt;p&gt;&lt;br&gt;&lt;/p&gt;\n&lt;p&gt;&lt;br&gt;&lt;/p&gt;\n&lt;ol&gt;\n  &lt;li&gt;ророрлр&lt;/li&gt;\n  &lt;li&gt;иориориорио&lt;/li&gt;\n  &lt;li&gt;оииорио&lt;/li&gt;\n&lt;/ol&gt;"
time: "1638823157"
title: "Тема статьи"
tutor: "55" */}

                        {/* {parse(this.state.text)} */}
                        <div>


                        </div>

                    </div>
                    <Canonical seo={true} />
                </main>
            )
    }
}
const mapStateToProps = state => ({
    currentUser: state.currentUser
})

const mapDispatchToProps = dispatch => ({
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Blogs));