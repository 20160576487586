import React, { Component } from 'react';
import constants from './Constants';
import Canonical from './Canonical';
import { IntlProvider, FormattedMessage, injectIntl } from 'react-intl';
import { BrowserRouter as Router, Switch, NavLink, Link, Route, useRouteMatch } from 'react-router-dom';
import { connect } from 'react-redux';
import { getProfileFetch, clearMessage } from './redux/actions';
import Signup from './components/Signup_old';
import Login from './components/Loginsss';
import Forgot from './components/Forgot';
import Dashboard from './components/Dashboard';
import './App.css';

import DocumentMeta from 'react-document-meta';



class App extends Component {
    // componentDidMount = () => {
    //   this.props.getProfileFetch()
    // }
    constructor(props) {
        super(props);
    }
    state = {
        error: this.props.error,
        message: this.props.message
    }

    handleClick = event => {
        event.preventDefault()
        this.props.clearMessage()
        this.setState({ error: null, message: null })
    }


   
    render() {
    
        const intl = this.props.intl;
        const meta = {
            description: intl.formatMessage({ id: 'meta_description' }),
            canonical: constants.location + window.location.pathname,
            meta: {
              charset: 'utf-8',
              name: {
                keywords: intl.formatMessage({ id: 'meta_keywords' })
              }
            }
          };
          console.log("~~~meta: ",meta)

        return ( <>
            <DocumentMeta {...meta}/>
             {
                this.props.error ? ( < div className = "error_popup"
                    onClick = { this.handleClick.bind(this) } > < FormattedMessage id = { this.props.error }
                    /></div > ) : ''
            } {
                this.props.message ? ( < div className = "message_popup"
                    onClick = { this.handleClick.bind(this) } > < FormattedMessage id = { this.props.message }
                    /></div > ) : ''
            } </>)
        }
    }



    const mapStateToProps = state => ({
        currentUser: state.currentUser,
        message: state.message,
        error: state.error
            //currentUser: state.reducer.currentUser
    })

    const mapDispatchToProps = dispatch => ({
        getProfileFetch: () => dispatch(getProfileFetch()),
        clearMessage: () => dispatch(clearMessage())
    })

    export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(App));