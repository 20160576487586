import React, { useState, useRef, useCallback, Component } from 'react';
import constants from './Constants';
import { IntlProvider, FormattedMessage, injectIntl } from 'react-intl';
import { BrowserRouter as Router, Switch, NavLink, Route, useRouteMatch } from 'react-router-dom';
import { connect } from 'react-redux';
import Signup from './components/Signup_old';
import Login from './components/Loginsss';
import Forgot from './components/Forgot';
import Dashboard from './components/Dashboard';
import './App.css';
import { messages } from './index';

import RichTextEditor from './components/RichTextEditor';


// import { setMessages } from './redux/actions';

import DocumentMeta from 'react-document-meta';





class Canonical extends Component {

    constructor(props) {
        super(props);
    }

    state = {
        locale: this.props.intl?.locale,
        role: this.props.currentUser.role,
        edit: false,
        title: "",
        title_status: "empty",
        title_editable: "",
        h1: "",
        h1_status: "empty",
        h1_editable: "",
        h2: "",
        h2_status: "empty",
        h2_editable: "",
        description: "",
        description_status: "empty",
        description_editable: "",
        seo: "",
        seo_status: "empty",
        seo_editable: "",
        path: (this.props.pathname) ? this.props.pathname : window.location.pathname,
    }

    componentDidMount = () => {
        this.getState();
    }

    componentDidUpdate(prevProps) {
        if (
            (this.props.intl?.locale !== prevProps.intl?.locale) ||
            (this.props?.pathname !== prevProps?.pathname) ||
            (this.props?.title !== prevProps?.title)) {
            this.getState();
        }
    }

    canonical_set = async (obj) => {
        //  const obj = {
        //     "jwt": e.jwt,
        //     "canonical":e.canonical,
        //     "title":e.title, 
        //     "description":e.description
        // }

        let json = {};
        let response = await fetch(constants.location + "/handler/canonical_set.php", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify({ obj })
        });
        if (response.ok) {
            json = await response.json();
        } else {
            console.log("Ошибка HTTP: " + response.status);
        }
        return json;
    }


    canonicalGet = async () => {
        let path = (this.props.pathname) ? this.props.pathname : window.location.pathname;
        const obj = {
            "canonical": path
        }
        try {
            const res = await fetch(constants.location + "/handler/canonical_get.php", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                },
                body: JSON.stringify({ obj })
            });
            const data = await res.json();
            console.log("data: ", data);
            if (data.error) {
                return [];
            } else {
                return data
            }
        } catch (err) {
            console.log("error: ", err);
            return [];
        }
    }

    getState = async () => {
        let data = await this.canonicalGet();
        const intl = this.props.intl;
        let locale = intl?.locale;
        let path = (this.props.pathname) ? this.props.pathname : window.location.pathname;
        let title = data?.title;
        let title_status = (title === undefined || !title) ? "empty" : "done";
        let h1 = data?.h1;
        let h1_status = (h1 === undefined || !h1) ? "empty" : "done";
        let h2 = data?.h2;
        let h2_status = (h2 === undefined || !h2) ? "empty" : "done";
        let description = data?.description;
        let description_status = (description === undefined || !description) ? "empty" : "done";
        let seo = data?.seo;
        let seo_status = (seo === undefined || !seo || seo === "<p><br></p>") ? "empty" : "done";

        if (title === undefined || !title) {
            title = this.props.title
        }
        if (h1 === undefined || !h1) {
            h1 = this.props.h1?this.props.h1:this.props.title
        }
        if (description === undefined || !description) {
            description = this.props.description?this.props.description:''
        }

        this.setState({
            locale: locale,
            path: path,

            title: title,
            title_status: title_status,
            title_editable: title,

            h1: h1,
            h1_status: h1_status,
            h1_editable: h1,

            h2: h2,
            h2_status: h2_status,
            h2_editable: h2,

            description: description,
            description_status: description_status,
            description_editable: description,

            seo: seo,
            seo_status: seo_status,
            seo_editable: seo,
        })
    }



    edit_togle = () => {
        this.setState({
            edit: !this.state.edit
        })
    }
    edit_title = (e) => {
        // let elem = e.target
        // elem.style.height = "1px";
        // elem.style.height = (elem.scrollHeight) + "px";
        let value = e.target.value;
        let name = e.target.name;
        this.setState({
            [`${name}_editable`]: value
        })
    }
    edit_seo = (value) => {
        console.log("RichTextEditorProps ",value)
        value=value.replaceAll("'","&#8216;")
        this.setState({
            [`seo_editable`]: value
        })
    }
    edit_description = (e) => {
        // let elem = e.target
        // elem.style.height = "1px";
        // elem.style.height = (elem.scrollHeight) + "px";
        let value = e.target.value;
        this.setState({
            description_editable: value
        })
    }




    saveForm = async () => {
        this.setState({
            title_status: "load",
            h1_status: "load",
            h2_status: "load",
            description_status: "load",
            seo_status: "load"
        })
        const intl = this.props.intl;
        // this.props.messages?.[constants.locale]?.[this.state.path]=this.state.title_editable;
        // this.props.intl.messages?.[this.state.path]=this.state.title_editable;

        // console.log(this.props.messages?.[constants.locale])
        // store.dispatch({
        //     type: 'SET_MESSAGES',
        //     payload: messages2
        // })

        const obj = {
            "jwt": this.props.currentUser.jwt,
            "canonical": this.state.path,
            "title": this.state.title_editable,
            "h1": this.state.h1_editable,
            "h2": this.state.h2_editable,
            "description": this.state.description_editable,
            "seo": this.state.seo_editable,
        }


        let res = await this.canonical_set(obj);

        console.log("res: ", res)

        if (res?.message == "ok") {
            this.getState();
        } else {
            this.setState({
                title_status: "empty",
                h1_status: "empty",
                h2_status: "empty",
                description_status: "empty",
                seo_status: "empty",
            })
        }

        // this.props.setMessages();
    }

    textareaSize = e => {
        console.log("this.textarea=", e, e?.style);
        if (e?.style) {
            e.style.height = "1px";
            e.style.height = (e.scrollHeight) + "px";
        }
    }

    render() {

        console.log("path: ", this.state.path)
        // const intl = this.props.intl;
        // const meta = {
        //     description: intl.formatMessage({ id: 'meta_description' }),
        //     canonical: constants.location + window.location.pathname,
        //     meta: {
        //       charset: 'utf-8',
        //       name: {
        //         keywords: intl.formatMessage({ id: 'meta_keywords' })
        //       }
        //     }
        //   };
        const intl = this.props.intl;
        const meta = {
            title: this.state.title,
            description: this.state.description,
            canonical: constants.location + window.location.pathname,
            meta: {
                charset: 'utf-8',
                name: {
                    keywords: ""
                }
            }
        };
        if(this.props.author)meta.meta.name.author=this.props.author;
        console.log("~~~meta: ", meta)

if(this.props.seo){
    return this.state.seo?(<div className="seo_content ql-snow"><section className="ql-editor" dangerouslySetInnerHTML={{__html: this.state.seo.replaceAll("rel=\"noopener noreferrer\" target=\"_blank\"", "")}}/></div>):"";
}else return (<>
            <DocumentMeta {...meta} />
            {/* <div className="canonical">role: {this.state.role}, title: {this.state.title}</div> */}
            {this.state.role == 3 ?
                <div className={"editable_block" + (this.state.edit ? " active" : "")}>
                    <div className="editable_block_header">
                        <span onClick={this.edit_togle} className="search-button_green edit"><FormattedMessage id="edit" /></span>
                        {/* <span onClick={this.edit_togle} className="edit_button"><FormattedMessage id="edit" /></span> */}
                        <span className="edit_url">{this.state.path}</span>
                        <span className={`edit_icon_${this.state.title_status}`}>title</span>
                        <span className={`edit_icon_${this.state.h1_status}`}>H1</span>
                        <span className={`edit_icon_${this.state.h2_status}`}>H2</span>
                        <span className={`edit_icon_${this.state.description_status}`}>description</span>
                        <span className={`edit_icon_${this.state.seo_status}`}>seo text</span>
                    </div>
                    {
                        this.state.edit ?
                            <div className="editable_block_content">
                                title:
                            <textarea rows="1"
                                    name="title"
                                    className="editable_title"
                                    value={this.state.title_editable}
                                    onChange={this.edit_title.bind(this)}
                                    ref={(c) => this.textareaSize(c)}
                                />
                            H1:
                            <textarea rows="1"
                                    name="h1"
                                    className="editable_title"
                                    value={this.state.h1_editable}
                                    onChange={this.edit_title.bind(this)}
                                    ref={(c) => this.textareaSize(c)}
                                />
                                H2:
                                <textarea rows="1"
                                    name="h2"
                                    className="editable_title"
                                    value={this.state.h2_editable}
                                    onChange={this.edit_title.bind(this)}
                                    ref={(c) => this.textareaSize(c)}
                                />
                                description:
                                <textarea rows="1"
                                    name="description"
                                    className="editable_title"
                                    value={this.state.description_editable}
                                    onChange={this.edit_title.bind(this)}
                                    ref={(c) => this.textareaSize(c)}
                                />
                                seo:
                                {/* <textarea rows="1"
                                    name="seo"
                                    className="editable_title"
                                    value={this.state.seo_editable}
                                    onChange={this.edit_title.bind(this)}
                                    ref={(c) => this.textareaSize(c)}
                                /> */}
                                <RichTextEditor
                    value={this.state.seo_editable} 
                    onChange={e=>this.edit_seo(e)}
                    />
                                <button className="search-button_green"
                                    disabled={
                                        !(
                                            this.state.title !== this.state.title_editable ||
                                            this.state.h1 !== this.state.h1_editable ||
                                            this.state.h2 !== this.state.h2_editable ||
                                            this.state.description !== this.state.description_editable ||
                                            this.state.seo !== this.state.seo_editable
                                        )}
                                    onClick={this.saveForm.bind(this)}><FormattedMessage id="save" /></button>
                            </div>
                            :
                            <>
                                <h1>{this.state.h1}</h1>
                                {this.state.h2 ? <h2>{this.state.h2}</h2> : ""}
                    {/* {this.state.seo ? <div className="seo_content" dangerouslySetInnerHTML={{__html: this.state.seo}}/> : ""} */}
                            </>
                    }
                </div>
                :
                <>
                    <h1>{this.state.h1}</h1>
                    {this.state.h2 ? <h2>{this.state.h2}</h2> : ""}
                                {/* <RichTextEditor
                    value={this.state.seo_editable} 
                    onChange={e=>this.edit_seo(e)}
                    /> */}
                </>

            }
            {/* <DocumentMeta {...meta}/>
             {
                this.props.error ? ( < div className = "error_popup"
                    onClick = { this.handleClick.bind(this) } > < FormattedMessage id = { this.props.error }
                    /></div > ) : ''
            } {
                this.props.message ? ( < div className = "message_popup"
                    onClick = { this.handleClick.bind(this) } > < FormattedMessage id = { this.props.message }
                    /></div > ) : ''
            }  */}

        </>)
    }
}



const mapStateToProps = state => ({
    currentUser: state.currentUser,
    // error: state.error
    //currentUser: state.reducer.currentUser
})

const mapDispatchToProps = dispatch => ({
    // getProfileFetch: () => dispatch(getProfileFetch()),
    // setMessages: () => dispatch(setMessages())
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Canonical));