import React, { Component, useState, useEffect } from 'react';
import constants from '../Constants';
import Canonical from '../Canonical';
import { IntlProvider, FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { userPostTutor, userPostAvatar } from '../redux/actions';
import { Link } from 'react-router-dom';
import SelectSearch from '../SelectSearch';
import InputCheck from './InputCheck'
import { getProfileFetch, postTutorOrder, postTutorOrderText } from '../redux/actions';
import { Redirect } from 'react-router-dom';
import EditPass from './EditPass';
import './TutorOrder.css'


function select(name, title, data, selected, intl, select) {
    console.log("name, selected " + name + " " + selected)
    return <select defaultValue={selected} value={selected} id={name} name={name} className="filters_list" onChange={select}>
        <option value={title} >{intl.formatMessage({ id: title })}</option>
        {data.map((key, i) => !!key?<option key={name + i} value={key[name]} >{intl.formatMessage({ id: name + '.' + key[name] })}</option>:'')}
    </select>
} function select2(name, title, data, selected, intl, select) {
    console.log("name, selected " + name + " " + selected)
    return <select defaultValue={selected} value={selected} id={name} name={name} className="filters_list" onChange={select}>
        <option value={title} >{intl.formatMessage({ id: title })}</option>
        {console.log(data)}
        {(data !== undefined) ? data.map((key, i) => <option key={name + i} value={(key==='')?-1:key} >{intl.formatMessage({ id: name + '.' + key })}</option>) : ''}
    </select>
}
class TutorOrder extends Component {

    constructor(props) {
        super(props);
    }

    // searchState = {
    //     online: (findGetParameter("cities") === "online"),//(this.props.match.params.cities==="online"),
    //     cities: (findGetParameter("cities") !== "online") ? findGetParameter("cities") : "",
    //     districts: '',
    //     subjects: findGetParameter("subjects"),
    //     levels: ''
    // }
    sState = this.props.searchState

    state = {
        jwt: this.props.currentUser.jwt,
        key: [],
        steps: 1,
        online: (this.sState.online) ? this.sState.online : false,
        selected_cities: (this.sState.cities) ? this.sState.cities : '',
        selected_districts: (this.sState.districts) ? this.sState.districts : '',
        selected_orders_street: '',
        selected_subjects: (this.sState.subjects) ? this.sState.subjects : '',
        selected_levels: (this.sState.levels) ? this.sState.levels : '',
        selected_lesson_duration: localStorage.getItem('student_lesson_duration'),
        selected_periodicity: localStorage.getItem('student_periodicity'),
        at_the_student: false,
        at_the_tutor: false,
        error: false,
        name: this.props.currentUser?.name??localStorage.getItem('student_name'),
        email: this.props.currentUser?.email??localStorage.getItem('student_email'),
        phone: this.props.currentUser?.phone??localStorage.getItem('student_phone'),
        times_text: '',
        help_text: '',
        wishes_text: '',
        formErrors: { name: '', email: '', phone: '' },
        formValids: { valid: false, name: false, email: false, phone: false },
        submitted: true,
        formError: '',
        response: this.props.selectUserOrders.response,
        current: this.props.selectUserOrders.current
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        if (prevState.currentTutor !== nextProps.currentTutor) {
            return {
                key: nextProps.currentTutor
            };
        }
        if (prevState.selectUserOrders !== nextProps.selectUserOrders) {
            return {
                response: nextProps.selectUserOrders.response,
                current: nextProps.selectUserOrders.current
            };
        }
        return null;
    }
    close = () => {
        this.props.close[0] = true
        this.setState({
            jwt: this.props.currentUser.jwt,
            key: [],
            steps: 1,
            selected_cities: (this.sState.cities) ? this.sState.cities : '',
            selected_districts: (this.sState.districts) ? this.sState.districts : '',
            selected_subjects: (this.sState.subjects) ? this.sState.subjects : '',
            selected_levels: (this.sState.levels) ? this.sState.levels : '',
            selected_lesson_duration: localStorage.getItem('student_lesson_duration'),
            selected_periodicity: localStorage.getItem('student_periodicity'),
            error: false,
            times_text: '',
            help_text: '',
            wishes_text: '',
            formErrors: { name: '', email: '', phone: '' },
            formValids: { valid: false, name: false, email: false, phone: false },
            submitted: true,
            formError: ''
        })
    }

    handleChange = event => {
        let value = event.target.value
        let len = 300
        let name = event.target.name
        if (value.length > len) {
            value = value.substr(0, len)
        }
        this.setState({
            [name]: value,
            submitErrors: ""
        });
        localStorage.setItem(["student_" + name], value);
    }
    handleChangeText = event => {
        let value = event.target.value
        let len = 500
        let name = event.target.name
        if (value.length > len) {
            value = value.substr(0, len)
        }
        this.setState({
            [name]: value
        });
    }
    handleChangeSelect = (event) => {
        let selected = event.target.value
        let obj = event.target.id
        this.setState({
            ["selected_" + obj]: selected,
            error: false
        });
        localStorage.setItem(["student_" + obj], selected);
        console.log("selected_" + obj + ": " + selected)
    }
    handleChangeSelect2 = (event) => {
        let selected = event.target.value
        let obj = event.target.name
        this.setState({
            [obj]: selected,
            error: false
        });
        console.log("selected_" + obj + ": " + selected)
    }
    handleCheck = (event) => {
        let selected = event.target.checked
        let obj = event.target.name
        this.setState({
            [obj]: selected,
            error: false
        });
        console.log(obj + ": " + selected)
    }
    checkStep(step) {
        const o = this.state
        let e = false
        console.log("switch1 (" + step + ")" + e)
        switch (step) {
            case 1:
                e = (
                    (o.online || o.at_the_tutor || (o.at_the_student && o.selected_districts && o.selected_cities && o.selected_orders_street)) &&
                    (o.selected_subjects && o.selected_levels) &&
                    (o.selected_lesson_duration && o.selected_periodicity)
                )
                break;
            case 2:
                e = this.valid();
                break;
            case 3:
                e = true;
                break;
            default:
                break;
        }
        console.log("switch2 (" + step + ")" + e)
        return e
    }

    validateField(fieldName, value) {
        const intl = this.props.intl
        var formErrors = this.state.formErrors;
        var formValids = this.state.formValids;
        switch (fieldName) {
            case 'name':
                formValids.name = (value != null && value.length >= 2);
                formErrors.name = formValids.name ? '' : intl.formatMessage({ id: "error.is_too_short" });
                break;
            case 'email':
                formValids.email = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) ? true : false;
                formErrors.email = formValids.email ? '' : intl.formatMessage({ id: "error.is_invalid" });
                break;
            case 'phone':
                formValids.phone = (value != null && value.length >= 6);
                formErrors.phone = formValids.phone ? '' : intl.formatMessage({ id: "error.is_too_short" });
                break;
            default:
                break;
        }
        formValids.valid = (formValids.name && formValids.email && formValids.phone)
        this.setState({ error: !formValids.valid })
    }
    valid() {
        for (var key in this.state.formValids) {
            console.log(key)
            this.validateField(key, this.state[key])
        }
        return this.state.formValids.valid
    }
    prevStep() {
        this.setState({ steps: +this.state.steps - 1 })
    }
    nextStep() {
        if (!this.checkStep(this.state.steps)) { this.setState({ error: true }); return false }
        if (this.state.steps == 1) { this.setState({ steps: +this.state.steps + 1 }); return false }
        if (this.state.steps == 3) { this.handleSubmit2(); return false }
        if (this.state.steps == 2) this.handleSubmit()
    }

    handleSubmit = () => {
        const unixTime = Math.trunc(Date.now()/1000);
        // this.props.selectUserOrders.current = unixTime
        const obj = {
            unixTime: unixTime,
            jwt: this.state.jwt,
            tutor: this.state.key[0],
            tutor_name: this.state.key[2] + ' ' + this.state.key[3],
            price: this.state.key[7],
            online: this.state.online,
            cities: this.state.selected_cities,
            districts: this.state.selected_districts,
            orders_street: this.state.selected_orders_street,
            subjects: this.state.selected_subjects,
            levels: this.state.selected_levels,
            lesson_duration: this.state.selected_lesson_duration,
            periodicity: this.state.selected_periodicity,
            at_the_student: this.state.at_the_student,
            at_the_tutor: this.state.at_the_tutor,
            name: this.state.name,
            email: this.state.email,
            phone: this.state.phone
        }
        if (this.state.formValids.valid && this.state.submitted) {
            this.setState({ submitted: false })
            const fetch_url = constants.location
            fetch(fetch_url + "/handler/tutor_order.php", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                },
                body: JSON.stringify({ obj })
            })
                .then(resp => resp.json())
                .then(data => {
                    if (data.error) {
                        console.log(data.error)
                        this.setState({ formError: this.props.intl.formatMessage({ id: data.error }) })
                    } else {
                        if (data.jwt && data.jwt != this.state.jwt) {
                            localStorage.setItem('token', data.jwt);
                            localStorage.setItem('currentUser', JSON.stringify({ 'jwt': data.jwt, 'role': 0 }));
                            console.log("old jwt: " + this.state.jwt)
                            console.log("set new jwt: " + data.jwt)
                            this.props.getProfileFetch(data.jwt).then(items => {
                                this.setState({ formError: items })
                            });
                        }
                        if(data.order){
                            this.props.selectUserOrders.current = data.order
                            console.log("set new selectUserOrders: " + data.order)
                            this.setState({ submitted: true, steps: +this.state.steps + 1 })
                        }else{
                            this.setState({ formError: this.props.intl.formatMessage({ id: data.error }) })
                        }
                    }
                }).catch((error) => {
                    console.log(error)
                });
        }
    }
    handleSubmit2 = () => {
        console.log("data")
        const obj = {
            id: this.props.selectUserOrders.current,
            jwt: this.state.jwt,
            tutor: this.state.key[0],
            email: this.state.email,
            times_text: this.state.times_text,
            help_text: this.state.help_text,
            wishes_text: this.state.wishes_text
        }
        console.log(obj)
        if (obj.tutor && obj.email && (obj.times_text || obj.help_text || obj.wishes_text)) {
            this.setState({ submitted: false })
            const fetch_url = constants.location
            fetch(fetch_url + "/handler/tutor_order_text.php", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                },
                body: JSON.stringify({ obj })
            })
                .then(resp => resp.json())
                .then(data => {
                    if (data.error) {
                        console.log(data.error)
                        this.setState({ formError: this.props.intl.formatMessage({ id: data.error }) })
                    } else {
                        this.setState({ submitted: true, steps: +this.state.steps + 1 })
                    }
                }).catch((error) => {
                    console.log(error)
                });
            // this.props.postTutorOrderText(data)
            // this.setState({ steps: +this.state.steps + 1 })
        } else {
            this.setState({ submitted: true, steps: +this.state.steps + 1 })
        }
    }

    render() {
        console.log("currentTutor state")
        console.log(this.state)
        const o = this.state

        const intl = this.props.intl

        const { state: { key } } = this;
        let name = {
            '0': 'id',
            '1': 'rating',
            '2': 'name',
            '3': 'lastname',
            '4': 'photo',
            '5': 'showprofile',
            '6': 'showdate',
            '7': 'price',
            '8': 'experience',
            '9': 'subjects',
            '10': 'education',
            '11': 'degree',
            '12': 'graduation',
            '13': 'diplomas',
            '14': 'city',
            '15': 'myplacecheck',
            '16': 'myplace',
            '17': 'address',
            '18': 'studentplacecheck',
            '19': 'studentplace',
            '20': 'online',
            '21': 'shortinfo',
            '22': 'longinfo',
            '23': 'age',
            '24': 'sex',
            '25': 'video',
            '26': 'classesschedule',
            '27': 'grade',
            '29': 'status'
        }

        return (!this.props.close[0] && key?.[0]) ? (
            <div className="modal">
                {console.log("return selectUserOrders: ")}
                {console.log(this.props.selectUserOrders)}
                <div className="modal-content">
                    <span onClick={this.close.bind(this)} className="close"></span>
                    {(this.state.submitted) ? '' : <span className="load_submitted"></span>}
                    {(this.state.steps < 4) ? <>
                        {/* <img className="user-photo" src={key[4]} alt={key[2] + ' ' + (key[3].substring(0, 1)) + '.'} /> */}
                        <div className="user-photo" style={{backgroundImage: "url('"+key[4]+"')"}} title={key[2] + ' ' + (key[3].substring(0, 1)) + '.'} />
                        <span className="user-name"><FormattedMessage id="tutor_order" /> {key[2] + ' ' + (key[3].substring(0, 1)) + '.'}</span>
                        <div className="steps-indicator">
                            <span className={(this.state.steps == 1) ? "active" : ""}>1</span>
                            <span className={(this.state.steps == 2) ? "active" : ""}>2</span>
                            <span className={(this.state.steps == 3) ? "active" : ""}>3</span>
                        </div>
                        {(this.state.steps == 1) ?
                            <div className="steps-content">
                                <div className="request-wrapper">
                                    <p><FormattedMessage id="lesson" /></p>
                                    {select("subjects", "lesson", JSON.parse(key[9]), this.state.selected_subjects, this.props.intl, this.handleChangeSelect)}
                                </div>

                                <div className="request-wrapper">
                                    <p><FormattedMessage id="level" /></p>
                                    {select2("levels", "level", JSON.parse(key[9])?.find(arr => arr?.subjects == this.state.selected_subjects)?.levels.split(', '), this.state.selected_levels, this.props.intl, this.handleChangeSelect)}
                                </div>

                            

                                <div className="request-wrapper">
                                    <p><FormattedMessage id="place_of_study" /></p>
                                    <div>

                                         {(key[15]=="1"||key[15]==1)?<label className="checkbox_container">
                                            <FormattedMessage id="at_the_tutor" />
                                            <input type="checkbox" hidden onChange={this.handleCheck} name="at_the_tutor" checked={this.state.at_the_tutor} />
                                            <span className="checkmark"></span>
                                            {(this.state.at_the_tutor) ? <div>{intl.formatMessage({ id: 'cities.' + JSON.parse(key[14])[0].cities })}, {key[16]}</div> : ""}
                                        </label>:""}
                                        {(key[18]=="1"||key[18]==1)?<label className="checkbox_container">
                                            <FormattedMessage id="at_the_student" />
                                            <input type="checkbox" hidden onChange={this.handleCheck} name="at_the_student" checked={this.state.at_the_student} />
                                            <span className="checkmark"></span>
                                            {(this.state.at_the_student) ? <>
                                                {select("cities", "tutorcity_title",
                                                    JSON.parse(key[14]), this.state.selected_cities, this.props.intl, this.handleChangeSelect)}
                                                {select2("districts", "app.districts",
                                                    JSON.parse(key[14])?.find(arr => arr.cities === this.state.selected_cities)?.districts.split(', '),
                                                    this.state.selected_districts, this.props.intl, this.handleChangeSelect)}
                                                <input name="adres" type="text" id="orders_street" value={this.state.selected_orders_street} onChange={this.handleChangeSelect} placeholder={intl.formatMessage({ id: 'orders_street' })} />
                                            </> : ""}
                                        </label>:""}
                                        {(key[20]=="1"||key[20]==1)?<label className="checkbox_container">
                                            <FormattedMessage id="online" />
                                            <input type="checkbox" hidden onChange={this.handleCheck} name="online" checked={this.state.online} />
                                            <span className="checkmark"></span>
                                        </label>:""}
                                    </div>
                                </div>
                                <div className="request-wrapper">
                                    <p><FormattedMessage id="periodicity" /></p>
                                    {select2("periodicity", "periodicity", [0, 1, 2], this.state.selected_periodicity, this.props.intl, this.handleChangeSelect)}

                                </div>
                                <div className="request-wrapper">
                                    <p><FormattedMessage id="lesson_duration" /></p>
                                    {select2("lesson_duration", "lesson_duration", [0, 1, 2], this.state.selected_lesson_duration, this.props.intl, this.handleChangeSelect)}
                                </div>
                            </div> : ''}

                        {(this.state.steps == 2) ? <div className="steps-content">
                            <div className="request-wrapper">
                                <p><FormattedMessage id="app.name" /></p>
                                <input name="name" type="text" id="name" value={this.state.name} onChange={this.handleChange} placeholder={intl.formatMessage({ id: 'app.name' })} />
                            </div>
                            <div className="input_error">{this.state.formErrors.name}</div>
                            <div className="request-wrapper">
                                <p><FormattedMessage id="app.phone" /></p>
                                <input type='phone' name='phone' required id="phone" value={this.state.phone} onChange={this.handleChange} placeholder={intl.formatMessage({ id: 'app.phone' })} />
                            </div>
                            <div className="input_error">{this.state.formErrors.phone}</div>
                            <div className="request-wrapper">
                                <p><FormattedMessage id="app.email" /></p>
                                <input name="email" type="email" id="email" value={this.state.email} onChange={this.handleChange} placeholder={intl.formatMessage({ id: 'app.email' })} />
                            </div>
                            <div className="input_error">{this.state.formErrors.email}</div>


                        </div> : ''}
                        {(this.state.steps == 3) ? <div className="steps-content">
                            <div className="request-wrapper-column">
                                <p><FormattedMessage id="when_is_it_convenient_for_you_to_study" /></p>
                                <textarea name="times_text" value={this.state.times_text} onChange={this.handleChangeText}
                                    placeholder={intl.formatMessage({ id: "indicate_possible_days_of_classes_and_time_intervals" })} />
                            </div>
                            <div className="request-wrapper-column">
                                <p><FormattedMessage id="what_exactly_do_you_need_help_with" /></p>
                                <textarea name="help_text" value={this.state.help_text} onChange={this.handleChangeText}
                                    placeholder={intl.formatMessage({ id: "indicate_what_exactly_you_need_help_with" })} />
                            </div>
                            <div className="request-wrapper-column">
                                <p><FormattedMessage id="do_you_have_any_additional_requests" /></p>
                                <textarea name="wishes_text" value={this.state.wishes_text} onChange={this.handleChangeText}
                                    placeholder={intl.formatMessage({ id: "specify_additional_wishes" })} />
                            </div>
                        </div> : ''}
                        <div className="modal-content_error">
                            {this.state.error ? <FormattedMessage id="error.not_all_fields" /> : ''}
                            {this.state.formError}
                        </div>
                        <div className="request-step-buttons">
                            {(this.state.steps == 2) ? <button className="button_red" onClick={this.prevStep.bind(this)}><FormattedMessage id="back" /></button> : ''}
                            {(this.state.steps < 4) ? <button className="button_green" onClick={this.nextStep.bind(this)}><FormattedMessage id="further" /></button> :
                                <button className="button_green" onClick={this.aboutOrder.bind(this)}><FormattedMessage id="more_details_about_order" /></button>}
                        </div>
                        {(this.state.steps == 2) ? <p><FormattedMessage id="by_submitting_an_order_you_agree" /> <a target="_blank" href="/public_offer"><FormattedMessage id="you_agree_to_public_offer" /></a></p> : ""}
                        {/* {"TutorOrder: " + key} */}
                    </> : <>
                        <h2><FormattedMessage id="order_sent" /></h2>
                        <h2><FormattedMessage id="order_sent_title_1" /></h2>
                        <Link className="button_green" to={`/${constants.locale}/dashboard?tab=chat&id=${this.state.key[0]}`}><FormattedMessage id="chat_with_a_tutor" /></Link>
                        <Link className="link_green" to={`/${constants.locale}/dashboard?tab=order&id=${this.props.selectUserOrders.current}`}><FormattedMessage id="more_details_about_order" /></Link>
                        <p><FormattedMessage id="order_sent_title_2" /></p>
                    </>}


                </div>

            </div>
        ) : ""
    }
}


const mapStateToProps = state => ({
    currentUser: state.currentUser,
    selectUserOrders: state.selectUserOrders
})

const mapDispatchToProps = dispatch => ({
    getProfileFetch: object => dispatch(getProfileFetch(object)),
    postTutorOrder: object => dispatch(postTutorOrder(object)),
    postTutorOrderText: object => dispatch(postTutorOrderText(object))
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(TutorOrder));
