import React, { Component } from 'react';
import constants from '../Constants';
import Canonical from '../Canonical';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';

class FAQ extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        // document.title = this.props.intl.formatMessage({ id: 'document.title.faq' });
        return (<main className="main_wrapper">
            <div className="main_wrapper_block">
                <div className="main_wrapper_block_content_stretch">
                    <Canonical title={this.props.intl.formatMessage({ id: 'document.title.faq' })}/>
                    {/* <h1><FormattedMessage id="document.title.faq" />:</h1> */}
                    {Array(6).fill("details").map((e, i) => {
                        return (<details key={`${e}_${i}`} itemScope="" itemProp="mainEntity" itemType="https://schema.org/Question" open={!i ? "open" : ""}>
                            <summary itemProp="name" className="title"><FormattedMessage id={`faq.q.${1 + i}`} /></summary>
                            <div className="text" itemScope="" itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
                                <div itemProp="text"><FormattedMessage id={`faq.a.${1 + i}`} /></div>
                            </div>
                        </details>)
                    })}
                </div>
            </div>
                <Canonical seo={true}/>
        </main>)
    }
}
const mapStateToProps = state => ({
    currentUser: state.currentUser
})

const mapDispatchToProps = dispatch => ({})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(FAQ));