import React, { Component } from 'react';
import constants from '../Constants';
import Canonical from '../Canonical';
import { FormattedMessage, injectIntl } from 'react-intl';
import './Tutors.css'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import SelectSearch_from_search from '../SelectSearch_from_search';
// import { userSelectSearch, getSelectTutors } from '../redux/actions';
import TutorOrder from '../components/TutorOrder';
import PaginatedItems from '../components/PaginatedItems';
import findGetParameter from '../components/FindGetParameter';
// import SweetPagination from "../components/SweetPagination";
// import Pagination from "../components/Pagination";
import PathParameter from '../components/PathParameter';

// import ScrollableAnchor, { goToAnchor, removeHash, configureAnchors } from 'react-scrollable-anchor';

function declOfNum(number, words) {
    return words[(number % 100 > 4 && number % 100 < 20) ? 2 : [2, 0, 1, 1, 1, 2][(number % 10 < 5) ? Math.abs(number) % 10 : 5]];
}

function tutorsView(arr, intl, select) {
    let name = {
        '0': 'id',
        '1': 'rating',
        '2': 'name',
        '3': 'lastname',
        '4': 'photo',
        '5': 'showprofile',
        '6': 'showdate',
        '7': 'price',
        '8': 'experience',
        '9': 'subjects',
        '10': 'education',
        '11': 'degree',
        '12': 'graduation',
        '13': 'diplomas',
        '14': 'city',
        '15': 'myplacecheck',
        '16': 'myplace',
        '17': 'address',
        '18': 'studentplacecheck',
        '19': 'studentplace',
        '20': 'online',
        '21': 'shortinfo',
        '22': 'longinfo',
        '23': 'age',
        '24': 'sex',
        '25': 'video',
        '26': 'classesschedule',
        '27': 'grade',
        '28': 'status',
        '29': 'phone'
    }
    let i = 0;

    function getReviews(id) {
        console.log(id)
    }

function decodeHtmlCharCodes(str) {
    return str.replace(/(&#(\d+);)/g, function (match, capture, charCode) {
        return String.fromCharCode(charCode);
    });
}

    return <>
        {
            arr?.map((key, a) => {
                const key_14 = JSON.parse(key[14]);
                const key_9 = JSON.parse(key[9]);

                return <div key={key + "-" + a} className={"tutor_card"+(+key?.status===-1?" blocked_tutor":"")}>
                    <div className="info-block">
                        <div className="tutor_card_header">
                            <Link to={`/${constants.locale}/profile/id${key[0]}`}><div className="avatar_big" style={{ backgroundImage: "url('" + key[4] + "')" }} title={key[2] + ' ' + (key[3].substring(0, 1)) + '.'} /></Link>
                            {/* <Link to={`/${constants.locale}/profile/id${key[0]}`}><img src={key[4]} alt={key[2] + ' ' + (key[3].substring(0, 1)) + '.'} /></Link> */}
                            <div>
                                <Link to={`/${constants.locale}/profile/id${key[0]}`}><h2>{key[2]} {key[3].substring(0, 1)}.{(JSON.parse(key[13])?.[0]?.['name'] !== undefined) ? <i className="shield" title={intl.formatMessage({ id: 'uploaded_education_documents' })}></i> : ''}</h2></Link>
                                <div className="mobile">
                                    {(key[28]) ? '' : <div className="user-table-column bgcolor_red">{intl.formatMessage({ id: 'tutor_status.' + key[28] })}</div>}
                                    {(key[29] && key[29] !== undefined) ? <div className="user-table-column">{key[29]}</div> : ''}
                                    <div className="user-table-column">{intl.formatMessage({ id: 'price.' + key[7] })}</div>
                                    <div className="user-table-column button" onClick={getReviews.bind(this, key[0])}>
                                        {(key[27].split(',')[0] > 0) ? <><i className="star-icon"></i>{(+key[27].split(',')[0] / +key[27].split(',')[1]).toFixed(1)} {intl.formatMessage({ id: 'reviews' })} {key[27].split(',')[1]}</> : intl.formatMessage({ id: 'no_reviews_yet' })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="info-block">
                        <div className="mobile">
                            {(key[20] == "1" || key[20] == 1) ? <span className="online-available"><FormattedMessage id='online_classes_are_possible' /></span> : ''}
                            {(key[14] == undefined) ? '' : (key_14?.[0]?.cities !== undefined && key_14?.[0]?.cities !== '') ? (<h3><FormattedMessage id='citi' />: {intl.formatMessage({ id: 'cities.' + key_14[0].cities }) + ' '}
                                {(key_14?.length) ? key_14?.map((e, i) =>
                                    (i !== 0 && e?.cities !== key_14?.[0]?.cities) ? ((e?.cities) ? <span key={"att_" + i} className="new_link_users_attr"> {intl.formatMessage({ id: 'cities.' + e.cities })}{((key_14?.length - 1) > i) ? ', ' : ''} </span> : '') : ''
                                ) : ''}
                            </h3>) : ''}
                        </div>
                        <p className="p_lessons">
                            {(key[9] == undefined) ? '' : (key_9?.length) ? (<><FormattedMessage id='subjects' />: {key_9?.map((e, i) =>
                                (e?.subjects) ? <span key={"att_" + i} className="new_link_users_attr"> {intl.formatMessage({ id: 'subjects.' + e.subjects })}{((key_9?.length - 1) > i) ? ', ' : ''} </span> : ''
                            )}</>) : ''}
                        </p>
                        {(key[10] == 0 || key[10] == undefined) ? '' : <p className="user_info">{intl.formatMessage({ id: 'education_title' })}: {isNaN(key[10]) ? key[10] : intl.formatMessage({ id: 'education.' + key[10] })}</p>}
                        <p className="user_info">{intl.formatMessage({ id: 'degree_title' })}: {isNaN(key[11]) ? key[11] : intl.formatMessage({ id: 'degree.' + key[11] })}</p>
                        <p className="user_info">{intl.formatMessage({ id: 'experience_title' })}: {isNaN(key[8]) ? key[8] : intl.formatMessage({ id: 'experience.' + key[8] })}</p>
                        {(key[20] == "1" || key[20] == 1) ? <span className="desctop online-available"><FormattedMessage id='online_classes_are_possible' /></span> : ''}
                        <p className="description" onClick={e => { console.log(e); e.target.classList.toggle('open'); }} data-origin={key[22]}>{
                        //    constants.replacer(constants.renderHTML(key[22]))
                        constants.replacer(decodeHtmlCharCodes(key[22]))
                        }</p>
                        {/* {key[22]?.replace(/&amp;amp;quot;/g, '`')?.replace(/&quot;/g, '"')?.replace(/&amp;/g, '&')} */}
                    </div>
                    <div className="info-block">
                        <div className="desctop">
                            {(key[14] == undefined) ? '' : (key_14?.[0]?.cities !== undefined && key_14?.[0]?.cities !== '') ? (<h3><FormattedMessage id='citi' />: {intl.formatMessage({ id: 'cities.' + key_14[0].cities }) + ' '}
                                {(key_14?.length) ? key_14?.map((e, i) =>
                                    (i !== 0 && e?.cities !== key_14?.[0]?.cities) ? ((e?.cities) ? <span key={"att_" + i} className="new_link_users_attr">{intl.formatMessage({ id: 'cities.' + e.cities })}{((key_14?.length - 1) > i) ? ', ' : ''} </span> : '') : ''
                                ) : ''}</h3>) : ''}
                            {(key[28]) ? '' : <div className="user-table-column bgcolor_red">{intl.formatMessage({ id: 'tutor_status.' + key[28] })}</div>}
                            {(key[29] && key[29] !== undefined) ? <div className="user-table-column">{key[29]}</div> : ''}
                            <div className="user-table-column">{intl.formatMessage({ id: 'price.' + key[7] })}</div>
                            <div className="user-table-column button" onClick={getReviews.bind(this, key[0])}>
                                {(key[27].split(',')[0] > 0) ? <><i className="star-icon"></i>{(+key[27].split(',')[0] / +key[27].split(',')[1]).toFixed(1)} {intl.formatMessage({ id: 'reviews' })} {key[27].split(',')[1]}</> : intl.formatMessage({ id: 'no_reviews_yet' })}
                            </div>
                        </div>
                        <button className="search-button_green" onClick={select.bind(this, key)}>{intl.formatMessage({ id: 'contact_a_tutor' })}</button>
                        <span className="green_text"><FormattedMessage id="its_free" /></span>
                    </div>
                </div>
            })}
    </>
}

function select(name, title, data, selected, selected2, intl, select) {
    return <select id={title} defaultValue={selected} className="filters_list" onChange={select}>
        <option value={title} >{intl.formatMessage({ id: title })}</option>
        {data?.map((key, i) => <option key={i} value={key} disabled={(title == "price_min") ? (+key > +selected2) ? true : false : (+key < +selected2) ? true : false} >{intl.formatMessage({ id: name + '.' + key })}</option>)}
    </select>
}

class Tutors extends Component {
    constructor(props) {
        super(props);
    }

    myRef = React.createRef();

    scroll(ref) {
        ref.current.scrollIntoView({ behavior: 'smooth' })
    }

    state = {
        path_array: ["online", "subjects", "levels", "cities", "districts"],
        online: PathParameter.find("online"),//(this.props.match.params.cities==="online"),
        cities: PathParameter.find("cities"),
        districts: PathParameter.find("districts"),
        subjects: PathParameter.find("subjects"),
        levels: PathParameter.find("levels"),
        await_fetch: true,
        data_price: [],
        filteredData_price: [],
        selected_price_min: 'price_min',
        selected_price_max: 'price_max',
        select_tutor: [],
        close_popup: [true],
        renderSelect: true,
        count_item: 10,
        paginated_total: 0,
        paginated_item: findGetParameter('page', 1),
        arrTutors: [],
        selectedTutors: [],
        totalResults: 0,
        breadcrumbs: [],
        title:"",
        pathname: window.location.pathname,
    }

    getSelectTutors = async (tutor) => {
        try {
            const res = await fetch(constants.location + "/handler/select_tutors.php", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                },
                body: JSON.stringify({ tutor, url: window.location.pathname })
            })
            const data = await res.json();
            console.log("data: ", data);
            if (data.error) {
                return [];
            } else {
                return data
            }
        } catch (err) {
            console.log("error: ", err);
            return [];
        }
    }

    setPaginatedItem = (
        paginated_item,
        arr = this.state.arrTutors??[],
        price_min = this.state.selected_price_min,
        price_max = this.state.selected_price_max
    ) => {
        console.log("setPaginatedItem: ", paginated_item);
        // if(paginated_item===this.state.paginated_item)return false;
        let clonedArray = arr.filter((key, a) => {
            if (!(price_min !== 'price_min' && +price_min > +key[7]) && !(price_max !== 'price_max' && +price_max < +key[7])) return { ...a }
        })
        console.log("clonedArray: ", clonedArray)
        let totalResults = clonedArray.length;
        let paginated_total = Math.ceil(totalResults / this.state.count_item);
        paginated_item = (paginated_item > paginated_total) ? 1 : paginated_item;
        let startIndex = (paginated_item - 1) * this.state.count_item
        let selectedTutors = clonedArray.slice(+startIndex, +this.state.count_item + startIndex);
        console.log(+startIndex, +this.state.count_item + startIndex, selectedTutors)
        if (paginated_item !== this.state.paginated_item) {
            this.scroll(this.myRef)
        }
        this.setState({
            paginated_total: paginated_total,
            paginated_item: paginated_item,
            selectedTutors: selectedTutors,
            totalResults: totalResults
        });

        let params = new URLSearchParams(window.location.search);
        params.set("page", paginated_item)
        let getParam = "?" + params.toString()
        console.log(window.location.protocol + " || " + window.location.host + " || " + window.location.pathname + " || " + getParam)
        var newurl = constants.location + window.location.pathname + getParam;// + '?myNewUrlQuery=1';
        window.history.pushState({ path: newurl }, '', newurl);
    }

    getData = (object) => {
        fetch(`/data/` + object + `.json`)
            .then(response => response.json())
            .then(data => {
                // this.state['data_'+object]=data;
                // this.state['filteredData_'+object]=data;
                var filteredData = data;
                this.setState({
                    ['data_' + object]: data,
                    ['filteredData_' + object]: filteredData
                });
            });
    }

    rerender = async () => {
        this.setState({ await_fetch: true })
        const o = this.props.currentUser;
        let online = o.onlineSearch;
        let cities = (!isNaN(o.cities_search?.search?.cities)) ? o.cities_search?.search?.cities : '';
        let districts = (!isNaN(o.cities_search?.search?.districts)) ? o.cities_search?.search?.districts : '';
        let subjects = (!isNaN(o.subjects_search?.search?.subjects)) ? o.subjects_search?.search?.subjects : '';
        let levels = (!isNaN(o.subjects_search?.search?.levels)) ? o.subjects_search?.search?.levels : '';
        const obj = {
            jwt: (o.jwt) ? o.jwt : '',
            online: online,
            cities: cities,
            districts: districts,
            subjects: subjects,
            levels: levels,
            page: 1
        };
        this.renderRedirect(obj);
        console.log("obj", obj);
        const arrTutors = await this.getSelectTutors(obj);


        this.setTitle(subjects, online, cities);



        this.setState({
            online: online,
            cities: cities,
            districts: districts,
            subjects: subjects,
            levels: levels,
            arrTutors: arrTutors?.tutors,
            await_fetch: false
        })
        this.setPaginatedItem(1, arrTutors?.tutors)
    };

    getIntl = (id) => {
        const { intl } = this.props;
        return intl.formatMessage({ id: id })
    }

    setTitle = (subjects, online, cities) => {

        // var title = ((subjects) ? (this.getIntl("app.tutors.title.lesson") + ": " + this.getIntl("subjects." + subjects) + ", ") : "")
        // if (online) {
        //     title += this.getIntl("app.tutors.title.online") + ", " + this.getIntl("app.tutors.title.end")
        // } else if (cities) {
        //     title += this.getIntl("cities." + cities) + ", " + this.getIntl("app.tutors.title.end")
        // } else {
        //     title += this.getIntl("app.tutors.title.end")
        // }
        // this.setState({
        //     title: title
        // })

        // var title = ((this.state.subjects) ? (this.getIntl("app.tutors.title.lesson") + ": " + this.getIntl("subjects." + this.state.subjects) + ", ") : "")
        // if (this.state.online) {
        //     title += this.getIntl("app.tutors.title.online") + ", " + this.getIntl("app.tutors.title.end")
        // } else if (this.state.cities) {
        //     title += this.getIntl("cities." + this.state.cities) + ", " + this.getIntl("app.tutors.title.end")
        // } else {
        //     title += this.getIntl("app.tutors.title.end")
        // }
        // this.setState({
        //     title: title
        // })
    }

    renderRedirect(obj) {
        console.log("renderRedirect", obj)
        // let params = new URLSearchParams();
        let pathname = "/" + constants.locale + "/tutors/";
        let breadcrumbs = [];//["online", "subjects", "levels", "cities", "districts"],
        this.state.path_array.forEach(function (e, i) {
            console.log("Ключ: " + e + " значение: " + obj?.[e]);
            if (!isNaN(obj?.[e]) && !(obj["online"]&&i>2)) {
                console.log(e, obj?.[e])
                //params.set(e, obj?.[e])
                let path = PathParameter.tutors_url?.[e]?.[obj?.[e]];
                if (path) {
                    pathname += path + "/";
                    breadcrumbs.push([e, obj?.[e], path])
                }
            } else {
                console.log("delete: ",e)
                // params.delete(e)
            }
        });
        let getParam = "?" //+ params.toString()
        console.log(window.location.protocol + " || " + window.location.host + " || " + window.location.pathname + " || " + getParam)
        var newurl = constants.location + pathname + getParam;// + '?myNewUrlQuery=1';
        window.history.pushState({ path: newurl }, '', newurl);
        this.setState({ breadcrumbs: breadcrumbs, pathname: pathname});
        // constants.setHeader()
    }

    handleChange = event => {
        let online = event.target.checked ? 1 : 0;
        this.setState({
            online: online
        });
        console.log("online: " + online)
        var object = this.props.currentUser;
        object["onlineSearch"] = online
        // this.userSelectSearch(object);
        this.rerender()
    }
    handleChangeSelect = (event) => {
        let selected = event.target.value
        let obj = event.target.id
        this.setState({
            ["selected_" + obj]: selected
        });
        console.log("selected_" + obj + ": " + selected)
        let price_min = (obj === "price_min") ? selected : this.state.selected_price_min;
        let price_max = (obj === "price_max") ? selected : this.state.selected_price_max;
        this.setPaginatedItem(1, this.state.arrTutors, price_min, price_max)
    }
    handleSubmit = (e) => {
        e.preventDefault()
    }
    contactTutor = (key) => {
        console.log(key)
        // this.state.close_popup[0] = false
        this.setState({ select_tutor: key, close_popup: [false] })
        console.log(this.state.select_tutor)
    }

    componentDidMount() {
        this.getData('price')
        // this.rerender()
        this.setData();

        constants.setHeader()
    }

    setBreadcrumbs = (obj) => {
        let breadcrumbs = [];
        this.state.path_array.forEach(function (e, i) {
            console.log("breadcrumbs: ", e, obj?.[e])
            if (obj?.[e]!=='') breadcrumbs.push([e, obj?.[e], PathParameter.tutors_url?.[e]?.[obj?.[e]]])
        })
        this.setState({ breadcrumbs: breadcrumbs, pathname: window.location.pathname });
    }

    setData = async () => {
        this.setState({ await_fetch: true })
        const o = this.props.currentUser;
        let online = PathParameter.find("online");
        let cities = PathParameter.find("cities");
        let districts = PathParameter.find("districts");
        let subjects = PathParameter.find("subjects");
        let levels = PathParameter.find("levels");
        const obj = {
            jwt: (o.jwt) ? o.jwt : '',
            online: online,
            cities: cities,
            districts: districts,
            subjects: subjects,
            levels: levels
        };
        this.props.currentUser.onlineSearch = online
        this.props.currentUser.cities_search = { search: { cities: cities, districts: districts } }
        this.props.currentUser.subjects_search = { search: { subjects: subjects, levels: levels } }
        console.log("setData - obj", obj);

        const arrTutors = await this.getSelectTutors(obj);
        this.setBreadcrumbs(obj);

        this.setTitle(subjects, online, cities);

        this.setState({
            online: online,
            cities: cities,
            districts: districts,
            subjects: subjects,
            levels: levels,
            arrTutors: arrTutors?.tutors,
            await_fetch: false,
        })

        this.setState({ renderSelect: true })
        this.setPaginatedItem(this.state.paginated_item, arrTutors?.tutors)


    }
    Breadcrumbs = () => {
        let intl = this.props.intl;
        let url = constants.location + "/" + constants.locale + "/tutors/";
        let content=1;
        let name=intl.formatMessage({ id: 'tutors' });
        console.log("this.state.breadcrumbs: ",this.state.breadcrumbs)

        return (<ol className="breadcrumbs" itemscope itemtype="https://schema.org/BreadcrumbList">

            <li itemprop="itemListElement" itemscope itemtype="https://schema.org/ListItem">
                <a itemprop="item" href={url}>
                    <span itemprop="name">{name}</span></a>
                <meta itemprop="position" content={content} />
            </li>
            
            {this.state.breadcrumbs.map(v => {
            content++;
            url+=v?.[2]+"/";
            name+=", "+intl.formatMessage({ id: `${v[0]}.${v[1]}` })
            return(<li itemprop="itemListElement" itemscope itemtype="https://schema.org/ListItem">
            <a itemprop="item" href={url}>
                <span itemprop="name">{name}</span></a>
            <meta itemprop="position" content={content} />
        </li>)
            })}
        </ol>)
    }

    render() {
        // document.title = this.props.intl.formatMessage({ id: 'document.title.tutors' });
        window.onpopstate = (event) => {
            this.setState({ renderSelect: false })
            this.setData()
            constants.setHeader()
        };

        const intl=this.props.intl;


        var title = ((this.state.subjects) ? (intl.formatMessage({ id: "app.tutors.title.lesson"}) + ": " + intl.formatMessage({ id: "subjects." + this.state.subjects}) + ", ") : "")
        if (this.state.online) {
            title += intl.formatMessage({ id: "app.tutors.title.online"}) + ", " + intl.formatMessage({ id: "app.tutors.title.end"})
        } else if (this.state.cities) {
            title += intl.formatMessage({ id: "cities." + this.state.cities}) + ", " + intl.formatMessage({ id: "app.tutors.title.end"})
        } else {
            title += intl.formatMessage({ id: "app.tutors.title.end"})
        }

        const pathname=window.location.pathname;

        return (
            <main className="main_wrapper_block search_tutors">
                <div className="main_wrapper_block_content_stretch">
                    {this.Breadcrumbs()}
                    <div className="search_tutors_title">
                        <Canonical title={title} pathname={pathname} />
                        
                    </div>
                    {/* <h1 className="search_tutors_title"><Canonical title={this.setTitle()}/></h1> */}
                    <div className="search_tutors_wrapper">
                        <form onSubmit={this.handleSubmit} className="search_tutors_wrapper_form">
                            {/* <h2 ><FormattedMessage id="app.filters" /></h2> */}

                            <div className="search_tutors_wrapper_form_block">
                                <p><FormattedMessage id="app.what_do_you_want_to_learn_title" /></p>
                                {(this.state.renderSelect) ?
                                    <SelectSearch_from_search title="app.what_do_you_want_to_learn" object="subjects" level="levels" rerender={this.rerender} />
                                    : ''}
                            </div>

                            <div className="search_tutors_wrapper_form_block">
                                <p><FormattedMessage id="app.where_title" /></p>

                                {(this.state.renderSelect) ?
                                    <SelectSearch_from_search title="app.where" object="cities" hide={this.state.online} level="districts" rerender={this.rerender} />
                                    : ''}

                                {/* <SelectSearch_from_search title="locs.0" object="locs" rerender={this.rerender} /> */}

                                <label className="checkbox_container">
                                    <FormattedMessage id="app.online_lesson" />
                                    <input type="checkbox" hidden onChange={this.handleChange} checked={this.state.online} />
                                    <span className="checkmark"></span>
                                </label>
                            </div>

                            <div className="search_tutors_wrapper_form_block">
                                <p><FormattedMessage id="app.price_title" /></p>
                                {select("price", "price_min", this.state.data_price, this.state.selected_price_min, this.state.selected_price_max, this.props.intl, this.handleChangeSelect.bind(this))}
                                {select("price", "price_max", this.state.data_price, this.state.selected_price_max, this.state.selected_price_min, this.props.intl, this.handleChangeSelect.bind(this))}
                            </div>

                        </form>
                        <div ref={this.myRef} id='tutors_result' className="search_tutors_wrapper_result">

                            <div className="tutors-count"><FormattedMessage id="finds" /> <span >{this.state.totalResults}</span> <FormattedMessage id={declOfNum(this.state.totalResults, ['teachers_questionnaire', 'teacher_questionnaires', 'teachers_questionnaires'])} /></div>
                            {
                                (this.state.selectedTutors?.length) ?
                                    tutorsView(this.state.selectedTutors, this.props.intl, this.contactTutor.bind(this))
                                    : <FormattedMessage id={this.state.await_fetch ? "app.search" : "error.not_find_tutors"} />}

                            <PaginatedItems current={this.state.paginated_item} total={this.state.totalResults} count={this.state.count_item} callback={this.setPaginatedItem.bind(this)} style="dots" />

                            {/* <SweetPagination
        currentPageData={this.setPaginatedItem.bind(this)}
        getData={Array(this.state.totalResults).fill(null).map((_, i) => i)}
        paginated_item={this.state.paginated_item}
        dataPerPage={10}
        navigation={true}
        getStyle={'style-2'}
      /> */}
                            {/* <Pagination
        className="pagination-bar"
        currentPage={this.state.paginated_item}
        totalCount={this.state.paginated_total*this.state.paginated_item}
        pageSize={this.state.count_item}
        onPageChange={page => {console.log("page: ",page); this.setPaginatedItem(page)}}
      /> */}

                        </div>

                    </div>
                    {!this.state.close_popup[0] ? <TutorOrder currentTutor={this.state.select_tutor} close={this.state.close_popup} searchState={this.state} /> : ""}

                <Canonical seo={true}/>
                </div>
            </main>
        )
    }
}
const mapStateToProps = state => ({
    currentUser: state.currentUser
})

const mapDispatchToProps = dispatch => ({
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Tutors));




