import React, { Component } from 'react';
import constants from '../Constants';
import Canonical from '../Canonical';
import { injectIntl, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import UserRedirect from './UserRedirect';

class Forgot extends Component {
  state = {
    email: "",
    submitted: false,
    submitErrors: "",

    formErrors: { email: '' },
    emailValid: false,
    formValid: false,
    formOk: false,
  }

  validateField(fieldName, value) {
    const intl = this.props.intl
    var fieldValidationErrors = this.state.formErrors;
    var emailValid = this.state.emailValid;
    switch (fieldName) {
      case 'email':
        emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) ? true : false;
        fieldValidationErrors.email = emailValid ? '' : intl.formatMessage({ id: "error.is_invalid" });
        break;
      default:
        break;
    }
    this.setState({
      formErrors: fieldValidationErrors,
      emailValid: emailValid,
      formValid: emailValid
    });
  }

  handleChange = event => {
    this.validateField(event.target.name, event.target.value);
    this.setState({
      [event.target.name]: event.target.value,
      submitErrors: ""
    });
  }

  getForgotStatus = async () => {
    try {
      const res = await fetch(constants.location + "/handler/forgot.php", {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify({user: { email: this.state.email }})
      })
      const data = await res.json();
      return data.message==="ok";
    } catch (err) {
      console.log("error: ", err);
      return false;
    }
  }

  handleSubmit = async (event) => {
    event.preventDefault()
    this.setState({ submitted: true })
    let mess = await this.getForgotStatus();
    console.log("mess: ",mess)
    this.setState({
      submitErrors: mess ? "" : this.props.intl.formatMessage({ id: "error.login_is_not_registered" }),
      formOk: mess,
      formValid: false,
    })
  }

  render() {
    return (
      <div>
        {+this.props?.currentUser?.role === 3 ? '' : <UserRedirect />}
        <div id="register" className="table_header">
          {this.state.formOk ?
            <form>
              <p><FormattedMessage id="app.forgot_your_password_ok" values={{ email: this.state.email }} /></p>
              <p><Link to={`/${constants.locale}/login`}><FormattedMessage id="app.login" /></Link></p>
            </form>
            :
            <form onSubmit={this.handleSubmit}>
              <Canonical title={this.props.intl.formatMessage({ id: 'app.forgot_your_password_text' })} />
              <div className="input-wrap">
                <input className="input"
                  id="login-email"
                  type='email'
                  name='email'
                  placeholder='Email'
                  value={this.state.email}
                  onChange={this.handleChange}
                  required
                />
                <label className="placeholder" htmlFor="login-email"><FormattedMessage id="app.email" /></label>
                <div className="input_error">{this.state.formErrors.email}</div>
              </div>
              {this.state.submitErrors}
              <div className="btn-wrap">
                <button type='submit' className={`b_btn btn-green${this.state.submitted ? " submitted" : ""}`} disabled={!this.state.formValid}>
                  <FormattedMessage id="app.forgot_your_password_send" />
                </button>
              </div>

              <p> <Link to={`/${constants.locale}/login`}><FormattedMessage id="app.login" /></Link>  <FormattedMessage id="or" /> <Link to={`/${constants.locale}/register`}><FormattedMessage id="app.sign_up" /></Link>
              </p>
            </form>
          }
        </div>
        <Canonical seo={true} />
      </div>
    )
  }
}
export default injectIntl(connect(null, null)(Forgot));