import React, { Component } from 'react';
import constants from '../Constants';
import Canonical from '../Canonical';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import SelectSearch from '../SelectSearch';
import { userSelectSearch, getSelectTutors } from '../redux/actions';
import PathParameter from '../components/PathParameter';

class PageNotFound extends Component {
    render() {
        console.log("class Redirec: ", `${constants.locale}/`);
        // return <Redirect to={`/${constants.locale}/404`} />;
        return (
            <main className="block_404">
                <Canonical title={this.props.intl.formatMessage({ id: 'page_not_found' })}/>
                <img className="img_404" src="/images/404.svg" />
                <Canonical seo={true} />
            </main>
        )
    }
}

export default injectIntl(connect(null, null)(PageNotFound));