import React, { Component } from 'react';
import constants from '../Constants';
import Canonical from '../Canonical';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import SelectSearch from '../SelectSearch';
import { userSelectSearch, getSelectTutors } from '../redux/actions';
import PathParameter from '../components/PathParameter';

class Sections extends Component {
    constructor(props) {
        super(props);
    }
    state = {
        data: {},
        tabNames: ["languages", "sports", "music", "fine_arts", "school_subjects", "university_courses", "other"],
        tab: +PathParameter.find("sections"),
    }
    handleChange = event => {
        var value = event.target.value
        console.log("value: ", value)
        this.setPath(value);
        this.setState({
            tab: value
        });
    }

    getData = () => {
        fetch(`/data/sections.json`)
            .then(response => response.json())
            .then(data => {
                console.log(data)
                if (data == undefined) {
                    this.setState({
                        hide: true
                    }); return
                };
                this.setState({ data: data });
            });
    };

    getIntl = (id) => {
        const { intl } = this.props;
        return intl.formatMessage({ id: id })
    }


    setPath = (e) => {
        e = e ? e : 0;
        var newurl = constants.location + "/" + constants.locale + "/sections/" + this.state.tabNames[e];
        window.history.pushState({ path: newurl }, '', newurl);
    }

    componentDidMount() {
        // constants.setHeader()
        this.getData();
        this.setPath(this.state.tab);
    }

    render() {
        document.title = this.props.intl.formatMessage({ id: 'document.title.sections' });
        const data = this.state.data
        if (Object.keys(data).length == 0) {
            return <></>
        }
        return (<main className="main_wrapper">
            <div className="main_wrapper_block">
                <div className="main_wrapper_block_content_stretch">
                    <Canonical
                        title={this.props.intl.formatMessage({ id: 'sections' })}
                        pathname={"/" + constants.locale + "/sections/" + this.state.tabNames[this.state.tab]}
                    />
                    {/* <h1><FormattedMessage id="sections" />:</h1> */}
                    <div id="radio_tabs" className="section_block">
                        {Object.keys(data).map(i => (
                            <label key={`radio_tabs_${i}`} className="desctop" >
                                <input name="tab" type="radio" value={i} checked={this.state.tab == i} onChange={this.handleChange} />
                                <div className="wrap_radio__bg"></div>
                                <span className="wrap_radio__label">
                                    <a href={constants.location + "/" + constants.locale + "/sections/" + this.state.tabNames[i]}>
                                        <FormattedMessage id={"sections." + i} />
                                    </a>
                                </span>
                            </label>
                        ))}
                        <select className="sections_select mobile" name="tab" value={this.state.tab} onChange={this.handleChange}>
                            {Object.keys(data).map(i => <option key={`option_${i}`} value={i}>{this.props.intl.formatMessage({ id: "sections." + i })}</option>)}
                        </select>
                    </div>
                    <div className="section_block">
                        {data[this.state.tab]?.map((i) => (
                            <Link key={`link_${i}`} to={`/${constants.locale}/tutors/${PathParameter.tutors_url["subjects"]?.[i]}`} className="section_item" title={this.props.intl.formatMessage({ id: "subjects." + i })}>
                                {/* <Link to={"/tutors?subjects=" + i} key={`link_${i}`} className="section_item"> */}
                                <FormattedMessage id={"subjects." + i} />
                            </Link>
                        ))}
                    </div>
                </div>
            </div>
            <Canonical seo={true} />
        </main>)
    }
}
const mapStateToProps = state => ({
    currentUser: state.currentUser
})

const mapDispatchToProps = dispatch => ({})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Sections));