import React, { Component } from 'react';
import constants from './Constants';
import Canonical from './Canonical';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import SelectSearch_from_search from './SelectSearch_from_search';
import './SearchTutorForm.css';
import { userSelectSearch } from './redux/actions';
import PathParameter from './components/PathParameter';

class SearchTutorForm extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    selected: (this.props.currentUser.onlineSearch),
    cities: (this.props.currentUser.cities_search?.search?.cities!=='' && this.props.currentUser.cities_search?.search?.cities!==undefined) ? this.props.currentUser.cities_search.search.cities : '',
    subjects: '',//(this.props.currentUser.subjects_search?.search?.subjects!=='') ? this.props.currentUser.subjects_search.search.subjects : '',
    submitted: false,
    error: ''
  }

  handleChange = event => {
    let selected = event.target.checked
    this.setState({
      selected: selected,
      error: ''
    });
    var object = this.props.currentUser;
    object["onlineSearch"] = selected
    this.props.userSelectSearch(object);
  }

  handleSubmit = event => {
    event.preventDefault()
    var object = this.props.currentUser
    this.setState({
      subjects: object.subjects_search?.search?.subjects,
      submitted: true,
      error: ''
    })
  }

  renderRedirect = () => {
    if (this.state.submitted) {
      this.setState({ submitted: false })
      // let get = (this.state.subjects) ? `?subjects=${this.state.subjects}` : "";
      let path=`/${PathParameter.tutors_url["subjects"]?.[this.state.subjects]}/`;
      return <Redirect to={`/${constants.locale}/tutors${path}`} />
    }
  }

  rerender = async (object) => {
    console.log("rerender e=",object)
    // var object = this.props.currentUser
    this.setState({
      subjects: object.subjects_search?.search?.subjects,
      error: ''
    })
  }

  render() {
    let path=PathParameter.tutors_url["subjects"]?.[this.state.subjects];
    let url=`/${constants.locale}/tutors/${(path)?path+"/":""}`;
    return (
      <form onSubmit={this.handleSubmit} className="index_search">
        {this.renderRedirect()}
        <div className="index_search_subjects">
          <SelectSearch_from_search title="app.what_do_you_want_to_learn" object="subjects" rerender={this.rerender}/>
        </div>
        <a href={url} className="index_search_button_green" onClick={this.handleSubmit}>
          {this.state.submitted ? '' : (<FormattedMessage id="app.search" />)}
        </a>
      </form>
    )
  }
}

const mapStateToProps = state => ({
  // currentUser: state.currentUser,
  currentUser: state.currentUser
})

const mapDispatchToProps = dispatch => ({
  userSelectSearch: object => dispatch(userSelectSearch(object))
})

export default connect(mapStateToProps, mapDispatchToProps)(SearchTutorForm);