import React, { Component } from 'react';
import constants from '../Constants';
import Canonical from '../Canonical';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import SelectSearch from '../SelectSearch';
import { userSelectSearch, getSelectTutors } from '../redux/actions';

function time(unixTimeStamp) {
    let date = new Date(+unixTimeStamp);
    return ('0' + date.getDate()).slice(-2) + '.' + ('0' + (date.getMonth() + 1)).slice(-2) + '.' + date.getFullYear() + ' ' + ('0' + date.getHours()).slice(-2) + ':' + ('0' + date.getMinutes()).slice(-2);
}
class Chats extends Component {
    constructor(props) {
        super(props);
    }
    state = {
    }

    getIntl = (id) => {
        const { intl } = this.props;
        return intl.formatMessage({ id: id })
    }

    replacer = (e) => {
        e = e.replace(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gm, ' email ');//(?<!\.)
        // e.replace(/(\+{0,})(\d{0,})([(]{1}\d{1,3}[)]{0,}){0,}(\s?\d+|\+\d{2,3}\s{1}\d+|\d+){1}[\s|-]?\d+([\s|-]?\d+){1,2}(\s){0,}/gm, ' phone ');
        e = e.replace(/\+?[0-9][0-9()-\s+.]{4,20}[0-9()-\s+.]/gm, ' phone ');
        return e;
    }
    render() {
        console.log(this.props.chats)
        // if (this.props.chats?.length < 1) {
        //     return <div className="order_card"><FormattedMessage id="data_not_found" /></div>
        // }
        return (<>
        <a className="search-button_green send_message_button" href={`/${constants.locale}/dashboard?tab=chat&id=1`}><FormattedMessage id="write_to_administrator" /></a>
        {(this.props.chats?.length < 1) ?
        <div className="order_card"><FormattedMessage id="data_not_found" /></div>:
             <>
             {this.props.chats?.map((o, a) => {
                return <Link to={"./dashboard?tab=chat&id=" + o.id} onClick={this.props.clickTab?.bind(this, 'chat', o.id)} style={{ textDecoration: "none" }} className="message_preview">
                            <div className="avatar_preview" style={{ backgroundImage: "url('" + o.photo + "')" }} alt={o.name + ' ' + o.lastname} />
                            <div className="message_data">
                                <small>{o.lastname + ' ' + o.name + ' '}</small> <small className="date_time"><FormattedMessage id="from" /> {time(o.time * 1000)}</small>
                                <p className="message_preview-text">{constants.renderHTML(this.replacer(o.message))}</p>
                            </div>
                            {(o.status_count > 0) ? <span className={"new_message_indicator"}>
                                {o.status_count}
                            </span> : ''}
                        {/* 
                        `time`, `status`, `from`, `to`, `message` FROM `chat_mess`
                        `id`, `name`, `lastname`, `photo`
                        from: "53"
                        id: "56"
                        lastname: "Морганюк"
                        message: "sdfgf"
                        name: "Игорь"
                        photo: "/handler/uploads/56_1636631262.png"
                        status: "0"
                        time: "1636922411"
                        to: "56"*/}
                        {/* <div className="row_wrap"><p><FormattedMessage id="customer" />:</p><p>{o.id}</p></div> */}
                </Link>
            })}
            </>
        }
        </>)
    }
}
const mapStateToProps = state => ({
    currentUser: state.currentUser
})

const mapDispatchToProps = dispatch => ({
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Chats));