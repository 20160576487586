import React, { Component } from 'react';
import constants from './Constants';
import Canonical from './Canonical';
import { FormattedMessage } from 'react-intl';
import { BrowserRouter as Router, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getProfileFetch, logoutUser, switchMenu } from './redux/actions';
import { Redirect } from 'react-router-dom';

class Events extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    redirect: false,
    events: { num: 0 }
  }

  handleClick = event => {
    event.preventDefault()
    // Удаление token из localStorage
    localStorage.removeItem("token")
    // удаление из Redux хранилица
    this.props.logoutUser()
    console.log("Redirect to=/")
    this.setState({ redirect: true })
  }

  getEvents = () => {
    if(!this.props.currentUser?.jwt)return false
    const fetch_url = constants.location
    fetch(fetch_url + "/handler/get_events.php", {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'JWT': `${this.props.currentUser.jwt}`
      }
    })
      .then(resp => resp.json())
      .then(data => {
        if (data.error) {
          // Будет ошибка если token не дествительный
          console.log(data.error)
          this.setState({ formError: data.error })
        } else {
          console.log(data)
          if (data?.logout) {
            // Удаление token из localStorage
            localStorage.removeItem("token")
            // удаление из Redux хранилица
            this.props.logoutUser()
          }
          if (+this.state.events?.num !== +data?.events?.num) {
            console.log(this.state.events?.num+" - "+data?.events?.num)
            this.props.currentUser.event = true;
            this.props.switchMenu()
            console.log(this.props)
          }
          this.setState({ load: false, events: data?.events })
        }
      })
      .catch(err => {
        console.log("err")
      })
  }

  getTimerEvents = () => {
    let i = +this.state.timer + 1
    this.setState({ timer: i })
    console.log("timer=" + i);
    this.getEvents()
  }
  timer = null;
  componentDidMount() {
constants.setHeader()
    this.getEvents()
    this.timer = setInterval(
      () => this.getTimerEvents(),
      10000
    );
  }

  render() {
    return (this.props.currentUser?.jwt) ? (
      <div className="drop_menu">
        {(this.state.redirect) ? <Redirect to={`/${constants.locale}/`} /> : ''}
        <div className="drop_menu-current">
          <div className="avatar_preview">
            <svg className="event_img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 333333 333333" shape-rendering="geometricPrecision" text-rendering="geometricPrecision" image-rendering="optimizeQuality" fill-rule="evenodd" clip-rule="evenodd">
              <path d="M68874 6187l23904 23904C58677 54330 35942 93113 33434 137413H1C2341 83919 28753 36778 68874 6187zm195585 0c39953 30591 66365 77732 68873 131226h-33433c-2508-44299-25409-83082-59177-107322l23737-23904zm-72717 22568l-1 11367c47811 11367 75226 54330 75226 105649v83583l33433 33434v16716l-267467 1v-16717l33433-33433v-83583c0-51488 27248-94283 75225-105650V28755c0-13876 11201-25076 25076-25076 13874 0 25075 11201 25075 25076zm-58676 267467h66867c0 4681-836 9027-2507 13039-4347 10030-13206 17385-24072 19726-2173 501-4346 668-6687 669-18556 0-33433-15046-33601-33433z" fill="#2BB801" />
            </svg>
            {(this.state.events?.num > 0) ? <span className="event_red">{this.state.events?.num}</span> : ''}
          </div>
          {/* <span className="arrow">
            <svg id="Capa_1" x="0px" y="0px" viewBox="0 0 477.2 252.2">
              <g>
                <path className="st0" d="M238.6,219.5L23.1,4C17.8-1.3,9.3-1.3,4,4s-5.3,13.8,0,19.1l225.1,225.1c5.3,5.3,13.8,5.3,19.1,0l225-225.1c2.6-2.6,4-6.1,4-9.5s-1.3-6.9-4-9.5c-5.3-5.3-13.8-5.3-19.1,0L238.6,219.5z"></path>
              </g>
            </svg>
          </span> */}
        </div>
        {(this.state.events?.num > 0) ?
          <ul className="drop_menu-dropdown">
            
{(this.state.events?.chats > 0) ?<li><Link className="b_menu__item" to={`/${constants.locale}/dashboard?tab=chats`}><FormattedMessage id="chat_rooms" /><span className="red_event">{this.state.events?.chats}</span></Link></li>:''}
{(this.state.events?.new_orders > 0) ?<li><Link className="b_menu__item" to={`/${constants.locale}/dashboard?tab=new_orders`}><FormattedMessage id="new_orders" /><span className="red_event">{this.state.events?.new_orders}</span></Link></li>:''}
{(this.state.events?.adopted_orders > 0) ?<li><Link className="b_menu__item" to={`/${constants.locale}/dashboard?tab=adopted_orders`}><FormattedMessage id="my_orders" /><span className="red_event">{this.state.events?.adopted_orders}</span></Link></li>:''}
{(this.state.events?.free_orders > 0) ?<li><Link className="b_menu__item" to={`/${constants.locale}/dashboard?tab=free_orders`}><FormattedMessage id="free_orders" /><span className="red_event">{this.state.events?.free_orders}</span></Link></li>:''}

          </ul> : ''}
      </div>
    ) : ('');
  }
}

const mapStateToProps = state => ({
  currentUser: state.currentUser
  //currentUser: state.reducer.currentUser
})

const mapDispatchToProps = dispatch => ({
  getProfileFetch: () => dispatch(getProfileFetch()),
  logoutUser: () => dispatch(logoutUser()),
  switchMenu: () => dispatch(switchMenu())
})

export default connect(mapStateToProps, mapDispatchToProps)(Events);

