document.addEventListener('DOMContentLoaded', function(){
    const body=document.querySelector('body');
    function start(){
      body.classList.add('start');
    }
  


    const menu=document.getElementById("menu_icon"),
    main=document.querySelector(".main"),
    root=document.getElementById("root"),
    rootMenu=document.querySelector(".root_menu");
    // sliderArrowNext=document.querySelector(".slider-arrow-next");
    // let menuArrow=sliderArrowNext.querySelectorAll("div");
    // function changeMenuArrow(i){
    //   menuArrow.forEach((e, i) => {
    //       e.classList.remove("active");
    //   });
    //   menuArrow[i].classList.add("active");
    // }      

    function menuClick() {
        if(menu.classList.contains('active')){
            menu.querySelectorAll('.button-play').forEach((e, i) => {
                e.beginElement();
            });
            menu.classList.remove('active');
        }else{
            menu.querySelectorAll('.button-pause').forEach((e, i) => {
                e.beginElement();
            });
            menu.classList.add('active');
        }
        return false;
    };

    function menuBar(){
        menuClick();
        main.classList.toggle("menu_active");
        root.classList.toggle("menu_active");
        rootMenu.classList.toggle("menu_active");
    }
    // menu.addEventListener("click", menuBar);







    start();
});

(function init100vh(){
    function setHeight() {
        var vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    }
    setHeight();
    window.addEventListener('resize', setHeight);
})();



