import React, {Component} from 'react';
import constants from '../Constants';
import Canonical from '../Canonical';
import {IntlProvider, FormattedMessage} from 'react-intl';
import {connect} from 'react-redux';
import {userLoginFetch} from '../redux/actions';

class Login extends Component {
  state = {
    email: "",
    password: "",
    submitted: false,


    formErrors: {email: '', password: ''},
    emailValid: false,
    passwordValid: false,
    formValid: false,
    submitErrors: this.props.error?this.props.error:""
  }

  validateField(fieldName, value) {
    var fieldValidationErrors = this.state.formErrors;
    var emailValid = this.state.emailValid;
    var passwordValid = this.state.passwordValid;
  switch(fieldName) {
      case 'email':
        emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)?true:false;
        fieldValidationErrors.email = emailValid ? '' : ' is invalid';
        break;
      case 'password':
        passwordValid = value.length >= 6;
        fieldValidationErrors.password = passwordValid ? '': ' is too short';
        break;
      default:
        break;
    }
    this.setState({formErrors: fieldValidationErrors,
                    emailValid: emailValid,
                    passwordValid: passwordValid,
                    formValid: emailValid && passwordValid
                  });
  }

  handleChange = event => {
    this.validateField(event.target.name, event.target.value);
    this.setState({
      [event.target.name]: event.target.value,
      submitErrors: ""
    });
  }

  handleSubmit = event => {
    event.preventDefault()
    this.setState({submitted: true})
    if(!this.state.formValid)this.setState({submitted: false});
    if(this.state.formValid)this.props.userLoginFetch(this.state).then(items => {
      this.formError(items);
      });
  }
  formError = event => {
    if(this.props.error){
      this.setState({submitted: false, submitErrors: this.props.error})
    };
  }

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
      <h1><strong><FormattedMessage id="app.login"/></strong></h1>

        <div className="input-wrap">
        <input
          id="login-email"
          type='email'
          name='email'
          placeholder='Email'
          value={this.state.email}
          onChange={this.handleChange}
          required
          />
          <label className="placeholder" htmlFor="login-email"><FormattedMessage id="app.email"/></label>
          <div className="input_error">{this.state.formErrors.email}</div>
        </div>

        <div className="input-wrap">
        <input
          id="login-password"
          type='password'
          name='password'
          placeholder='Password'
          value={this.state.password}
          onChange={this.handleChange}
          required
          />
          <label className="placeholder" htmlFor="login-password"><FormattedMessage id="app.password"/></label>
          <div className="input_error">{this.state.formErrors.password}</div>
        </div>

        <div className="btn-wrap">
          <button type='submit' className={`btn ${this.state.submitted ? "submitted" : ""}`} disabled={!this.state.formValid}><FormattedMessage id="app.enter"/>
            <svg width="8.574358mm" height="9.8692408mm" viewBox="0 0 8.574358 9.8692408" version="1.1">
              <path  d="M 5.5116534,2.38803 2.2173964,0.38070997 c -0.514732,-0.22305 -1.85303797,0.0172 -1.90451297,1.37683003 l -0.01288,6.31798 c -0.0025,1.22139 1.39419097,1.80501 2.17474297,1.31254 l 5.404666,-3.40994 c 0.380397,-0.24 0.627785,-1.58927 0.05564,-2.02354 L 7.5835704,3.6878"/>
            </svg>
          </button>
        </div>
        <div className="input_error_popup">{this.state.submitErrors}</div>

      </form>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  userLoginFetch: userInfo => dispatch(userLoginFetch(userInfo))
})

export default connect(null, mapDispatchToProps)(Login);