import React, { Component, useEffect, useState } from 'react';
import constants from '../Constants';
import Canonical from '../Canonical';
import { IntlProvider, FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router-dom';

// import { Editor } from "react-draft-wysiwyg";
// import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';
// import { stateToHTML } from 'draft-js-export-html';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import parse from 'html-react-parser';


import RichTextEditor from './RichTextEditor';


function time(unixTimeStamp) {
    let date = new Date(+unixTimeStamp);
    return ('0' + date.getDate()).slice(-2) + '.' + ('0' + (date.getMonth() + 1)).slice(-2) + '.' + date.getFullYear() + ' ' + ('0' + date.getHours()).slice(-2) + ':' + ('0' + date.getMinutes()).slice(-2);
}
function findGetParameter(parameterName) {
    var result = "",
        tmp = [];
    window.location.search
        .substr(1)
        .split("&")
        .forEach(function (item) {
            tmp = item.split("=");
            if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
        });
    return result;
}

class Blogs extends Component {
    state = {
        close: [false, false, false],
        articles: [],
        selectManager: []
    }

    handleChange = event => {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleSubmit = event => {
        event.preventDefault()
        this.setState({ submitted: true })
        this.props.userPostForgot(this.state)
    }

    renderRedirect = () => {
        console.log("jwt: " + (!!this.props.currentUser.jwt) + " role: " + this.props.currentUser.role + " role!=1: " + (this.props.currentUser.role != 3))
        if (!this.props.currentUser.jwt || this.props.currentUser.role != 1) {
            console.log("<Redirect to={`/login} />")
            return <Redirect to={`/${constants.locale}/login`}
            />
        }
    }

    getManagers = () => {
        const fetch_url = constants.location
        fetch(fetch_url + `/handler/get_articles.php?offset=0&tutor=${this.props.currentUser.id}`, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'JWT': `${this.props.currentUser.jwt}`
            }
        })
            .then(resp => resp.json())
            .then(data => {
                if (data.error) {
                    // Будет ошибка если token не дествительный
                    console.log(data.error)
                    this.setState({ formError: data.error })
                } else {
                    console.log(data)
                    this.setState({ load: false, articles: data?.articles })
                }
            })
            .catch(err => {
                console.log("err")
            })
    }
    showPopup = (i, n) => {
        let close = this.state.close
        close[i] = !close[i]
        this.setState({ selectManager: n, close: close })
    }

    componentDidMount() {
constants.setHeader()
        this.getManagers()
    }

    render() {
        return (<>
            {this.renderRedirect()}
            <div className="container" >
            {(this.state.close[0]||this.state.close[1])?'':<button className="b_btn btn-green" onClick={this.showPopup.bind(this, 0)}><FormattedMessage id="create_article" /></button>}
            </div>
            {(this.state.close[0]) ? <AddArticle act="add" intl={this.props.intl} close={this.showPopup.bind(this, 0)} currentUser={this.props.currentUser} /> : ''}
            {(this.state.close[1]) ? <AddArticle act="change" o={this.state.selectManager} intl={this.props.intl} close={this.showPopup.bind(this, 1)} currentUser={this.props.currentUser} /> : ''}
            
            {(this.state.close[0]||this.state.close[1])?'':
            (this.state.articles?.length < 1) ? <div className="order_card"><FormattedMessage id="data_not_found" /></div> : <>
                {this.state.articles?.map((o, a) => {
                    return <div className="order_card">
                        <div className="row_wrap"><p><FormattedMessage id="subjects" />:</p><p>
                            <FormattedMessage id={'subjects.' + o.subjects} />
                        </p></div>
                        <h1>{o?.title}</h1>
                        {/* <p>{o?.text}</p> */}
                        <div className="article_description ql-snow"><section className="ql-editor" dangerouslySetInnerHTML={{ __html: parse(o?.text.replaceAll("rel=\"noopener noreferrer\" target=\"_blank\"", "")) }} /></div>
                                    
                        <span>{time(+o?.time * 1000)}</span>
                        <button className="b_btn btn-green" onClick={this.showPopup.bind(this, 1, o)}><FormattedMessage id="change" /></button>
                    </div>
                })}</>}
        </>
        )
    }
}
class AddArticle extends Component {
    state = {
        subjects: (this.props.o?.subjects) ? this.props.o.subjects : "",
        title: (this.props.o?.title) ? this.props.o.title : "",
        text: (this.props.o?.text) ? parse(this.props.o.text) : "",

        submitted: false,
        formErrors: { subjects: '', title: '', email: '' },
        subjectsValid: (this.props.o?.subjects) ? true : false,
        titleValid: (this.props.o?.title) ? true : false,
        textValid: (this.props.o?.login) ? true : false,
        formValid: (this.props.o?.subjects && this.props.o?.title && this.props.o?.text) ? true : false,
        submitErrors: "",
        load: false,

        query: "",
        data: [],
        filteredData: [],
        showSelect: false,
        selectedId: "",
        // editorState: EditorState.createEmpty()
    }
    setEditorState = (value) => {
        console.log("RichTextEditorProps ",value)
        value=value.replaceAll("'","&#8216;")
        this.validateField('text', value.replace(/<[^>]+>/g, ''));
        this.setState({
            text: value
        })
    }

    handleInputChange = event => {
        const intl = this.props.intl;
        const query = event.target.value;
        this.setState(prevState => {
            const filteredData = prevState.data.filter(element => {
                element = 'subjects.' + element;
                return intl.formatMessage({ id: element }).toLowerCase().includes(query.toLowerCase());
            });
            return {
                query,
                filteredData
            };
        });
    };

    getData = () => {
        fetch(`/data/subjects.json`)
            .then(response => response.json())
            .then(data => {
                var filteredData = data;
                this.setState({
                    data,
                    filteredData
                });
            });
    };

    openSelect = () => {
        this.setState({ showSelect: !this.state.showSelect });
    }
    chooseSelect = (e) => {
        var selected = e.target.getAttribute("data-val");
        this.validateField('subjects', selected)
        this.setState({ subjects: selected });
        this.setState({ showSelect: !this.state.showSelect });
    }

    validateField(fieldName, value) {
        const intl = this.props.intl
        var fieldValidationErrors = this.state.formErrors;
        var titleValid = this.state.titleValid;
        var textValid = this.state.textValid;
        var subjectsValid = this.state.subjectsValid;
        console.log("validateField",value)
        switch (fieldName) {
            case 'title':
                titleValid = value.length >= 5;
                fieldValidationErrors.title = titleValid ? '' : intl.formatMessage({ id: "error.is_too_short" });
                break;
            case 'text':
                textValid = value.length >= 100;
                fieldValidationErrors.text = textValid ? '' : intl.formatMessage({ id: "error.is_too_short" });
                break;
            case 'subjects':
                subjectsValid = value.length !== '';
                fieldValidationErrors.subjects = subjectsValid ? '' : intl.formatMessage({ id: "error.is_too_short" });
                break;
            default:
                break;
        }
        this.setState({
            formErrors: fieldValidationErrors,
            titleValid: titleValid,
            textValid: textValid,
            subjectsValid: subjectsValid,
            formValid: titleValid && textValid && subjectsValid,
            submitErrors: ""
        });
    }

    handleChange = event => {
        var value = event.target.value//.trim()
        this.validateField(event.target.name, value);
        this.setState({
            [event.target.name]: value,
            submitErrors: ""
        });
    }

    handleSubmit = event => {
        event.preventDefault()
        this.setState({ submitted: true })
        if (!this.state.formValid) this.setState({ submitted: false });
        const fetch_url = constants.location;
        if (this.state.formValid && this.props.act == "add") {
            this.setState({ load: true })
            const obj = {
                "jwt": this.props.currentUser.jwt,
                title: this.state.title,
                text: this.state.text,
                subjects: this.state.subjects
            }
            console.log(obj);
            fetch(fetch_url + "/handler/add_article.php", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                },
                body: JSON.stringify({ obj })
            })
                .then(resp => resp.json())
                .then(data => {
                    if (data.error) {
                        console.log(data.error)
                        this.setState({ load: false, submitErrors: data.error })
                    } else {
                        console.log(data)
                        this.setState({ load: false })
                        this.close.bind(this)
                    }
                }).catch(err => { console.log("err") })
        } else if (this.props.act == "change") {
            this.setState({ load: true })
            const obj = {
                "jwt": this.props.currentUser.jwt,
                title: this.state.title,
                text: this.state.text,
                subjects: this.state.subjects
            }
            fetch(fetch_url + "/handler/add_article.php?id="+this.props.o?.id, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                },
                body: JSON.stringify({ obj })
            })
                .then(resp => resp.json())
                .then(data => {
                    if (data.error) {
                        console.log(data.error)
                        this.setState({ load: false, submitErrors: data.error })
                    } else {
                        console.log(data)
                        this.setState({ load: false })
                        this.close.bind(this)
                    }
                }).catch(err => { console.log("err") })
        }
    }
    handleCheck = e => {
        var value = e.target.checked;
        var obj = e.target.name
        this.setState({ [obj]: value });
    }
    componentDidMount() {
constants.setHeader()
        this.getData();
    }

    close = () => {
        this.props.close()
    }
    render() {
        const intl = this.props.intl;
        return (<form className="order_card" onSubmit={this.handleSubmit}>
            <span onClick={this.close.bind(this)} className="close_button"></span>
            {(this.state.load) ? <span className="load_submitted"></span> : ''}

            <div className="input-wrap">
                <input className="input"
                    id="title-name"
                    type='text'
                    name='title'
                    placeholder='title'
                    value={this.state.title}
                    onChange={this.handleChange}
                    required
                />
                <label className="placeholder" htmlFor="title-name"><FormattedMessage id="title_of_article" /></label>
                <div className="input_error">{this.state.formErrors.title}</div>
            </div>

            <RichTextEditor
                    value={this.state.text} 
                    onChange={e=>this.setEditorState(e)}
                    />
                <div className="input_error">{this.state.formErrors.text}</div>
            <div className="search_form">
                <span onClick={this.openSelect} className={(this.state.showSelect ? "active" : "")} data-id={this.state.selectedId ? this.state.selectedId : ""} data-name={this.state.subjects ? this.state.subjects : ""}><FormattedMessage id={this.state.subjects ? "subjects." + this.state.subjects : "subject_of_article"} /></span>
                <div className={"select_search_close " + (this.state.showSelect ? "show" : "hide")} onClick={this.openSelect}></div>
                <div className={"select_search " + (this.state.showSelect ? "show" : "hide")}>
                    <input
                        placeholder={intl.formatMessage({ id: 'app.search' })}
                        value={this.state.query}
                        onChange={this.handleInputChange}
                    />
                    <ul>{this.state.filteredData.map((key, i) => <li onClick={this.chooseSelect.bind(this)} className={this.state.subjects == key ? "selected" : ""} data-id={i} data-val={key} ><FormattedMessage id={"subjects." + key} /> </li>)}</ul>
                </div>
            </div>

            <div className="input_error">{this.state.formErrors.subjects}</div>

            <div className="btn-wrap">
                <button type='submit' className={`b_btn btn-green ${this.state.submitted ? "submitted" : ""}`} disabled={!this.state.formValid}>
                    <FormattedMessage id="save" />
                </button>
            </div>
            {(this.state.submitErrors) ? <p>{this.state.submitErrors}</p> : ''}
        </form>)
    }
}



const mapStateToProps = state => ({
    currentUser: state.currentUser
})

const mapDispatchToProps = dispatch => ({})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Blogs));



// <p>Як швидко вивчити англійську мову?&nbsp;</p>
// <p>Таке запитання часто задають учні з різним рівнем знаньТож поділюся своїм досвідомХороший досвідчений репетиторце звичайно прекрасноале крім регулярних занять з репетитором потрібно учневі докласти неабияких зусильОкрім вивчення поданого учителем матеріалу вамдорогі учніпотрібно постійно дивитися англомовні фільмиабо короткі відеомультфільмислухати пісніЩоб це було цікавообирайте жанри відповідно своїм вподобанням</p>
// <p>Адже щоб навчитися розуміти і правильно говоритипотрібно постійно чути мовуа також і застосовувати її на практиціЗ другим завданням теж можливо справитисяяк варіант можна знайти друга по переписціЦе чудова можливість застосовувати набуті знання і вивчити чималий обсяг нових сліввиразів і ідіом</p>
// <p>За приклад можемо взяти одну з найбільш розвинених країн світуДаніюТут майже всі з легкістю розмовляють поанглійськиХоча репетиторство в Данії не таке популярнеяк в УкраїніДанці вивчають англійську мову з першого по девʼятий класПрограма набагато легшаніж у наспроте після закінчення школи більшість вільно володіє англійською мовоюЧого не можна сказати про випускників наших шкілБагато школярів так і не можуть подолати мовний барʼєрЧому такПояснення простеу данців майже всі фільми транслюються мовою оригіналу з титрами на данській мовіТобто виходитьщо вони вивчають англійську мову в повсякденному життіокрім вивчення за шкільною партоюТож давайте візьмемо цей досвід за приклад</p>
// <p>Прислухайтеся моєї поради і ви одразу побачите результатТоді послуги репетитора вам знадобляться лише для пояснення граматичного матеріалу</p>


// &lt;p&gt;Як швидко вивчити англійську мову?&amp;nbsp;&lt;/p&gt;
// &lt;p&gt;Таке запитання часто задають учні з різним рівнем знаньТож поділюся своїм досвідомХороший досвідчений репетиторце звичайно прекрасноале крім регулярних занять з репетитором потрібно учневі докласти неабияких зусильОкрім вивчення поданого учителем матеріалу вамдорогі учніпотрібно постійно дивитися англомовні фільмиабо короткі відеомультфільмислухати пісніЩоб це було цікавообирайте жанри відповідно своїм вподобанням&lt;/p&gt;
// &lt;p&gt;Адже щоб навчитися розуміти і правильно говоритипотрібно постійно чути мовуа також і застосовувати її на практиціЗ другим завданням теж можливо справитисяяк варіант можна знайти друга по переписціЦе чудова можливість застосовувати набуті знання і вивчити чималий обсяг нових сліввиразів і ідіом&lt;/p&gt;
// &lt;p&gt;За приклад можемо взяти одну з найбільш розвинених країн світуДаніюТут майже всі з легкістю розмовляють поанглійськиХоча репетиторство в Данії не таке популярнеяк в УкраїніДанці вивчають англійську мову з першого по девʼятий класПрограма набагато легшаніж у наспроте після закінчення школи більшість вільно володіє англійською мовоюЧого не можна сказати про випускників наших шкілБагато школярів так і не можуть подолати мовний барʼєрЧому такПояснення простеу данців майже всі фільми транслюються мовою оригіналу з титрами на данській мовіТобто виходитьщо вони вивчають англійську мову в повсякденному життіокрім вивчення за шкільною партоюТож давайте візьмемо цей досвід за приклад&lt;/p&gt;
// &lt;p&gt;Прислухайтеся моєї поради і ви одразу побачите результатТоді послуги репетитора вам знадобляться лише для пояснення граматичного матеріалу&lt;/p&gt;