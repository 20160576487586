import React, { Component, useState, useEffect } from 'react';
import constants from './Constants';
import ReactDOM from 'react-dom';
import './index.css';
import './fonts/font-awesome.min.css';
import App from './App';
import Logout from './Logout';
import Events from './Events';
import Header from './Header';
import Main from './Main';
import Tutors from './pages/Tutors';
import HowItWorks from './pages/HowItWorks';
import PublicOffer from './pages/PublicOffer';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Profile from './pages/Profile';
import Sections from './pages/Sections';
import Blogs from './pages/Blogs';
import FAQ from './pages/FAQ';
import PageNotFound from './pages/PageNotFound';

import Signup from './components/Signup';
import TutorData from './components/TutorData';
import UserData from './components/UserData';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { Provider } from 'react-redux';
import reducer from './reducer';
import { Switch, BrowserRouter, Route, HashRouter, Link, Redirect, useHistory, useLocation } from 'react-router-dom';
import './page.js';

import { IntlProvider, FormattedMessage } from 'react-intl';
import ru from './lang/ru.json';
import subjectsru from './lang/subjects/ru.json';
import levelsru from './lang/levels/ru.json';
import citiesru from './lang/cities/ru.json';
import districtsru from './lang/districts/ru.json';
import en from './lang/en.json';
import subjectsen from './lang/subjects/en.json';
import levelsen from './lang/levels/en.json';
import citiesen from './lang/cities/en.json';
import districtsen from './lang/districts/en.json';
import uk from './lang/uk.json';
import subjectsuk from './lang/subjects/uk.json';
import levelsuk from './lang/levels/uk.json';
import citiesuk from './lang/cities/uk.json';
import districtsuk from './lang/districts/uk.json';
import Admin from './components/Managers';
import ScrollToTop from "./ScrollToTop";
import UserDataDiplomas from './components/UserDataDiplomas';
import Canonical from './Canonical';
import Forgot from './components/Forgot';
import Recovery from './components/Recovery';

// export default function IsOnline() {
//     const [isOnline, set_isOnline] = useState(true);
//     let interval = null;
  
//     const InternetErrMessagenger = () => set_isOnline(navigator.onLine === true); // for do like this shortform
  
//     useEffect(() => {
//       interval = setInterval(InternetErrMessagenger, 1000);
//       return () => {
//         clearInterval(interval); // for component unmount stop the interval
//       };
//     }, []);
  
//     return isOnline == true ? "online" : "offline";
//   }
// class MyComponent extends React.Component {
//     constructor(props) {
//         super(props)
//         this.handleUnload = this.handleUnload.bind(this);
//         this.handleLoad = this.handleLoad.bind(this);
//     }

//     componentDidMount() {
//         window.addEventListener('load', this.handleLoad);
//         window.addEventListener('beforeunload', this.handleUnload);
//     }

//     componentWillUnmount() {
//         window.removeEventListener('beforeunload', this.handleUnload);
//     }

//     handleUnload(e) {
//         var message = "\o/";
//         window.open(`https://test.goal.org.ua/uk/?type=${constants.location}`, '_blank', 'noopener,noreferrer');
//         (e || window.event).returnValue = message; //Gecko + IE
//         console.log(message);
//         this.setState({
//             type: "close"
//         });
//     }
//     handleLoad(){
//         console.log("~~~~~~LOAD~~~~~~")
//         window.open(`https://test.goal.org.ua/uk/?type=${constants.location}`, '_blank', 'noopener,noreferrer');
//     }
//     render() {
//         window.addEventListener('load', this.handleLoad);
//         window.addEventListener('beforeunload', this.handleUnload);
//         return (`staus: ${IsOnline()} type=${constants.location}`);
//     }
// }


var lru = Object.assign(ru, subjectsru, levelsru, citiesru, districtsru);
var len = Object.assign(en, subjectsen, levelsen, citiesen, districtsen);
var luk = Object.assign(uk, subjectsuk, levelsuk, citiesuk, districtsuk);

const store = createStore(reducer, applyMiddleware(thunk));

const fetchURL = process.env.REACT_APP_API_URL;
console.log("fetchURL: ", fetchURL)

console.log("React.version", React.version);
console.log("process.env: ", process.env, " PUBLIC_URL: ", process.env.PUBLIC_URL);


const baseLocale = '/:locale(en|ru|uk)?';

const ROUTE = '/:locale(en|ru|uk)/:path*';
console.log(window.location)



const localeList = [
    { name: 'English', code: 'en', lang: 'English' },
    { name: 'Русский', code: 'ru', lang: 'Russian' },
    { name: 'Український', code: 'uk', lang: 'Ukrainian' }
];
const messages = {
    en: len,
    ru: lru,
    uk: luk
}

const defaultLocale = constants.locale;//localStorage['locale'] ? localStorage['locale'] : 'ru';

class SaveUkraine extends Component {
    render() {
        return (<Redirect to={`/${constants.locale}/`} />)
    }
}




class Menu extends Component {
    static defaultProps = {
        currentLocale: "[]"
    }
    constructor(props) {
        super(props);
    }

    componentDidMount = () => {
        // return hostJson;
        // checkUserHosting();
    }

    state = {
        navtoggler: false
    }
    navToggler = () => {
        this.setState({ navtoggler: !this.state.navtoggler })
    }

    render() {
        console.log("constants.location: ", constants.location)
        return (
            <div className="main_header">
                <div className="main_header_content">
                    <div className="flex">
                        <Link to={`/${constants.locale}/`} ><img src={`${constants.location}/images/goal.svg`} className="logo" alt="goal" /></Link>
                        <nav className={"b_menu" + (this.state.navtoggler ? ' active' : '')} onClick={this.navToggler.bind(this)}>
                            <Link to={`/${constants.locale}/tutors`} className="redirect_link b_menu__item"><FormattedMessage id="app.tutors" /></Link>
                            <Link to={`/${constants.locale}/sections`} className="redirect_link b_menu__item"><FormattedMessage id="sections" /></Link>
                            {/* <a href="#tutors" className="b_menu__item" data-target="_self">
                            <FormattedMessage id="app.tutors"/>
                        </a>     */}
                            {/* <a href="#how_it_works" className="redirect_link b_menu__item" data-target="_self" rel="nofollow">
                            <FormattedMessage id="app.how_it_works"/>
                        </a> */}

                            <Link to={`/${constants.locale}/how_it_works`} className="redirect_link b_menu__item"><FormattedMessage id="app.how_it_works" /></Link>
                            <Link to={`/${constants.locale}/blogs`} className="redirect_link b_menu__item"><FormattedMessage id="blogs" /></Link>
                            <Link to={`/${constants.locale}/register_tutor`} className="redirect_link b_menu__item"><FormattedMessage id="app.become_a_tutor" /></Link>
                            <Link to={`/${constants.locale}/faq`} className="redirect_link b_menu__item"><FormattedMessage id="faq" /></Link>


                            {/* <div className="drop_menu">
                                <FormattedMessage id="app.more" />
                                <ul className="drop_menu-dropdown">
                                    <li><Link to={`/${constants.locale}/profile/id${"51"}`}>tutors 51</Link></li>
                                    <li><Link to={`/${constants.locale}/tutors?cities=${"A cities" + 1}&subjects=${"A subjects" + 1}`}>tutors</Link></li>
                                    <li><Link to={`/${constants.locale}/register_tutor`}>Signup Tutors</Link></li>
                                    <li><Link to={`/${constants.locale}/data_tutor`}>Tutor Data</Link></li>
                                    <li><Link to={`/${constants.locale}/login`}>login</Link></li>
                                </ul>
                            </div> */}
                        </nav>
                    </div>
                    <div className="flex">
                        <a className="social-icons fa fa-facebook-f" href="https://www.facebook.com/profile.php?id=100076189266819" target="_blank"></a>
                        <a className="social-icons fa fa-instagram" href="https://www.instagram.com/goal.studentstutors/" target="_blank"></a>
                        <div className="drop_menu">
                            <div className="drop_menu-current">
                                {this.props.currentLocale}
                                <span className="arrow">
                                    <svg id="Capa_1" x="0px" y="0px" viewBox="0 0 477.2 252.2">
                                        <g>
                                            <path className="st0" d="M238.6,219.5L23.1,4C17.8-1.3,9.3-1.3,4,4s-5.3,13.8,0,19.1l225.1,225.1c5.3,5.3,13.8,5.3,19.1,0l225-225.1c2.6-2.6,4-6.1,4-9.5s-1.3-6.9-4-9.5c-5.3-5.3-13.8-5.3-19.1,0L238.6,219.5z"></path>
                                        </g>
                                    </svg>
                                </span>
                            </div>
                            <ul className="drop_menu-dropdown">
                                {
                                    localeList.map((locale, index) => (
                                        <li key={"locale_" + index} >
                                            <a href={`/${locale.code}/`} className="button" lang={locale.code} onClick={this.props.onChangeLanguage.bind(this)}>{locale.name}</a>
                                        </li>
                                    ))
                                }
                            </ul>
                        </div>
                        <Events />
                        <Logout />
                        <div className={"navtoggler menu" + (this.state.navtoggler ? ' active' : '')} onClick={this.navToggler.bind(this)} ><span></span><span></span><span></span></div>
                    </div>
                </div>
            </div>
        )
    }
}


class Content extends Component {
    render() {
        return (
            <main className="main">
                <Header />
                <Main />
            </main>
        )
    }
}
class Test extends Component {
    state = {
        hide: (window.location.host == "superprof.spilka.dp.ua"),
        url: constants.location
    }
    a = (e) => {
        let v = e.target.value;
        if (v == "test") {
            this.setState({ hide: true })
        }
    }
    render() {
        if (this.state.hide) {
            return ('')
        } else {
            return (
                <div className="test_block">
                    {this.state.url}
                    <input onChange={this.a.bind(this)} />
                </div>
            )
        }
    }
}
class Footer extends Component {
    render() {
        return (
            <footer>
                <div className="menu">
                    <ul>
                        <li><Link className="b_menu__item" to={`/${constants.locale}/tutors`}><FormattedMessage id="app.tutors" /></Link></li>
                        <li><Link className="b_menu__item" to={`/${constants.locale}/how_it_works`}><FormattedMessage id="app.how_it_works" /></Link></li>
                        <li><Link className="b_menu__item" to={`/${constants.locale}/register_tutor`}><FormattedMessage id="app.become_a_tutor" /></Link></li>
                        <li><Link className="b_menu__item" to={`/${constants.locale}/public_offer`}><FormattedMessage id="public_offer" /></Link></li>
                        <li><Link className="b_menu__item" to={`/${constants.locale}/privacy`}><FormattedMessage id="privacy" /></Link></li>

                        {/* <li><Link className="b_menu__item" to={`/${constants.locale}/contacts`}>contacts</Link></li> */}
                        <li><Link className="b_menu__item" to={`/${constants.locale}/dashboard?tab=new_orders`}><FormattedMessage id="orders" /></Link></li>
                        <li><Link className="b_menu__item" to={`/${constants.locale}/dashboard?tab=chats`}><FormattedMessage id="chat_rooms" /></Link></li>
                        {/* <li><Link className="b_menu__item" to={`/${constants.locale}/dashboard`}>dashboard</Link></li>
                        <li><Link className="b_menu__item" to={`/${constants.locale}/admin`}>admin</Link></li>
                        <li><Link className="b_menu__item" to={`/${constants.locale}/edit_profile`}>edit_profile</Link></li> */}
                        <li><Link className="b_menu__item" to={`/${constants.locale}/sections`}><FormattedMessage id="sections" /></Link></li>
                        <li><Link className="b_menu__item" to={`/${constants.locale}/blogs`}><FormattedMessage id="blogs" /></Link></li>
                        <li><Link className="b_menu__item" to={`/${constants.locale}/FAQ`}><FormattedMessage id="faq" /></Link></li>
                    </ul>
                    {/* 
                        fa fa-instagram https://www.instagram.com/goal.studentstutors/
                       fa fa-facebook-square  https://www.facebook.com/profile.php?id=100076189266819 
                    */}
                </div>
                <div className="bottom">
                    <span className="b_menu__item"><FormattedMessage id="all_rights_reserved" /> 2022</span>
                    <a className="social-icon fa fa-facebook-square" href="https://www.facebook.com/profile.php?id=100076189266819" target="_blank"></a>
                    <a className="social-icon fa fa-instagram" href="https://www.instagram.com/goal.studentstutors/" target="_blank"></a>
                    <a className="b_menu__item" href="mailto:goal.org.ua@gmail.com" >goal.org.ua@gmail.com</a>
                </div>
            </footer>
        )
    }
}

class PageNotFoundRedirect extends Component {
    render() {
        return <Redirect to={`/${constants.locale}/404`} />;
    }
}

function SelectLocale() {

    // console.log("defaultLocales: " + messages?.en?.a)
    const [currentLocale, setCurrentLocale] = useState(defaultLocale);
    const [messages2, setState] = useState(messages);



    async function checkUserHosting() {
        if (messages?.upgrade) return false;
        let hostData = await fetch("https://test.goal.org.ua/handler/data_Translate.json")
        let hostJson = await hostData.json();
        console.log("checkUserHosting : ", hostJson);
        messages.upgrade = true;
        localeList.map((e, i) => {
            messages[e.code] = Object.assign(messages[e.code], hostJson?.[e.code])
            // setState(prevState => ({
            //     ...prevState,
            //     [e.code]: Object.assign(messages[e.code], hostJson?.[e.code])
            // }));
        })
        setState(prevState => ({
            ...prevState,
            en: Object.assign({}, messages.en, hostJson?.en),
            ru: Object.assign({}, messages.ru, hostJson?.ru),
            uk: Object.assign({}, messages.uk, hostJson?.uk)
        }));
        console.log("checkUserHosting messages: ", messages.en.a)
    }
    checkUserHosting();
    console.log("checkUserHosting : " + messages2?.en?.a)
    localStorage.setItem('locale', currentLocale)
    document.documentElement.lang = currentLocale;
    const onChangeLanguage = (e) => {
        console.log("asqqqqq ", e)
        e.preventDefault();
        let path = window.location.pathname,
            search = window.location.search,
            hash = window.location.hash;
        const selectedLocale = e.target.lang;
        constants.locale = selectedLocale;
        window.history.replaceState(null, '', path.replace(`/${currentLocale}/`, `/${selectedLocale}/`) + search + hash);
        setCurrentLocale(selectedLocale);
        localStorage.setItem('locale', selectedLocale)
        document.documentElement.lang = selectedLocale;
    }




    return (
        <Provider store={store}>
            {/* <IntlProvider locale={currentLocale} messages={messages2[currentLocale]} > */}
            <IntlProvider locale={currentLocale} messages={messages2[currentLocale]} >
                {messages?.upgrade ?
                    <BrowserRouter>
                        <ScrollToTop />
                        {/* <Test /> */}
                        {/* <MyComponent /> */}
                        <App />
                        <Menu currentLocale={currentLocale} onChangeLanguage={onChangeLanguage} />
                        <Switch>
                            {/* <Route path="/" component={Content} exact /> */}
                            <Route path={`${constants.baseLocale}/`} component={Content} exact />
                            {/* <Route path="/tutors?cities=:cities&subjects=:subjects" component={Tutors} /> */}
                            <Route path={`${constants.baseLocale}/tutors`} component={Tutors} />
                            <Route path={`${constants.baseLocale}/profile`} component={Profile} />
                            <Route path={`${constants.baseLocale}/register`} component={Signup} />
                            <Route path={`${constants.baseLocale}/register_tutor`} component={Signup} />
                            <Route path={`${constants.baseLocale}/data_tutor`} component={TutorData} />
                            <Route path={`${constants.baseLocale}/edit_profile`} component={UserData} />
                            <Route path={`${constants.baseLocale}/login`} component={Login} />
                            <Route path={`${constants.baseLocale}/forgot`} component={Forgot} />
                            <Route path={`${constants.baseLocale}/recovery`} component={Recovery} />
                            <Route path={`${constants.baseLocale}/how_it_works`} component={HowItWorks} />
                            <Route path={`${constants.baseLocale}/public_offer`} component={PublicOffer} />
                            <Route path={`${constants.baseLocale}/privacy`} component={PrivacyPolicy} />
                            <Route path={`${constants.baseLocale}/dashboard`} component={Dashboard} />
                            <Route path={`${constants.baseLocale}/admin`} component={Admin} />
                            <Route path={`${constants.baseLocale}/sections`} component={Sections} />
                            <Route path={`${constants.baseLocale}/blogs`} component={Blogs} />
                            <Route path={`${constants.baseLocale}/faq`} component={FAQ} />
                            <Route path={`${constants.baseLocale}/d`} component={UserDataDiplomas} />
                            <Route path={`${constants.baseLocale}/404`} component={PageNotFound} />
                            <Route path={`${constants.baseLocale}/*`} component={PageNotFoundRedirect} />
                        </Switch>
                        <Footer />
                        {/* <Content /> */}
                    </BrowserRouter>
                    : "load"}
            </IntlProvider>
        </Provider>
    )

}

ReactDOM.render(
    <SelectLocale />
    , document.getElementById('root')
);