import React, { Component } from 'react';
import constants from '../Constants';
import Canonical from '../Canonical';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import SelectSearch from '../SelectSearch';
import { userSelectSearch, getSelectTutors } from '../redux/actions';
function declOfNum(number, words) {
    if(typeof number!=='number')number=0;
    if(number<0)number=number*-1;
    return words[(number % 100 > 4 && number % 100 < 20) ? 2 : [2, 0, 1, 1, 1, 2][(number % 10 < 5) ? Math.abs(number) % 10 : 5]];
}
function time(unixTimeStamp) {
    let date = new Date(+unixTimeStamp * 1000);
    return ('0' + date.getDate()).slice(-2) + '.' + ('0' + (date.getMonth() + 1)).slice(-2) + '.' + date.getFullYear() + ' ' + ('0' + date.getHours()).slice(-2) + ':' + ('0' + date.getMinutes()).slice(-2);
}
class Orders extends Component {
    constructor(props) {
        super(props);
    }
    state = {
        unixTime: (+Date.now() / 1000)
    }

    getIntl = (id) => {
        const { intl } = this.props;
        return intl.formatMessage({ id: id })
    }

    render() {
        let role = this.props.currentUser.role;
        console.log("role=" + role)
        console.log(this.props.orders)
        if (this.props.orders?.length > 0) {
            return (<>
                {this.props.orders?.map((o, a) => {
                    console.log("this.props.status=" + this.props.status)
                    if (this.props.status && this.props.status != o?.status) { return false }
                    let start = Math.trunc((+this.state.unixTime - +o?.start) / (1 * 24 * 60 * 60));
                    let taken = (o?.status > 0) ? Math.trunc((+this.state.unixTime - +o?.taken) / (1 * 24 * 60 * 60)) : '';
                    let end = (o?.status > 1) ? Math.trunc((+this.state.unixTime - +o?.end) / (1 * 24 * 60 * 60)) : '';
                    let color = (+o.status == 0) ? ((+start > 3) ? ((+start > 7) ? "red" : "yellow") : "green") : ((+o.status == 1) ? ((+taken > 3) ? ((+taken > 7) ? "red" : "yellow") : "green") : "green");

        let first_lesson = (o?.first_lesson > 1)?Math.trunc((+this.state.unixTime - +o.first_lesson) / (1 * 24 * 60 * 60)):0;
        let first_color = (+first_lesson > 3) ? ((+first_lesson > 7) ? "red" : "yelow") : "green";


                    return <div className="order_card">
                        <strong>
                            <FormattedMessage id="order" /> №<Link to={"/order?id=" + o?.id}>{o?.id}</Link> <FormattedMessage id="from" /> {time(o?.start)} - ({start} <FormattedMessage id={declOfNum(start, ['day', 'of_the_day', 'days'])} />)
                            {(o.status == 2 && o.rate > 0) ? <span><i className="star-icon"></i>{o.rate}</span> : ''}
                            <span className={"new_link_users_attr bgcolor_" + color}><FormattedMessage id={"order_status." + o?.status} /></span>
                        </strong>
                        {(o?.tutor>0)?<div className="row_wrap"><p><FormattedMessage id="tutor" />:</p><p><Link to={`/${constants.locale}/profile/id${o?.tutor}`} >{o?.tutor_name} </Link>
                            {(+role != 1) ? <Link to={"./dashboard?tab=chat&id=" + o?.tutor} className="link_users_chat" title={this.getIntl("to_write")} onClick={this.props.clickTab?.bind(this, 'chat', o?.tutor)} ></Link> : ''}
                        </p></div>:''}
                        {(o?.status > 0) ? (<div className="row_wrap"><p><FormattedMessage id={"order_status.1"} />:</p><p className={"color_ " + ((+taken > 3) ? ((+taken > 7) ? "red" : "yelow") : "green")}>{time(o?.taken)} - ({taken} <FormattedMessage id={declOfNum(taken, ['day', 'of_the_day', 'days'])} />)</p></div>) : ''}


                {(o?.status > 0 && o?.first_lesson > 1) ? (<div className="row_wrap"><p><FormattedMessage id={"first_lesson"} />:</p><p className={"color_ " + first_color}>{time(o.first_lesson)} - ({first_lesson} <FormattedMessage id={declOfNum(first_lesson, ['day', 'of_the_day', 'days'])} />)</p></div>) : ''}

                        {(o?.status > 1) ? (<div className="row_wrap"><p><FormattedMessage id={"order_status.2"} />:</p><p>{time(o?.end)} - ({end} <FormattedMessage id={declOfNum(end, ['day', 'of_the_day', 'days'])} />)</p></div>) : ''}
                        {/* {(o?.taken_tutor) ?
                            <div className="row_wrap"><p><FormattedMessage id="tutor" /> 2:</p><p><Link to={`/${constants.locale}/profile/id${o?.taken_tutor}`} >{o?.taken_tutor}</Link></p></div> : ''
                        } */}
                        {/* at_the_student: "0"
    at_the_tutor: "0"
    cities: "4"
    districts: "91"
    email: "email@gmail.com"
    end: "0"
    help_text: ""
    id: "7"
    lesson_duration: "0"
    levels: "29"
    name: "Ian"
    online: "1"
    orders_street: ""
    periodicity: "1"
    phone: "0989802631"
    start: "1636197124419"
    status: "0"
    subjects: "0"
    times_text: "kjhg"
    tutor: "54"
    wishes_text: ""
    taken 
    price
    */}
                        <div className="row_wrap"><p><FormattedMessage id="customer" />:</p><p>
                            <Link to={`/${constants.locale}/profile/id${o?.student}`} >  {o?.name} </Link>
                            {(+role != 0) ? <Link to={"./dashboard?tab=chat&id=" + o?.student} className="link_users_chat" title={this.getIntl("to_write")} onClick={this.props.clickTab?.bind(this, 'chat', o?.student)}></Link> : ''}
                        </p></div>
                        <div className="row_wrap"><p><FormattedMessage id="price_placeholder" />:</p><p><FormattedMessage id={"price." + o?.price} /></p></div>
                        <div className="row_wrap"><p><FormattedMessage id="item" />:</p><p><FormattedMessage id={"subjects." + o?.subjects} /></p></div>
                        <div className="row_wrap"><p><FormattedMessage id="level_of_preparation" />:</p><p><FormattedMessage id={"levels." + o?.levels} /></p></div>
                        <div className="row_wrap"><p><FormattedMessage id="location_of_classes" />:</p><p>
                            {(+o?.at_the_tutor == 1) ? <><FormattedMessage id="at_the_tutor" /><br /></> : ''}
                            {(+o?.online == 1) ? <><FormattedMessage id="online" /><br /></> : ''}
                            {(+o?.at_the_student == 1) ? <><FormattedMessage id="at_the_student" /><br /></> : ''}
                        </p></div>
                        {(o?.wishes_text) ? <div className="row_wrap"><p><FormattedMessage id="other_wishes" />:</p><p>{o?.wishes_text}</p></div> : ''}
                        {(o?.times_text) ? <div className="row_wrap"><p><FormattedMessage id="class_time" />:</p><p>{o?.times_text}</p></div> : ''}
                        {(o?.help_text) ? <div className="row_wrap"><p><FormattedMessage id="need_help_with" />:</p><p>{o?.help_text}</p></div> : ''}
                        <div className="row_wrap_end">
                            <Link className="b_btn btn-green" to={"./dashboard?tab=order&id=" + o?.id} onClick={this.props.clickTab?.bind(this, 'order', o?.id)}>
                                <FormattedMessage id="more_details" />
                            </Link>
                        </div>
                    </div>
                })}
            </>)
        } else {
            return <div className="order_card"><FormattedMessage id="data_not_found" /></div>
        }
    }
}
const mapStateToProps = state => ({
    currentUser: state.currentUser
})

const mapDispatchToProps = dispatch => ({
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Orders));