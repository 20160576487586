import React, { Component } from 'react';
import constants from '../Constants';
import Canonical from '../Canonical';
import { injectIntl, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import UserRedirect from './UserRedirect';
import { Redirect } from 'react-router-dom';

function findGetParameter(parameterName) {
  var result = "",
      tmp = [];
  window.location.search
      .substr(1)
      .split("&")
      .forEach(function (item) {
          tmp = item.split("=");
          if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
      });
  return result;
}

class Recovery extends Component {
  state = {
    submitted: false,
    submitErrors: "",

    email:"",
    formErrors: { password: '', password_confirmation: '' },
    passwordValid: false,
    password_confirmationValid: false,
    formValid: false,
    formOk: false,
  }

  validateField(fieldName, value) {
    const intl = this.props.intl
    var fieldValidationErrors = this.state.formErrors;
    var passwordValid = this.state.passwordValid;
    var password_confirmationValid = this.state.password_confirmationValid;
    switch (fieldName) {
      case 'password':
        passwordValid = value.length >= 6;
        fieldValidationErrors.password = passwordValid ? '' : intl.formatMessage({ id: "error.is_too_short" });
        password_confirmationValid = value === this.state.password_confirmation;
        fieldValidationErrors.password_confirmation = password_confirmationValid ? '' : intl.formatMessage({ id: "error.not_equal_to_password" });
        break;
      case 'password_confirmation':
        password_confirmationValid = value === this.state.password;
        fieldValidationErrors.password_confirmation = password_confirmationValid ? '' : intl.formatMessage({ id: "error.not_equal_to_password" });
        break;
      default:
        break;
    }
    this.setState({
      passwordValid: passwordValid,
      password_confirmationValid: password_confirmationValid,
      formValid: passwordValid && password_confirmationValid
    });
  }

  handleChange = event => {
    this.validateField(event.target.name, event.target.value);
    this.setState({
      [event.target.name]: event.target.value,
      submitErrors: ""
    });
  }

  setRecovery = async () => {
    try {
      const res = await fetch(constants.location + "/handler/forgot.php?code="+findGetParameter("code"), {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify({user: { email: this.state.email, password: this.state.password }})
      })
      const data = await res.json();
      return data.message==="ok";
    } catch (err) {
      console.log("error: ", err);
      return false;
    }
  }

  getRecoveryEmail = async () => {
    try {
      const res = await fetch(constants.location + "/handler/forgot.php?code="+findGetParameter("code"), {
        method: "GET",
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        }
      })
      const data = await res.json();
      if(data.message==="ok")this.setState({
        email: data.email
      });
    } catch (err) {
      console.log("error: ", err);
    }
  }

  handleSubmit = async (event) => {
    event.preventDefault()
    this.setState({ submitted: true })
    let mess = await this.setRecovery();
    console.log("mess: ",mess)
    this.setState({
      submitErrors: mess ? "" : this.props.intl.formatMessage({ id: "error.login_is_not_registered" }),
      formOk: mess,
      formValid: false,
    })
  }

  componentDidMount() {
            this.getRecoveryEmail()
        }

  render() {
    return (
      <div>
        {+this.props?.currentUser?.role === 3 ? '' : <UserRedirect />}
        <div id="register" className="table_header">
          {this.state.formOk?<Redirect to = {`/${constants.locale}/login`} />:''}
          {!this.state.email ?
            <form>
              <p><FormattedMessage id="app.recovery_error"/></p>
              <p> 
                <Link to={`/${constants.locale}/login`}><FormattedMessage id="app.login" />
                </Link>  <FormattedMessage id="or" /> <Link to={`/${constants.locale}/register`}><FormattedMessage id="app.sign_up" />
                </Link>  <FormattedMessage id="or" /> <Link to={`/${constants.locale}/forgot`}><FormattedMessage id="app.forgot_your_password_send" /></Link>
              </p>
            </form>
            :
            <form onSubmit={this.handleSubmit}>
              <Canonical title={this.props.intl.formatMessage({ id: 'app.recovery_text' })} />

              <div className="input-wrap">
              <input className="input"
                id="signup-email"
                type='email'
                name='email'
                placeholder='Email'
                value={this.state.email}
                readOnly={true}
              />
              <label className="placeholder" htmlFor="signup-email"><FormattedMessage id="app.email" /></label>
              <div className="input_error">{this.state.formErrors.email}</div>
            </div>

            <div className="input-wrap">
              <input className="input"
                id="signup-password"
                type='password'
                name='password'
                placeholder='Password'
                value={this.state.password}
                onChange={this.handleChange}
                required
              />
              <label className="placeholder" htmlFor="signup-password"><FormattedMessage id="app.password" /></label>
              <div className="input_error">{this.state.formErrors.password}</div>
            </div>

            <div className="input-wrap">
              <input className="input"
                id="signup-password_confirmation"
                type='password'
                name='password_confirmation'
                placeholder='Password again'
                value={this.state.password_confirmation}
                onChange={this.handleChange}
                required
              />
              <label className="placeholder" htmlFor="signup-password_confirmation"><FormattedMessage id="app.password_again" /></label>
              <div className="input_error">{this.state.formErrors.password_confirmation}</div>
            </div>

              {this.state.submitErrors}
              <div className="btn-wrap">
                <button type='submit' className={`b_btn btn-green${this.state.submitted ? " submitted" : ""}`} disabled={!this.state.formValid}>
                  <FormattedMessage id="save" />
                </button>
              </div>

            </form>
          }
        </div>
        <Canonical seo={true} />
      </div>
    )
  }
}
export default injectIntl(connect(null, null)(Recovery));