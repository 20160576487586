import React, { Component } from 'react';
import constants from './Constants';
import Canonical from './Canonical';
import {IntlProvider, FormattedMessage, useIntl, injectIntl, intlShape, defineMessages } from 'react-intl';
import { BrowserRouter as Router, Switch, NavLink, Link, Route, useRouteMatch } from 'react-router-dom';
import { connect } from 'react-redux';
import SearchTutorForm from './SearchTutorForm';

class Header extends Component {
  componentDidMount() {
  constants.setHeader();
  }
  render() {
    document.title = this.props.intl.formatMessage({ id: 'document.title.index' });
    return (
      <header className="header_content">
        <div className="header header-home">
        <Canonical title={this.props.intl.formatMessage({ id: 'app.find_an_experienced_tutor' })} />
          {/* <h1 className="main-page__heading"><span><FormattedMessage id="app.find_an_experienced_tutor" /></span></h1> */}
          <div className="main-page__form users_head">
            <SearchTutorForm />
          </div>
        </div>
      </header>
    );
  }
}

const mapStateToProps = state => ({
  currentUser: state.currentUser
  //currentUser: state.reducer.currentUser
})

const mapDispatchToProps = dispatch => ({
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Header));

