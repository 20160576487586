import React, { Component } from 'react';
import constants from '../Constants';
import Canonical from '../Canonical';
import { FormattedMessage, injectIntl, IntlProvider } from 'react-intl';
import { connect } from 'react-redux';
import DocumentMeta from 'react-document-meta';


class PublicOffer extends Component {
        constructor(props) {
                super(props);
        }
        componentDidMount() {
                // constants.setHeader()
        }
        render() {
                const meta = {
                        title: this.props.intl.formatMessage({ id: 'document.title.public_offer' }),
                        canonical: constants.location + window.location.pathname,
                        meta: {
                                charset: 'utf-8',
                                name: {
                                        robots: "noindex",
                                        googlebot: "noindex",
                                        keywords: '',
                                }
                        }
                };
                return (
                        <div className="main_wrapper">
                                <DocumentMeta {...meta} />
                                <div className="main_wrapper_block">
                                        <div className="main_wrapper_block_content_stretch public_text">
                                                <h1><FormattedMessage id="public_offer" /></h1>
                                                <h1><FormattedMessage id="offer.c.1" /></h1>
                                                <p><FormattedMessage id="offer.c.2" /></p>
                                                <p><FormattedMessage id="offer.c.3" /></p>
                                                <h2><FormattedMessage id="offer.c.4" /></h2>
                                                <p><FormattedMessage id="offer.c.5" /></p>
                                                <p><FormattedMessage id="offer.c.6" /></p>
                                                <p><FormattedMessage id="offer.c.7" /></p>
                                                <p><FormattedMessage id="offer.c.8" /></p>
                                                <h2><FormattedMessage id="offer.c.9" /></h2>
                                                <p><FormattedMessage id="offer.c.10" /></p>
                                                <p><FormattedMessage id="offer.c.11" /></p>
                                                <p><FormattedMessage id="offer.c.12" /></p>
                                                <p><FormattedMessage id="offer.c.13" /></p>
                                                <p><FormattedMessage id="offer.c.14" /></p>
                                                <p><FormattedMessage id="offer.c.15" /></p>
                                                <p><FormattedMessage id="offer.c.16" /></p>
                                                <h2><FormattedMessage id="offer.c.17" /></h2>
                                                <p><FormattedMessage id="offer.c.18" /></p>
                                                <h2><FormattedMessage id="offer.o" /></h2>
                                                <p><FormattedMessage id="offer.o.1" /></p>
                                                <p><FormattedMessage id="offer.o.2" /></p>
                                                <p><FormattedMessage id="offer.o.3" /></p>
                                                <p><FormattedMessage id="offer.o.4" /></p>
                                                <p><FormattedMessage id="offer.o.5" /></p>
                                                <p><FormattedMessage id="offer.o.6" /></p>
                                                <p><FormattedMessage id="offer.o.7" /></p>
                                                <h1><FormattedMessage id="offer.u.1" /></h1>
                                                <p><FormattedMessage id="offer.u.1.1" /></p>
                                                <p><FormattedMessage id="offer.u.1.2" /></p>
                                                <p><FormattedMessage id="offer.u.1.3" /></p>
                                                <p><FormattedMessage id="offer.u.1.4" /></p>
                                                <p><FormattedMessage id="offer.u.1.5" /></p>
                                                <h2><FormattedMessage id="offer.u.2" /></h2>
                                                <p><FormattedMessage id="offer.u.2.1" /></p>
                                                <p><FormattedMessage id="offer.u.2.2" /></p>
                                                <p><FormattedMessage id="offer.u.2.3" /></p>
                                                <h2><FormattedMessage id="offer.u.3" /></h2>
                                                <p><FormattedMessage id="offer.u.3.1" /></p>
                                                <p><FormattedMessage id="offer.u.3.2" /></p>
                                                <p><FormattedMessage id="offer.u.3.3" /></p>
                                                <p><FormattedMessage id="offer.u.3.4" /></p>
                                                <p><FormattedMessage id="offer.u.3.5" /></p>
                                                <h2><FormattedMessage id="offer.u.4" /></h2>
                                                <p><FormattedMessage id="offer.u.4.1" /></p>
                                                <p><FormattedMessage id="offer.u.4.2" /></p>
                                                <p><FormattedMessage id="offer.u.4.3" /></p>
                                                <p><FormattedMessage id="offer.u.4.5" /></p>
                                                <p><FormattedMessage id="offer.u.4.6" /></p>
                                                <p><FormattedMessage id="offer.u.4.7" /></p>
                                                <h2><FormattedMessage id="offer.u.5" /></h2>
                                                <p><FormattedMessage id="offer.u.5.1" /></p>
                                                <h2><FormattedMessage id="offer.u.6" /></h2>
                                                <p><FormattedMessage id="offer.u.6.1" /></p>
                                                <p><FormattedMessage id="offer.u.6.2" /></p>
                                                <p><FormattedMessage id="offer.u.6.3" /></p>
                                                <p><FormattedMessage id="offer.u.6.4" /></p>
                                                <p><FormattedMessage id="offer.u.6.5" /></p>
                                                <p><FormattedMessage id="offer.u.6.6" /></p>
                                                <p><FormattedMessage id="offer.u.6.7" /></p>
                                                <h2><FormattedMessage id="offer.u.7" /></h2>
                                                <h3><FormattedMessage id="offer.t.1" /></h3>
                                                <p><FormattedMessage id="offer.t.2" /></p>
                                                <p><FormattedMessage id="offer.t.3" /></p>
                                                <p><FormattedMessage id="offer.t.4" /></p>
                                                <p><FormattedMessage id="offer.t.5" /></p>
                                                <p><FormattedMessage id="offer.t.6" /></p>
                                                <p><FormattedMessage id="offer.t.7" /></p>
                                                <p><FormattedMessage id="offer.t.8" /></p>
                                                <p><FormattedMessage id="offer.t.9" /></p>
                                                <p><FormattedMessage id="offer.t.10" /></p>
                                                <p><FormattedMessage id="offer.t.11" /></p>
                                                <h3><FormattedMessage id="offer.t.12" /></h3>
                                                <p><FormattedMessage id="offer.t.13" /></p>
                                                <p><FormattedMessage id="offer.t.14" /></p>
                                                <p><FormattedMessage id="offer.t.15" /></p>
                                                <h3><FormattedMessage id="offer.t.16" /></h3>
                                                <p><FormattedMessage id="offer.t.17" /></p>
                                                <p><FormattedMessage id="offer.t.18" /></p>
                                                <p><FormattedMessage id="offer.t.19" /></p>
                                                <h3><FormattedMessage id="offer.t.20" /></h3>
                                                <p><FormattedMessage id="offer.t.21" /></p>
                                                <p><FormattedMessage id="offer.t.22" /></p>
                                                <p><FormattedMessage id="offer.t.23" /></p>
                                                <p><FormattedMessage id="offer.t.24" /></p>
                                                <p><FormattedMessage id="offer.t.25" /></p>
                                                <h2><FormattedMessage id="offer.t.26" /></h2>
                                                <p><FormattedMessage id="offer.t.27" /></p>
                                                <p><FormattedMessage id="offer.t.28" /></p>
                                                <h2><FormattedMessage id="offer.s.0" /></h2>
                                                <p><FormattedMessage id="offer.s.0.1" /></p>
                                                <p><FormattedMessage id="offer.s.0.2" /></p>
                                                <h2><FormattedMessage id="offer.s.1" /></h2>
                                                <p><FormattedMessage id="offer.s.1.1" /></p>
                                                <p><FormattedMessage id="offer.s.1.2" /></p>
                                                <p><FormattedMessage id="offer.s.1.3" /></p>
                                                <p><FormattedMessage id="offer.s.1.4" /></p>
                                                <p><FormattedMessage id="offer.s.1.5" /></p>
                                                <h2><FormattedMessage id="offer.s.2" /></h2>
                                                <p><FormattedMessage id="offer.s.2.1" /></p>
                                                <p><FormattedMessage id="offer.s.2.2" /></p>
                                                <h2><FormattedMessage id="offer.s.3" /></h2>
                                                <p><FormattedMessage id="offer.s.3.1" /></p>
                                                <p><FormattedMessage id="offer.s.3.2" /></p>
                                                <p><FormattedMessage id="offer.s.3.3" /></p>
                                                <p><FormattedMessage id="offer.s.3.4" /></p>
                                                <p><FormattedMessage id="offer.s.3.5" /></p>
                                                <h2><FormattedMessage id="offer.s.4" /></h2>
                                                <p><FormattedMessage id="offer.s.4.1" /></p>
                                                <p><FormattedMessage id="offer.s.4.2" /></p>
                                                <p><FormattedMessage id="offer.s.4.3" /></p>
                                                <h2><FormattedMessage id="offer.s.5" /></h2>
                                                <h3><FormattedMessage id="offer.s.5.1" /></h3>
                                                <p><FormattedMessage id="offer.s.5.1.1" /></p>
                                                <p><FormattedMessage id="offer.s.5.1.2" /></p>
                                                <p><FormattedMessage id="offer.s.5.1.3" /></p>
                                                <p><FormattedMessage id="offer.s.5.1.4" /></p>
                                                <p><FormattedMessage id="offer.s.5.1.5" /></p>
                                                <p><FormattedMessage id="offer.s.5.1.6" /></p>
                                                <p><FormattedMessage id="offer.s.5.1.7" /></p>
                                                <h3><FormattedMessage id="offer.s.5.2" /></h3>
                                                <p><FormattedMessage id="offer.s.5.2.1" /></p>
                                                <p><FormattedMessage id="offer.s.5.2.2" /></p>
                                                <p><FormattedMessage id="offer.s.5.2.3" /></p>
                                                <h3><FormattedMessage id="offer.s.5.3" /></h3>
                                                <p><FormattedMessage id="offer.s.5.3.1" /></p>
                                                <p><FormattedMessage id="offer.s.5.3.2" /></p>
                                                <p><FormattedMessage id="offer.s.5.3.3" /></p>
                                                <h3><FormattedMessage id="offer.s.5.4" /></h3>
                                                <p><FormattedMessage id="offer.s.5.4.1" /></p>
                                                <p><FormattedMessage id="offer.s.5.4.2" /></p>
                                                <p><FormattedMessage id="offer.s.5.4.3" /></p>
                                                <p><FormattedMessage id="offer.s.5.4.5" /></p>
                                                <p><FormattedMessage id="offer.s.5.4.6" /></p>
                                                <p><FormattedMessage id="offer.s.5.4.8" /></p>
                                                <p><FormattedMessage id="offer.s.5.4.9" /></p>
                                                <h2><FormattedMessage id="offer.s.6" /></h2>
                                                <p><FormattedMessage id="offer.s.6.1" /></p>
                                                <p><FormattedMessage id="offer.s.6.2" /></p>
                                                <p><FormattedMessage id="offer.s.6.3" /></p>
                                                <p><FormattedMessage id="offer.s.6.4" /></p>
                                                <h2><FormattedMessage id="offer.s.7" /></h2>
                                                <p><FormattedMessage id="offer.s.7.1" /></p>
                                                <p><FormattedMessage id="offer.s.7.2" /></p>
                                        </div>
                                </div>
                        </div>
                );
        }
}

const mapStateToProps = state => ({
        currentUser: state.currentUser
        //currentUser: state.reducer.currentUser
})

const mapDispatchToProps = dispatch => ({
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(PublicOffer));



