import React, { Component, useState, useEffect } from 'react';
import constants from '../Constants';
import Canonical from '../Canonical';
import { IntlProvider, FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { userSignupFetch, clearMessage } from '../redux/actions';
import { Link } from 'react-router-dom';
import UserRedirect from './UserRedirect';

class Signup extends Component {

  constructor(props) {
    super(props);
  }

  state = {
    name: "",
    lastname: "",
    phone: '',
    email: "",
    password: "",
    password_confirmation: "",
    role: "1",
    submitted: false,
    formErrors: { name: '', lastname: '', phone: '', email: '', password: '', password_confirmation: '' },
    nameValid: false,
    lastnameValid: false,
    phoneValid: false,
    emailValid: false,
    passwordValid: false,
    password_confirmationValid: false,
    formValid: false,
    submitErrors: this.props.error ? this.props.error : ""
  }

  validateField(fieldName, value) {
    const intl = this.props.intl
    var fieldValidationErrors = this.state.formErrors;
    var nameValid = this.state.nameValid;
    var lastnameValid = this.state.lastnameValid;
    var phoneValid = this.state.phoneValid;
    var emailValid = this.state.emailValid;
    var passwordValid = this.state.passwordValid;
    var password_confirmationValid = this.state.password_confirmationValid;
    switch (fieldName) {
      case 'name':
        nameValid = value.length >= 2;
        fieldValidationErrors.name = nameValid ? '' : intl.formatMessage({ id: "error.is_too_short" });
        break;
      case 'lastname':
        lastnameValid = value.length >= 2;
        fieldValidationErrors.lastname = lastnameValid ? '' : intl.formatMessage({ id: "error.is_too_short" });
        break;
      case 'phone':
        phoneValid = (value != null && value.length >= 10);
        fieldValidationErrors.phone = phoneValid ? '' : intl.formatMessage({ id: "error.is_too_short" });
        break;
      case 'email':
        emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) ? true : false;
        fieldValidationErrors.email = emailValid ? '' : intl.formatMessage({ id: "error.is_invalid" });
        break;
      case 'password':
        passwordValid = value.length >= 6;
        fieldValidationErrors.password = passwordValid ? '' : intl.formatMessage({ id: "error.is_too_short" });
        password_confirmationValid = value === this.state.password_confirmation;
        fieldValidationErrors.password_confirmation = password_confirmationValid ? '' : intl.formatMessage({ id: "error.not_equal_to_password" });
        break;
      case 'password_confirmation':
        password_confirmationValid = value === this.state.password;
        fieldValidationErrors.password_confirmation = password_confirmationValid ? '' : intl.formatMessage({ id: "error.not_equal_to_password" });
        break;
      default:
        break;
    }
    this.setState({
      formErrors: fieldValidationErrors,
      nameValid: nameValid,
      lastnameValid: lastnameValid,
      phoneValid: phoneValid,
      emailValid: emailValid,
      passwordValid: passwordValid,
      password_confirmationValid: password_confirmationValid,
      formValid: nameValid && lastnameValid && phoneValid && emailValid && passwordValid && password_confirmationValid
    });
  }

  handleChange = event => {
    var value = event.target.value.trim()
    this.validateField(event.target.name, value);
    this.props.clearMessage()
    this.setState({
      [event.target.name]: value,
      submitErrors: ""
    });
    console.log(value);
  }

  handleSubmit = event => {
    event.preventDefault()
    this.setState({ submitted: true })
    if (!this.state.formValid) this.setState({ submitted: false });
    if (this.state.formValid) this.props.userSignupFetch(this.state).then(items => {
      this.formError(items);
    });
  }
  formError = event => {
    if (this.props.error) {
      this.setState({ submitted: false, submitErrors: this.props.error })
    };
  }
  componentDidMount() {
    constants.setHeader()
  }

  render() {
    // document.title = this.props.intl.formatMessage({ id: 'document.title.signup' });
    return (
      <div>
        {+this.props?.currentUser?.role === 3 ? '' : <UserRedirect />}
        <div id="register" className="table_header">
          <form onSubmit={this.handleSubmit}>
            {/* <h1><strong> <FormattedMessage id="app.tutors.register.title" /></strong></h1> */}
            <Canonical title={this.props.intl.formatMessage({ id: 'app.tutors.register.title' })}/>
            {/* <div className="input-wrap" style={{display: 'none'}}>
        <input
          id="signup-name"
          name='name'
          placeholder='name'
          value={this.state.name}
          onChange={this.handleChange}
          required
          />
          <label className="placeholder" htmlFor="signup-name">name</label>
        </div> */}

            <div className="input-wrap">
              <div id="wrap_radio" >
                <label>
                  <input name="role" type="radio" value="0" checked={this.state.role === "0"} onChange={this.handleChange} />
                  <div className="wrap_radio__bg"></div>
                  <span className="wrap_radio__label"><FormattedMessage id="app.apprentice" /></span>
                </label>
                <label>
                  <input name="role" type="radio" value="1" checked={this.state.role === "1"} onChange={this.handleChange} />
                  <div className="wrap_radio__bg"></div>
                  <span className="wrap_radio__label"><FormattedMessage id="app.tutor" /></span>
                </label>
              </div>
            </div>

            <div className="input-wrap">
              <input className="input"
                id="signup-name"
                type='text'
                name='name'
                placeholder='name'
                value={this.state.name}
                onChange={this.handleChange}
                required
              />
              <label className="placeholder" htmlFor="signup-name"><FormattedMessage id="app.name" /></label>
              <div className="input_error">{this.state.formErrors.name}</div>
            </div>

            <div className="input-wrap">
              <input className="input"
                id="signup-lastname"
                type='text'
                name='lastname'
                placeholder='lastname'
                value={this.state.lastname}
                onChange={this.handleChange}
                required
              />
              <label className="placeholder" htmlFor="signup-lastname"><FormattedMessage id="app.lastname" /></label>
              <div className="input_error">{this.state.formErrors.lastname}</div>
            </div>

            <div className="input-wrap">
              <input className="input"
                id="signup-phone"
                type='phone'
                name='phone'
                placeholder='phone'
                value={this.state.phone}
                onChange={this.handleChange}
                required
              />
              <label className="placeholder" htmlFor="signup-phone"><FormattedMessage id="app.phone" /></label>
              <div className="input_error">{this.state.formErrors.phone}</div>
            </div>

            <div className="input-wrap">
              <input className="input"
                id="signup-email"
                type='email'
                name='email'
                placeholder='Email'
                value={this.state.email}
                onChange={this.handleChange}
                required
              />
              <label className="placeholder" htmlFor="signup-email"><FormattedMessage id="app.email" /></label>
              <div className="input_error">{this.state.formErrors.email}</div>
            </div>

            <div className="input-wrap">
              <input className="input"
                id="signup-password"
                type='password'
                name='password'
                placeholder='Password'
                value={this.state.password}
                onChange={this.handleChange}
                required
              />
              <label className="placeholder" htmlFor="signup-password"><FormattedMessage id="app.password" /></label>
              <div className="input_error">{this.state.formErrors.password}</div>
            </div>

            <div className="input-wrap">
              <input className="input"
                id="signup-password_confirmation"
                type='password'
                name='password_confirmation'
                placeholder='Password again'
                value={this.state.password_confirmation}
                onChange={this.handleChange}
                required
              />
              <label className="placeholder" htmlFor="signup-password_confirmation"><FormattedMessage id="app.password_again" /></label>
              <div className="input_error">{this.state.formErrors.password_confirmation}</div>
            </div>

            {(this.props.error) ? <FormattedMessage id={this.props.error} /> : ''}
            <div className="btn-wrap">
              <button type='submit' className={`b_btn btn-green ${this.state.submitted ? "submitted" : ""}`} disabled={!this.state.formValid}>
                <FormattedMessage id="app.tutors.register.button" />
              </button>
            </div>
            <p><FormattedMessage id="upon_registration_you_agree" /> <a target="_blank" href={`/${constants.locale}/public_offer`}><FormattedMessage id="you_agree_to_public_offer" /></a></p>
            <p><FormattedMessage id="app.tutors.register.comment" /></p>
            <div className="input_error_popup">{this.state.submitErrors}</div>
            
            <p><FormattedMessage id="or" /> <Link to={`/${constants.locale}/login`}><FormattedMessage id="app.login" /></Link></p>
          </form>
          <div className="button-wrapper"><span id="go_to_advantages" className="button-down-white"></span></div>
        </div>
        <div className="table-content">
          <h2><FormattedMessage id="app.tutors.register.text1" /></h2>
          <p><FormattedMessage id="app.tutors.register.text2" /></p>
          <p><FormattedMessage id="app.tutors.register.text3" /></p>
          <p><FormattedMessage id="app.tutors.register.text4" /></p>
          <p><FormattedMessage id="app.tutors.register.text5" /></p>
          <p><FormattedMessage id="app.tutors.register.text6" /></p>
          <p><FormattedMessage id="app.tutors.register.text7" /></p>
          <Canonical seo={true}/>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  currentUser: state.currentUser,
  error: state.error
})

const mapDispatchToProps = dispatch => ({
  userSignupFetch: userInfo => dispatch(userSignupFetch(userInfo)),
  clearMessage: () => dispatch(clearMessage())
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Signup));