import React, { Component, useState, useEffect } from 'react';
import constants from '../Constants';
import Canonical from '../Canonical';
import { IntlProvider, FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { userPostTutor, userPostAvatar } from '../redux/actions';
import { Link } from 'react-router-dom';
import SelectSearch from '../SelectSearch';
import InputCheck from './InputCheck'
import { userSelectSearch } from '../redux/actions';
import { Redirect } from 'react-router-dom';
import EditPass from './EditPass';

function isNumber(n) { return !isNaN(parseFloat(n)) && !isNaN(n - 0) }

class UserData extends Component {

  constructor(props) {
    super(props);
  }

  state = {
    avatar: {
      src: "../images/avatar_area_box.png",
      upload: null,
      name: "user image"
    },
    jwt: this.props.currentUser.jwt,
    files: [],
    filesname: [],
    submitted: true

  }
  readImage(fileToLoad) {
    var fileReader = new FileReader();
    fileReader.onload = (fileLoadedEvent) => {
      var data = {}
      data = { jwt: this.props.currentUser.jwt, name: fileToLoad.name, imgBase64: fileLoadedEvent.target.result }
      const fetch_url = constants.location
      fetch(fetch_url + "/handler/diplomas.php", {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify({ data })
      })
        .then(resp => resp.json())
        .then(data => {
          if (data.error) {
            console.log(data)
          } else {
            console.log(data)
            //url" => $url, "name
            if (data.name) {
              let file = [{ name: data.name, url: data.url}];
              var files = [...this.state.files, ...file];
              this.setState({ files: files })
            }
          }
        }).catch(err => {
          console.log(err)
        })
    };
    fileReader.readAsDataURL(fileToLoad);
  }
  handleSubmitImage = (e) => {
    if (e.name) {
      // src = URL.createObjectURL(e)
      // upload = this.readImage(e)
    } else { return false }
  }
  fileSelectedHandler = (e) => {
    var old_ = this.state.files
    if (old_?.length >= 5) return false;
    var new_ = e.target.files;
    // console.log(new_)
    var old_l = old_?.length
    for (let i = 0; i < (5 - old_l); i++) {
      // console.log("new_[" + i + "]======")
      if (new_?.[i]?.name) {
        var fileReader = new FileReader();
        fileReader.onload = (fileLoadedEvent) => {
          var data = {}
          data = { jwt: this.props.currentUser.jwt, name: new_[i].name, imgBase64: fileLoadedEvent.target.result }
          const fetch_url = constants.location
          fetch(fetch_url + "/handler/diplomas.php", {
            method: "POST",
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
            },
            body: JSON.stringify({ data })
          })
            .then(resp => resp.json())
            .then(data => {
              if (data.error) {
                console.log(data)
              } else {
                console.log(data)
                //url" => $url, "name
                if (data.name) {
                  let file = [{ name: data.name, url: data.url}];
                  var files = [...this.state.files, ...file];
                  this.setState({ files: files })
                }
              }
            }).catch(err => {
              console.log(err)
            })
        };
        fileReader.readAsDataURL(new_[i]);
      } else { return false }
    }
  }
  fileDelete(i) {
    var files = this.state.files
    console.log("delete: " + i)
    var data = {}
    data = { jwt: this.props.currentUser.jwt, name: files[i].name, url: files[i].url }
    const fetch_url = constants.location
    fetch(fetch_url + "/handler/diplomas.php?delete=true", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({ data })
    })
      .then(resp => resp.json())
      .then(data => {
        if (data.error) {
          console.log(data)
        } else {
          console.log(data)
          files.splice(i, 1);
          this.setState({ files: files })
        }
      }).catch(err => {
        console.log(err)
      })
  }


  render() {
    return (
      <>
        {/* <div id="register" className="table_header"> */}

        {console.log(this.state)}
        <form onSubmit={this.handleSubmit} id="user_data">

          <div className="profile-field">

            <h3><FormattedMessage id="diplomas_and_awards" /></h3>
            <ul>
              <li><FormattedMessage id="acceptable_formats" /> .png, .jpg, .jpeg, .gif</li>
              <li><FormattedMessage id="number_of_files" /> {this.state.files.length}-5</li>
            </ul>
            {this.state.files.length ? (
              <ul>
                {this.state.files.map((e, i) => {
                  console.log(e.name); return (
                    <li key={i} className="float_horizontal">
                      <div className="image_icon"></div>
                      <p>{this.state.files[i].name}</p>
                      {/* <p>{this.state.filesname[i].name}</p> */}
                      <div className="garbage" onClick={this.fileDelete.bind(this, i++)}></div>
                    </li>)
                })}
              </ul>
            ) : ''}
            <span className="add_files">
              <input type="file" multiple onChange={this.fileSelectedHandler} accept="image/*" className="w-add_files" />
              <FormattedMessage id="add_files" />
            </span>

          </div>

          {/* <img src="" id="image" />
<input id="input" type="file" onChange={this.handleFiles} />
<canvas id="canvas" width="240" height="240" style={{border: "1px solid black"}} /> */}


          <div className="btn-wrap">
            {(this.state.validPropsState) ? '' : <i className="input_confirm"><FormattedMessage id="error.not_all_information" /></i>}
            <button type='submit' className={`b_btn btn-green ${this.state.submitted ? "submitted" : ""}`} >
              <FormattedMessage id="app.tutors.register.button" />
            </button>
          </div>

          <p><FormattedMessage id="app.tutors.register.comment" /></p>
          <div className="input_error_popup">{this.state.submitErrors}</div>
          {/* <Link to={`/login/`}><FormattedMessage id="app.login"/></Link> */}
        </form>
        <div className="button-wrapper"><span id="go_to_advantages" className="button-down-white"></span></div>
        {/* </div> */}

      </>
    )
  }
}
const mapStateToProps = state => ({
  currentUser: state.currentUser
})

const mapDispatchToProps = dispatch => ({
  userSelectSearch: object => dispatch(userSelectSearch(object)),
  userPostTutor: userInfo => dispatch(userPostTutor(userInfo)),
  userPostAvatar: userInfo => dispatch(userPostAvatar(userInfo))
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(UserData));

class DragAndDrop extends Component {
  state = {
    drag: false
  }
  dragCounter = 0
  dropRef = React.createRef()
  handleDrag = (e) => {
    e.preventDefault()
    e.stopPropagation()
  }
  handleDragIn = (e) => {
    e.preventDefault()
    e.stopPropagation()
    this.dragCounter++
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      this.setState({ drag: true })
    }
  }
  handleDragOut = (e) => {
    e.preventDefault()
    e.stopPropagation()
    this.dragCounter--
    if (this.dragCounter == 0) {
      this.setState({ drag: false })
    }
  }
  handleDrop = (e) => {
    e.preventDefault()
    e.stopPropagation()
    this.setState({ drag: false })
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      this.props.handleDrop(e.dataTransfer.files)
      e.dataTransfer.clearData()
      this.dragCounter = 0
    }
  }
  componentDidMount() {
constants.setHeader()
    let div = this.dropRef.current
    div.addEventListener('dragenter', this.handleDragIn)
    div.addEventListener('dragleave', this.handleDragOut)
    div.addEventListener('dragover', this.handleDrag)
    div.addEventListener('drop', this.handleDrop)
  }
  componentWillUnmount() {
    let div = this.dropRef.current
    div.removeEventListener('dragenter', this.handleDragIn)
    div.removeEventListener('dragleave', this.handleDragOut)
    div.removeEventListener('dragover', this.handleDrag)
    div.removeEventListener('drop', this.handleDrop)
  }
  render() {
    return (
      <div
        style={{ display: 'inline-block', position: 'relative' }}
        ref={this.dropRef}
      >
        {this.state.drag ?
          <div
            style={{
              border: 'dashed grey 4px',
              backgroundColor: 'rgba(255,255,255,.8)',
              position: 'absolute',
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              zIndex: 9999
            }}
          >
            <div
              style={{
                position: 'absolute',
                top: '50%',
                right: 0,
                left: 0,
                textAlign: 'center',
                color: 'grey',
                fontSize: 36
              }}
            >
              <div>drop here :)</div>
            </div>
          </div>
          : ''}
        {this.props.children}
      </div>
    )
  }
}
