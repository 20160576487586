import React, { Component } from 'react';
import constants from '../Constants';
import Canonical from '../Canonical';
import { FormattedMessage, injectIntl } from 'react-intl';
import { BrowserRouter as Router, Switch, NavLink, Link, Route, useRouteMatch } from 'react-router-dom';
import { connect } from 'react-redux';
import './HowItWorks.css';
import PathParameter from '../components/PathParameter';


class HowItWorks extends Component {
  state = {
    role: PathParameter.find("how_it_works") ? "for_tutors" : "for_students",
  }
  handleChange = async (event) => {
    var value = event.target.value;
    await this.setPath(value);
    this.setState({
      [event.target.name]: value
    });
  }
  setPath = (e) => {
    var newurl = constants.location + "/" + constants.locale + "/how_it_works/" + e;
    window.history.pushState({ path: newurl }, '', newurl);
  }
  componentDidMount() {
    // constants.setHeader();
    this.setPath(this.state.role)
  }
  render() {
    document.title = this.props.intl.formatMessage({ id: 'document.title.how_it_works' });
    return (
      <main className="main_wrapper">
        <div className="main_wrapper_block">
          <div className="main_wrapper_block_content">
              <Canonical 
              title={this.props.intl.formatMessage({ id: 'app.how_it_works' })}
              pathname={"/" + constants.locale + "/how_it_works/"+this.state.role} />
            {/* {(this.state.role == "for_students") ?
              <Canonical 
              title={this.props.intl.formatMessage({ id: 'app.how_it_works' })}
              pathname={"/" + constants.locale + "/how_it_works/for_students"} />:<></>
            }
            {(this.state.role == "for_tutors") ?
              <Canonical 
              title={this.props.intl.formatMessage({ id: 'app.how_it_works' })}
              pathname={"/" + constants.locale + "/how_it_works/for_tutors"} />:<></>
            } */}
            {/* <h2><FormattedMessage id="app.how_it_works" /></h2> */}
            <div id="wrap_radio" >
              <label>
                <input name="role" type="radio" value="for_students" checked={this.state.role == "for_students"} onChange={this.handleChange} />
                <div className="wrap_radio__bg"></div>
                <span className="wrap_radio__label"><FormattedMessage id="for_students" /></span>
              </label>
              <label>
                <input name="role" type="radio" value="for_tutors" checked={this.state.role == "for_tutors"} onChange={this.handleChange} />
                <div className="wrap_radio__bg"></div>
                <span className="wrap_radio__label"><FormattedMessage id="for_tutors" /></span>
              </label>
            </div>

            <div className="how-wrapper">
              {(this.state.role == "for_students") ? <>
                <div className="why-wrapper">
                  <h2 className="heading"><FormattedMessage id="for_students.title" /></h2>
                  <div className="why-content">
                    <span className="why-item">
                      <span>
                        <h2><FormattedMessage id="for_students._1" /></h2>
                        <FormattedMessage id="for_students.text_1" /></span>
                    </span>
                    <span className="why-item">
                      <span>
                        <h2><FormattedMessage id="for_students._2" /></h2>
                        <FormattedMessage id="for_students.text_2" /></span>
                    </span>
                    <span className="why-item">
                      <span>
                        <h2><FormattedMessage id="for_students._3" /></h2>
                        <FormattedMessage id="for_students.text_3" /></span>
                    </span>
                    <span className="why-item">
                      <span>
                        <h2><FormattedMessage id="for_students._4" /></h2>
                        <FormattedMessage id="for_students.text_4" /></span>
                    </span>
                  </div>
                </div>
              </> : <>
                <div className="why-wrapper">
                  <h2 className="heading"><FormattedMessage id="for_tutors.title" /></h2>
                  <div className="why-content">
                    <span className="why-item">
                      <span>
                        <h2><FormattedMessage id="for_tutors._1" /></h2>
                        <FormattedMessage id="for_tutors.text_1" /></span>
                    </span>
                    <span className="why-item">
                      <span>
                        <h2><FormattedMessage id="for_tutors._2" /></h2>
                        <FormattedMessage id="for_tutors.text_2" /></span>
                    </span>
                    <span className="why-item">
                      <span>
                        <h2><FormattedMessage id="for_tutors._3" /></h2>
                        <FormattedMessage id="for_tutors.text_3" /></span>
                    </span>
                    <span className="why-item">
                      <span>
                        <h2><FormattedMessage id="for_tutors._4" /></h2>
                        <FormattedMessage id="for_tutors.text_4" /></span>
                    </span>
                    <span className="why-item">
                      <span>
                        <h2><FormattedMessage id="for_tutors._5" /></h2>
                        <FormattedMessage id="for_tutors.text_5" /></span>
                    </span>
                  </div>
                </div>
              </>}
            </div>
            <div className="how-wrapper">
              {(this.state.role == "for_students") ? <figure>
                <img src={`${constants.location}/images/how_it_work_2.svg`} alt="" />
                <figcaption>
                  <h3 className="heading"><FormattedMessage id="for_students.button_h" /></h3>
                  <h3 className="heading"><FormattedMessage id="for_students.button_p" /></h3>
                  <a className="search-button_green" href={"/" + constants.locale + "/tutors"}><FormattedMessage id="for_students.button" /></a>
                </figcaption>
              </figure> : <figure>
                <img src={`${constants.location}/images/earn-with-us.svg`} alt="" />
                <figcaption>
                  <h3 className="heading"><FormattedMessage id="for_tutors.button_h" /></h3>
                  <h3 className="heading"><FormattedMessage id="for_tutors.button_p" /></h3>
                  <a className="search-button_green" href={"/" + constants.locale + "/register"}><FormattedMessage id="for_tutors.button" /></a>
                </figcaption>
              </figure>}
            </div>
            <h2><FormattedMessage id="app.how_it_works_title" /></h2>
          </div>
        </div>
                <Canonical seo={true}/>
      </main>
    );
  }
}

const mapStateToProps = state => ({
  currentUser: state.currentUser
})

const mapDispatchToProps = dispatch => ({
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(HowItWorks));

