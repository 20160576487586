import React, { Component } from 'react';
import constants from '../Constants';
import Canonical from '../Canonical';
import { IntlProvider, FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import PaginatedItems from './PaginatedItems';
import findGetParameter from './FindGetParameter';

function time(unixTimeStamp) {
    let date = new Date(+unixTimeStamp);
    return ('0' + date.getDate()).slice(-2) + '.' + ('0' + (date.getMonth() + 1)).slice(-2) + '.' + date.getFullYear() + ' ' + ('0' + date.getHours()).slice(-2) + ':' + ('0' + date.getMinutes()).slice(-2);
}
// function findGetParameter(parameterName) {
//     var result = "",
//         tmp = [];
//     window.location.search
//         .substr(1)
//         .split("&")
//         .forEach(function (item) {
//             tmp = item.split("=");
//             if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
//         });
//     return result;
// }


class SearchPlugin extends React.Component {

    constructor(props) {
        super(props);
        this.onTextChanged = this.onTextChanged.bind(this);
    }

    onTextChanged(e) {
        var textArr = e.target.value.split(' ');
        this.props.filter(textArr);
    }

    render() {
        return <input className='order_card' placeholder="Пошук" onChange={this.onTextChanged} />;
    }
}



class Tab extends React.Component {
    constructor(props) {
        super(props);
    }
    state = {
        tab: 0
    }
    checkTab(i) {
        this.setState({ tab: i })
    }

    render() {
        let data = this.props.data;
        let title = this.props.title;
        let tab = this.state.tab;
        let subject = this.props.subject;
        let level = this.props.level;
        if (!Array.isArray(data)) return false;
        return (<>
            <div className="row_wrap"><p><FormattedMessage id={title} />:</p>
                <p className="p_lessons">
                    {data?.map((e, i) =>
                        (e?.[subject]) ? <span key={subject + "_" + e + "_" + i} className={"new_link_users_attr tab" + ((tab == i) ? " active" : "")} onClick={this.checkTab.bind(this, i)}><FormattedMessage id={subject + '.' + e[subject]} /></span> : ''
                    )}</p>
            </div>
            <div className="row_wrap">
                <p className="p_lessons">
                    <FormattedMessage id={"teaching_" + level} />:
                </p>
                <p className="p_lessons">
                    {data?.map((e, i) =>
                        (e?.[level]) ? <span key={level + "_" + e + "-" + i} className={"tab_content" + ((tab == i) ? " active" : "")} onClick={this.checkTab.bind(this, i)}>
                            {e[level].split(', ').map((a, n) => <span key={level + "_" + e + "-" + i + "_" + n} className="new_link_users_attr check-mark"><FormattedMessage id={level + '.' + a} /></span>)}
                        </span> : ''
                    )}
                </p>
            </div>
        </>
        );
    }
}

class Tutors extends Component {
    myRef = React.createRef();

    scroll(ref) {
        ref.current.scrollIntoView({ behavior: 'smooth' })
    }
    state = {
        close: [false, false, false],
        tutors: [],
        searchTutors: [],
        selectManager: [],
        page: 0,
        count: 0,
        offset: 0,
        alert: false,
        current: { name: "", id: "", error: "" },

        count_item: 10,
        paginated_total: 0,
        paginated_item: findGetParameter('page', 1),
        selectedTutors: [],
        totalResults: 0,
    }

    handleChange = event => {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleSubmit = event => {
        event.preventDefault()
        this.setState({ submitted: true })
        this.props.userPostForgot(this.state)
    }

    renderRedirect = () => {
        console.log("jwt: " + (!!this.props.currentUser.jwt) + " role: " + this.props.currentUser.role + " role!=1: " + (this.props.currentUser.role != 3))
        if (!this.props.currentUser.jwt || this.props.currentUser.role != 3) {
            console.log("<Redirect to={`/login} />")
            return <Redirect to={`/${constants.locale}/login`}
            />
        }
    }

    getTutors = (o = 0, i = 0) => {
        const fetch_url = constants.location
        o = (o * 50);
        fetch(fetch_url + "/handler/get_managers.php?table=tutors&offset=" + o + "&status=" + i, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'JWT': `${this.props.currentUser.jwt}`
            }
        })
            .then(resp => resp.json())
            .then(data => {
                if (data.error) {
                    // Будет ошибка если token не дествительный
                    console.log(data.error)
                    this.setState({ formError: data.error })
                } else {
                    console.log(data)
                    this.setState({ load: false, tutors: data?.tutors, searchTutors: data?.tutors, count: data?.count })
                    this.setPaginatedItem(1, data?.tutors)
                }
            })
            .catch(err => {
                console.log("err")
            })
    }
    alertActions = (id, name) => {
        const fetch_url = constants.location;
        const obj = {
            "jwt": this.props.currentUser.jwt,
            "id": id,
            "name": name
        }
        fetch(fetch_url + "/handler/tutor_actions.php", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify({ obj })
        })
            .then(resp => resp.json())
            .then(data => {
                if (data.error) {
                    console.log(data.error)
                    const current = this.state.current
                    current.error = data.error
                    this.setState({ load: false, current: current })
                } else {
                    console.log(data)
                    this.setState({ alert: false, load: false, current: { name: '', id: '', error: "" } })
                    this.getTutors(this.state.offset, this.state.page)
                }
            }).catch(err => { console.log(err) })
    }
    showPopup = (name, o) => {
        this.setState({ alert: true, current: { name: name, id: o?.id, error: '' } })
    }
    pagesTab = e => {
        this.setState({ offset: 0, page: e })
        this.getTutors(0, e)
    }
    pagesOffset = e => {
        this.setState({ offset: e })
        // this.getTutors(e, this.state.page)
    }
    alertCancellation = () => {
        this.setState({ alert: false, load: false, current: { name: '', id: '', error: "" } })
    }

    componentDidMount() {
        constants.setHeader()
        this.getTutors()
    }

    filterList(textArr) {
        let filteredList = [];
        let items = this.state.tutors;
        console.log("textArr: ", textArr);
        console.log("items: ", items);
        if (textArr.length > 0) {
            filteredList = items.filter(o => {
                let text = o?.id + " " + o?.name + " " + o?.lastname;
                return textArr.every(item => text.toLowerCase().includes(item.toLowerCase()));
            });
            console.log("array: ", filteredList)
        } else {
            filteredList = items;
        }

        console.log("searchTutors: ", filteredList);
        this.setPaginatedItem(1,filteredList);
        this.setState({ searchTutors: filteredList });
    }

    setPaginatedItem = (
        paginated_item,
        clonedArray = this.state.searchTutors ?? []
    ) => {
        console.log("setPaginatedItem: ", paginated_item);
        console.log("clonedArray: ", clonedArray)
        let totalResults = clonedArray.length;
        let paginated_total = Math.ceil(totalResults / this.state.count_item);
        paginated_item = (paginated_item > paginated_total) ? 1 : paginated_item;
        let startIndex = (paginated_item - 1) * this.state.count_item
        let selectedTutors = clonedArray.slice(+startIndex, +this.state.count_item + startIndex);
        console.log(+startIndex, +this.state.count_item + startIndex, selectedTutors)
        if (paginated_item !== this.state.paginated_item) {
            this.scroll(this.myRef)
        }
        this.setState({
            paginated_total: paginated_total,
            paginated_item: paginated_item,
            selectedTutors: selectedTutors,
            totalResults: totalResults
        });

        let params = new URLSearchParams(window.location.search);
        params.set("page", paginated_item)
        let getParam = "?" + params.toString()
        console.log(window.location.protocol + " || " + window.location.host + " || " + window.location.pathname + " || " + getParam)
        var newurl = constants.location + window.location.pathname + getParam;
        window.history.pushState({ path: newurl }, '', newurl);
    }


    render() {
        const intl = this.props.intl;
        const tutors_length = (this.state.tutors && this.state.tutors?.length > 0) ? this.state.tutors?.length : 0;
        const propsValues = (this.state.tutors?.length < 1) ? [] : this.state.tutors;
        return (<>
            {this.renderRedirect()}
            <p ref={this.myRef} className="p_lessons">
                <span onClick={this.pagesTab.bind(this, 0)} className={"new_link_users_attr tab" + ((this.state.page == 0) ? ' active' : '')}><FormattedMessage id="new_tutors" /></span>
                <span onClick={this.pagesTab.bind(this, 1)} className={"new_link_users_attr tab" + ((this.state.page == 1) ? ' active' : '')}><FormattedMessage id="proven_tutors" /></span>
                <span onClick={this.pagesTab.bind(this, -1)} className={"new_link_users_attr tab" + ((this.state.page == -1) ? ' active' : '')}><FormattedMessage id="blocked_tutors" /></span>
            </p>
            {(tutors_length < 1) ? <div className="order_card"><FormattedMessage id="data_not_found" /></div> : <>
                <p>{this.state.count} / {this.state.searchTutors.length}</p>

                <SearchPlugin filter={this.filterList.bind(this)} />

                {this.state.selectedTutors?.map((o, a) => {
                    if (a < ((this.state.offset + 1) * 50) - 50 || a > (this.state.offset + 1) * 50) return <div className="order_card">
                        <Link to={`/${constants.locale}/profile/id${o.id}`} >
                            <span className="avatar_block">
                                <div className="avatar_preview" style={{ backgroundImage: "url('" + o.photo + "')" }} alt={o.name + ' ' + o.lastname} />
                                {o.name + ' ' + o.lastname + ' id(' + o.id + ') '}
                                {/* <Link to={"./dashboard?tab=chat&id=" + o?.id} className="new_link_users_attr" onClick={this.props.clickTab?.bind(this, 'chat', o?.id)}><FormattedMessage id="to_write" /></Link> */}
                                <Link to={"./dashboard?tab=chat&id=" + o?.id} className="link_users_chat" title={intl.formatMessage({ id: "to_write" })} onClick={this.props.clickTab?.bind(this, 'chat', o?.id)} />
                            </span>
                        </Link>
                    </div>;
                    else return <div className="order_card">
                        <Link to={`/${constants.locale}/profile/id${o.id}`} >
                            <span className="avatar_block">
                                <div className="avatar_preview" style={{ backgroundImage: "url('" + o.photo + "')" }} alt={o.name + ' ' + o.lastname} />
                                {o.name + ' ' + o.lastname + ' id(' + o.id + ') '}
                                {/* <Link to={"./dashboard?tab=chat&id=" + o?.id} className="new_link_users_attr" onClick={this.props.clickTab?.bind(this, 'chat', o?.id)}><FormattedMessage id="to_write" /></Link> */}
                                <Link to={"./dashboard?tab=chat&id=" + o?.id} className="link_users_chat" title={intl.formatMessage({ id: "to_write" })} onClick={this.props.clickTab?.bind(this, 'chat', o?.id)} />
                            </span>
                        </Link>

                        <Link to={"./dashboard?tab=tutors_error&id=" + o?.id} className="edit_button" title={intl.formatMessage({ id: "to_write" })} onClick={this.props.clickTab?.bind(this, 'tutors_error', o?.id)} />

                        <div className="row_wrap"><p><FormattedMessage id="firstlogin" />:</p><p>{time(+o?.datefirstlogin * 1000)}</p></div>
                        <div className="row_wrap"><p><FormattedMessage id="lastlogin" />:</p><p>{time(o?.datelastlogin * 1000)}</p></div>

                        <Tab data={JSON.parse(o?.subjects)} title="lessons" subject="subjects" level="levels" />

                        <div className="row_wrap"><p><FormattedMessage id="online" />:</p><p>{(o?.online == "1" || o?.online == 1) ? intl.formatMessage({ id: 'yeas' }) : intl.formatMessage({ id: 'no' })}</p></div>
                        <div className="row_wrap"><p><FormattedMessage id="at_the_teacher" />:</p><p>{(o?.myplacecheck == "1" || o?.myplacecheck == 1) ? (
                            intl.formatMessage({ id: "cities." + JSON.parse(o?.city)?.[0]?.cities }) + ", " +
                            intl.formatMessage({ id: "districts." + JSON.parse(o?.city)?.[0]?.districts }) + ", " +
                            o?.myplace) : intl.formatMessage({ id: 'no' })}</p></div>
                        <div className="row_wrap"><p><FormattedMessage id="on_the_road" />:</p>
                            <p>{(o?.studentplacecheck == "1" || o?.studentplacecheck == 1) ? intl.formatMessage({ id: 'yeas' }) : intl.formatMessage({ id: 'no' })}</p>
                            {(!isNaN(+JSON.parse(o?.city)?.cities) && (o?.studentplacecheck == "1" || o?.studentplacecheck == 1)) ? <Tab data={JSON.parse(o?.city)} title="on_the_road" subject="cities" level="districts" /> : ''}
                        </div>
                        {/* <div className="row_wrap"><p><FormattedMessage id="cities" />:</p><p>
                             {o.city?.split(', ').map((item, i, arr) => <><FormattedMessage id={'cities.'+item} /> {(arr.length > 1 + i) ? ", " : ""}</>)} 
                        </p></div> */}
                        <div className="row_wrap"><p><FormattedMessage id="email" />:</p><p>{o?.login}</p></div>
                        <div className="row_wrap"><p><FormattedMessage id="phone" />:</p><p>{o?.phone}</p></div>
                        {/* age: "0"
city: "0, 1, 2, 3, 4"
emailstatus: "0"
id: "72"
lastname: "Морганюк"
login: "2.3dpu..a@gmail.com"
name: "tutors"
phone: "+380989802631"
photo: "../images/avatar_area_box.png"
rating: "0"
role: "2"
sex: "0"
showprofile: "0"
status: "1" */}
                        {/* <button className="b_btn btn-green" onClick={this.showPopup.bind(this, 1, o)}><FormattedMessage id="change" /></button> */}
                        {(+o.status == -1) ? <button className="b_btn btn-green" onClick={this.showPopup.bind(this, "unblock", o)}><FormattedMessage id="unblock" /></button> : ''}
                        {(+o.status == 0) ? <button className="b_btn btn-green" onClick={this.showPopup.bind(this, "confirm", o)}><FormattedMessage id="confirm" /></button> : ''}
                        {(+o.status == 1) ? <button className="b_btn btn-green" onClick={this.showPopup.bind(this, "block", o)}><FormattedMessage id="block" /></button> : ''}
                        <button className="b_btn btn-green" onClick={this.showPopup.bind(this, "delete", o)}><FormattedMessage id="delete" /></button>
                        <div className="row_wrap_space-between"></div>
                    </div>
                })}</>}
{console.log(`PaginatedItems: current=${this.state.paginated_item} total=${this.state.totalResults} count=${this.state.count_item}` )}
            <PaginatedItems current={this.state.paginated_item} total={this.state.totalResults} count={this.state.count_item} callback={this.setPaginatedItem.bind(this)} style="dots" />


            {/* {(this.state.count > 50) ?
                <p className="p_lessons">
                    {[...Array(Math.ceil(+this.state.count / 50)).keys()]?.map((o, a) => {
                        return <span key={o} onClick={this.pagesOffset.bind(this, o)} className={"new_link_users_attr tab" + ((this.state.offset == o) ? ' active' : '')}>{(o + 1)}</span>
                    })}
                </p> : ''} */}

            {(this.state.alert) ? <div className="alert_popup">
                <h2><FormattedMessage id="are_you_sure" />?</h2>
                {(this.state.current.error) ? <p><FormattedMessage id="error.server_error" /></p> : ''}
                <div className="row_wrap">
                    <button onClick={this.alertActions.bind(this, this.state.current.id, this.state.current.name)} className="b_btn btn-green" ><FormattedMessage id={this.state.current.name} /></button>
                    <button onClick={this.alertCancellation.bind(this)} className="b_btn btn-green" ><FormattedMessage id="cancellation" /></button>
                </div>
            </div> : ""}
        </>
        )
    }
}

const mapStateToProps = state => ({
    currentUser: state.currentUser
})

const mapDispatchToProps = dispatch => ({})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Tutors));