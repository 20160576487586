import React, { Component } from 'react';
import constants from '../Constants';
import Canonical from '../Canonical';
import { FormattedMessage, injectIntl, IntlProvider } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import SelectSearch from '../SelectSearch';
import { userSelectSearch, getSelectTutors } from '../redux/actions';
import { Redirect } from 'react-router-dom';
import TutorOrder from '../components/TutorOrder';
import PathParameter from '../components/PathParameter';
import DocumentMeta from 'react-document-meta';
function decodeHtmlCharCodes(str) {
    return str.replace(/(&#(\d+);)/g, function (match, capture, charCode) {
        return String.fromCharCode(charCode);
    });
}
function time(unixTimeStamp) {
    let date = new Date(+unixTimeStamp);
    return ('0' + date.getDate()).slice(-2) + '.' + ('0' + (date.getMonth() + 1)).slice(-2) + '.' + date.getFullYear() + ' ' + ('0' + date.getHours()).slice(-2) + ':' + ('0' + date.getMinutes()).slice(-2);
}

const getPath = () => {
    let path = window.location.pathname.split("/");
    return path?.[3];
}
function findGetParameter(parameterName) {
    // var result = "",
    var result = getPath(),
        tmp = [];
    if (result) {
        let i = result.match(/id([0-9]+)/)?.[1];
        if (i > -1) {
            return +i;
        } else {
            return result;
        }
    }
    window.location.search
        .substr(1)
        .split("&")
        .forEach(function (item) {
            tmp = item.split("=");
            if (tmp[0] === parameterName) {
                result = decodeURIComponent(tmp[1]);
                var newurl = constants.location + "/" + constants.locale + "/profile/id" + result;
                window.history.pushState({ path: newurl }, '', newurl);
            }
        });
    return result;
}

class Tab extends React.Component {
    constructor(props) {
        super(props);
    }
    state = {
        data: this.props.data,
        tab: 0
    }
    checkTab(i) {
        this.setState({ tab: i })
    }

    render() {
        let tab = this.state.tab;
        let subject = this.props.subject;
        let level = this.props.level;
        return (<>
            <p className="p_lessons">
                {this.state.data?.map((e, i) =>
                    (e?.[subject]) ? <span key={subject + "_" + e + "_" + i} className={"new_link_users_attr tab" + ((tab == i) ? " active" : "")} onClick={this.checkTab.bind(this, i)}><FormattedMessage id={subject + '.' + e[subject]} /></span> : ''
                )}</p>

            <p className="p_lessons">
                <FormattedMessage id={"teaching_" + level} />:
            </p>
            <p className="p_lessons">
                {this.state.data?.map((e, i) =>
                    (e?.[level]) ? <span key={level + "_" + e + "-" + i} className={"tab_content" + ((tab == i) ? " active" : "")} >
                        {/* onClick={this.checkTab.bind(this, i)} */}
                        {e[level].split(', ').map((a, n) => <Link to={`/${constants.locale}/tutors/${PathParameter.tutors_url?.[subject]?.[this.state.data?.[i]?.[subject]]}/${PathParameter.tutors_url?.[level]?.[a]}/`} key={level + "_" + e + "-" + i + "_" + n} className="new_link_users_attr check-mark"><FormattedMessage id={level + '.' + a} /></Link>)}
                    </span> : ''



                    // ["online", "subjects", "levels", "cities", "districts"].forEach(function (e, i) {
                    //     console.log("Ключ: " + e + " значение: " + obj?.[e]);
                    //     if (obj?.[e] && !(obj["online"]&&i>2)) {
                    //         console.log(e, obj?.[e])
                    //         let path = PathParameter.tutors_url?.[e]?.[obj?.[e]];
                    //         if (path) {
                    //             pathname += path + "/";
                    //             breadcrumbs.push([e, obj?.[e], path])
                    //         }
                    //     } else {
                    //         console.log("delete: ",e)
                    //         // params.delete(e)
                    //     }
                    // })


                )}
            </p>
        </>
        );
    }
}
function tutorsView(arr, intl, currentUser, select) {
    let name = {
        '0': 'id',
        '1': 'rating',
        '2': 'name',
        '3': 'lastname',
        '4': 'photo',
        '5': 'showprofile',
        '6': 'showdate',
        '7': 'price',
        '8': 'experience',
        '9': 'subjects',
        '10': 'education',
        '11': 'degree',
        '12': 'graduation',
        '13': 'diplomas',
        '14': 'city',
        '15': 'myplacecheck',
        '16': 'myplace',
        '17': 'address',
        '18': 'studentplacecheck',
        '19': 'studentplace',
        '20': 'online',
        '21': 'shortinfo',
        '22': 'longinfo',
        '23': 'age',
        '24': 'sex',
        '25': 'video',
        '26': 'classesschedule',
        '27': 'grade',
        '29': 'status',
        'phone': ''
    }
    //login,role,emailstatus,status

    function getClassessChedule(e) {
        let arrs = [...Array(28).keys()];
        let classesschedule = arr['classesschedule']?.replace(/[^0-9,_]/g, ' ')?.split(', ');
        return arrs.map((i) => {
            i = +i + 17
            let check = classesschedule?.includes(e + "_" + i)
            return <div key={"classesschedule" + e + "_" + i} className="schedule-cell">
                <input className="cell-input" name="classesschedule" type="checkbox" id={e + "_" + i} value={e + "_" + i} checked={check} readOnly />
                <label htmlFor={e + "_" + i}></label>
            </div>
        }
        )
    }
    function getVideoId(s) {
        var r, rx = /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed|shorts\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/;
        r = s.match(rx);
        let id=r?.[1]?r?.[1]:false;
        console.log("getVideoId: ",id);
        return id;
    }

    return <>
        <div className={"profile_card"}>
            <div className="profile_info-block">
                <div className="profile_card_header">
                    <div className="avatar_big" style={{ backgroundImage: "url('" + arr['photo'] + "')" }} title={arr['name'] + ' ' + (arr['lastname']?.substring(0, 1)) + '.'} />
                    {/* <Link to={`/profile/id${arr['id']}`}><img src={arr['photo']} alt={arr['name'] + ' ' + (arr['lastname'].substring(0, 1)) + '.'} /></Link> */}
                    <div>
                        <h2>
                            <FormattedMessage id={"role." + arr?.role} />:
                            {' ' + arr['name']} {arr['lastname']?.substring(0, 1)}.{(JSON.parse(arr['diplomas'])?.[0]?.['name'] !== undefined) ? <i className="shield" title={intl.formatMessage({ id: 'uploaded_education_documents' })}></i> : ''}
                            {(currentUser?.role > 1) ? <Link to={`/${constants.locale}/dashboard?tab=chat&id=` + arr['id']} className="link_users_chat" title={intl.formatMessage({ id: "to_write" })} /> : ''}
                        </h2>
                        <div className="user-table-column">{intl.formatMessage({ id: 'price.' + arr['price'] })}</div>
                        <a href="#reviews">
                            <div className="user-table-column button" >{(+arr['grade'].split(',')[0] > 0) ? <><i className="star-icon"></i>{(+arr['grade'].split(',')[0] / +arr['grade'].split(',')[1]).toFixed(1)} {intl.formatMessage({ id: 'reviews' })}: {arr['grade'].split(',')[1]}</> : intl.formatMessage({ id: 'no_reviews_yet' })}</div>
                        </a>
                        {(+currentUser.role > 1) ? <>
                            <a href={"tel:" + arr['phone']} className="user-table-column button">{arr['phone']}</a>
                            <a href={"mailto:" + arr['login']} className="user-table-column button">{arr['login']}</a>
                        </> : <div className="user-table-column button_green" onClick={select.bind(this)}>{intl.formatMessage({ id: 'add_to_order' })}</div>}
                    </div>
                </div>
            </div>
        </div>

        <div className={"profile_card"}>
            <div className="profile_info-block">
                <h3>{intl.formatMessage({ id: 'lessons' })}:</h3>
                {(JSON.parse(arr['subjects'])?.length) ? <Tab data={JSON.parse(arr['subjects'])} subject="subjects" level="levels" /> : ''}
            </div>
        </div>

        <div className={"profile_card"}>
            <div className="profile_info-block">
                <h3>{intl.formatMessage({ id: 'online' })}: {(arr['online'] == "1" || arr['online'] == 1) ? intl.formatMessage({ id: 'yeas' }) : intl.formatMessage({ id: 'no' })}</h3>
            </div>
            <div className="profile_info-block">
                <h3>{intl.formatMessage({ id: 'at_the_teacher' })}: {(arr['myplacecheck'] == "1" || arr['myplacecheck'] == 1) ? (
                    intl.formatMessage({ id: "cities." + JSON.parse(arr['city'])?.[0]?.cities }) + ", " +
                    intl.formatMessage({ id: "districts." + JSON.parse(arr['city'])?.[0]?.districts }) + ", " +
                    arr['myplace']) : intl.formatMessage({ id: 'no' })}</h3>
            </div>
            <div className="profile_info-block">
                <h3>{intl.formatMessage({ id: 'on_the_road' })}: {(arr['studentplacecheck'] == "1" || arr['studentplacecheck'] == 1) ? intl.formatMessage({ id: 'yeas' }) : intl.formatMessage({ id: 'no' })}</h3>
                {(JSON.parse(arr['city'])?.length && (arr['studentplacecheck'] == "1" || arr['studentplacecheck'] == 1)) ? <Tab data={JSON.parse(arr['city'])} subject="cities" level="districts" /> : ''}
            </div>
        </div>

        <div className={"profile_card"}>
            <div className="profile_info-block">
                <h3>{intl.formatMessage({ id: 'about_yourself' })}:</h3>
                <p>{constants.replacer(decodeHtmlCharCodes(arr['longinfo']))}</p>
                {/* <p>{arr['longinfo']?.replace(/&amp;amp;quot;/g, '`')?.replace(/&quot;/g, '"')?.replace(/&amp;/g, '&')}</p> */}
            </div>
            <div className="profile_info-block">
                <h3>{intl.formatMessage({ id: 'education_title' })}: {(isNaN(arr['education'])) ? arr['education'] : intl.formatMessage({ id: 'education.' + arr['education'] })}</h3>
            </div>
            <div className="profile_info-block">
                <h3>{intl.formatMessage({ id: 'age_title' })}: {intl.formatMessage({ id: 'age.' + arr['age'] })}</h3>
            </div>
            <div className="profile_info-block">
                <h3>{intl.formatMessage({ id: 'experience_title' })}: {intl.formatMessage({ id: 'experience.' + arr['experience'] })}</h3>
            </div>
        </div>

        {(JSON.parse(arr['diplomas'])?.[0]?.['name'] !== undefined) ? <div className={"profile_card"}>
            <div className="profile_info-block">
                <h3><FormattedMessage id="diplomas_and_awards" /></h3>
                <ul>{console.log(JSON.parse(arr['diplomas'])?.[0]?.['name'])}
                    {JSON.parse(arr['diplomas'])?.map((e, i) => {
                        const diplomas = JSON.parse(arr['diplomas']);
                        return (
                            <li key={i} className="float_horizontal">
                                <figure className="diplomas">
                                    <img src={diplomas[i]?.url} alt={diplomas[i]?.name} />
                                    <figcaption>{diplomas[i]?.name}</figcaption>
                                </figure>
                            </li>)
                    })}
                </ul>
            </div>
        </div> : ""}
        
        {(arr['video']?.includes('youtu') && getVideoId(arr['video'])) ? <div className={"profile_card"}>
            <div className="profile_info-block">
                {/* <h3>{intl.formatMessage({ id: 'lessons' })}:</h3> */}
                <iframe width="100%" height="315" src={'https://www.youtube.com/embed/' + getVideoId(arr['video'])} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
        </div> : ""}

        {/* <div className={"profile_card"}>
            <div className="profile_info-block">
                <h3>{intl.formatMessage({ id: 'classes_schedule' })}:</h3>
                <p>{intl.formatMessage({ id: 'classes_schedule_title' })}</p>
                <div className="schedule">
                    <div className="schedule-row title-row">
                        <div className="schedule-cell day-cell"></div>
                        <div className="schedule-cell time-cell time-17 time-18">8:00</div>
                        <div className="schedule-cell time-cell time-19 time-20">9:00</div>
                        <div className="schedule-cell time-cell time-21 time-22">10:00</div>
                        <div className="schedule-cell time-cell time-23 time-24">11:00</div>
                        <div className="schedule-cell time-cell time-25 time-26">12:00</div>
                        <div className="schedule-cell time-cell time-27 time-28">13:00</div>
                        <div className="schedule-cell time-cell time-29 time-30">14:00</div>
                        <div className="schedule-cell time-cell time-31 time-32">15:00</div>
                        <div className="schedule-cell time-cell time-33 time-34">16:00</div>
                        <div className="schedule-cell time-cell time-35 time-36">17:00</div>
                        <div className="schedule-cell time-cell time-37 time-38">18:00</div>
                        <div className="schedule-cell time-cell time-39 time-40">19:00</div>
                        <div className="schedule-cell time-cell time-41 time-42">20:00</div>
                        <div className="schedule-cell time-cell time-43 time-44">21:00</div>
                    </div>
                    <div className="schedule-row">
                        <div className="schedule-cell day-cell"><span ><FormattedMessage id="mon" /></span></div><div className="schedule-wrapper">
                            {getClassessChedule(1)}
                        </div>
                    </div>
                    <div className="schedule-row">
                        <div className="schedule-cell day-cell"><span ><FormattedMessage id="tue" /></span></div><div className="schedule-wrapper">
                            {getClassessChedule(2)}
                        </div>
                    </div>
                    <div className="schedule-row">
                        <div className="schedule-cell day-cell"><span ><FormattedMessage id="wed" /></span></div><div className="schedule-wrapper">
                            {getClassessChedule(3)}
                        </div>
                    </div>
                    <div className="schedule-row">
                        <div className="schedule-cell day-cell"><span ><FormattedMessage id="thu" /></span></div><div className="schedule-wrapper">
                            {getClassessChedule(4)}
                        </div>
                    </div>
                    <div className="schedule-row">
                        <div className="schedule-cell day-cell"><span ><FormattedMessage id="fri" /></span></div><div className="schedule-wrapper">
                            {getClassessChedule(5)}
                        </div>
                    </div>
                    <div className="schedule-row">
                        <div className="schedule-cell day-cell"><span ><FormattedMessage id="sat" /></span></div><div className="schedule-wrapper">
                            {getClassessChedule(6)}
                        </div>
                    </div>
                    <div className="schedule-row">
                        <div className="schedule-cell day-cell"><span ><FormattedMessage id="sun" /></span></div><div className="schedule-wrapper">
                            {getClassessChedule(7)}
                        </div>
                    </div>
                </div>
                <p className="available_time_for_classes"><label></label> - <FormattedMessage id="available_time_for_classes" /></p>
            </div>
        </div> */}

        {/* <div className={"profile_card"} id="reviews">
            <div className="profile_info-block">
                <h3>{intl.formatMessage({ id: 'reviews' })}:</h3>
            </div>
        </div> */}
    </>
}
function userView(arr, intl, currentUser, select) {
    let name = {
        age: "0",
        city: "{\"0\": {}}",
        emailstatus: "0",
        id: "127",
        lastname: "",
        login: "oksanabojanska@gmail.com",
        name: "Оксана",
        phone: "+380 (68) 766 61 98",
        photo: "../images/avatar_student.png",
        rating: "0",
        role: "0",
        sex: "0",
        showprofile: "0",
        status: "1"
    }

    return <>
        <div className={"profile_card"}>
            <div className="profile_info-block">
                <div className="profile_card_header">
                    <div className="avatar_big" style={{ backgroundImage: "url('" + arr['photo'] + "')" }} title={arr['name'] + ' ' + (arr['lastname']) + '.'} />
                    {/* <Link to={`/profile/id${arr['id']}`}><img src={arr['photo']} alt={arr['name'] + ' ' + (arr['lastname'].substring(0, 1)) + '.'} /></Link> */}
                    <div>

                        <h2>
                            <FormattedMessage id={"role." + arr?.role} />:{' ' + arr['name']} {arr['lastname']}
                            {(currentUser?.role > 1) ? <Link to={`/${constants.locale}/dashboard?tab=chat&id=` + arr['id']} className="link_users_chat" title={intl.formatMessage({ id: "to_write" })} /> : ''}
                        </h2>

                        {(+currentUser.role > 1) ? <>
                            <a href={"tel:" + arr['phone']} className="user-table-column button">{arr['phone']}</a>
                            <a href={"mailto:" + arr['login']} className="user-table-column button">{arr['login']}</a>
                        </> : ''}
                    </div>
                </div>
            </div>
        </div>

        {(arr['city'] !== '' && arr['city'] !== '{"0": {}}') ? (JSON.parse(arr['city'])?.[0]?.cities) ?
            <div className={"profile_card"}>
                <div className="profile_info-block">
                    <h3>{intl.formatMessage({ id: 'tutorcity_placeholder' })}: {
                        intl.formatMessage({ id: "cities." + JSON.parse(arr['city'])?.[0]?.cities }) + ", " +
                        intl.formatMessage({ id: "districts." + JSON.parse(arr['city'])?.[0]?.districts })}</h3>
                </div>
            </div> : '' : ''}

        <div className={"profile_card"}>
            <div className="profile_info-block">
                <h3>{intl.formatMessage({ id: 'age_title' })}: {intl.formatMessage({ id: 'age.' + arr['age'] })}</h3>
            </div>
        </div>
    </>
}


class Profile extends Component {
    constructor(props) {
        super(props);
    }
    state = {
        load: true,
        id: findGetParameter("id"),
        profile: {},
        close_popup: [true],
        reviews: {}
    }
    contactTutor = () => {
        // this.state.close_popup[0] = false
        this.setState({ close_popup: [false] })
    }

    getProfile = () => {
        const id = findGetParameter("id")
        if (id) {
            const fetch_url = constants.location
            fetch(fetch_url + "/handler/get_profile.php?id=" + id + "", {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    'jwt': `${this.props.currentUser.jwt}`
                }
            })
                .then(resp => resp.json())
                .then(data => {
                    if (data.error) {
                        // Будет ошибка если token не дествительный
                        console.log(data.error)
                        this.setState({ load: false, formError: this.props.intl.formatMessage({ id: data.error }) })
                    } else {
                        console.log(data.message)
                        if (data.profile) {
                            this.setState({ load: false, profile: data.profile, reviews: data.reviews })
                        } else if (data.reviews) {
                            this.setState({ load: false, reviews: Object.assign({}, this.state.reviews, data.reviews) })
                        }

                    }
                })
                .catch(err => {
                    console.log(err)
                })
        } else {
            return <Redirect to={`/${constants.locale}/`} />
        }
    }

    componentDidMount() {
        constants.setHeader()
        this.getProfile()
    }

    render() {
        if (this.state.load) return (<>load</>)
        console.log(this.state)

        const reviews = this.state.reviews;
        const profile = this.state.profile;
        const intl = this.props.intl;

        let title = this.props.intl.formatMessage({ id: 'document.title.profile' });
        let description = "";
        if (Object.keys(profile).length !== 0) {
            const subjects = profile?.['subjects'] ? JSON.parse(profile?.['subjects']) : [];
            if (subjects?.length) {
                subjects.map(e => {
                    description += `, ${intl.formatMessage({ id: "subjects." + e?.subjects })}`
                })
            }
            const cities = profile?.['city'] ? JSON.parse(profile?.['city']) : [];
            if (cities?.length) {
                cities.map((e, i) => {
                    if (i !== 0 && cities?.[0]?.cities === e?.cities) {
                        description += `, ${intl.formatMessage({ id: "cities." + e?.cities })}`;
                    }
                })
            }
            title = `${intl.formatMessage({ id: "role." + profile?.role })}: ${profile?.['name'] ? profile?.['name'] : ""}${profile?.['lastname'] ? " " + profile?.['lastname'] : ""}${description}`;
            description = `${(+profile?.role == 1) ? intl.formatMessage({ id: 'document.title.tutor_data' }) : intl.formatMessage({ id: 'document.title.profile' })}${description}`;
        }

        const meta = {
            title: title,
            description: description,
            canonical: constants.location + window.location.pathname,
            meta: {
                charset: 'utf-8',
                name: {
                    keywords: ""
                }
            }
        };

        return (
            <main className="main_wrapper_block search_tutors">
                <DocumentMeta {...meta} />
                <div className="main_wrapper_block_content_stretch_profile">
                    {/* <h1 className="search_tutors_title">{this.state.id}</h1> */}
                    {(Object.keys(this.state.profile).length == 0) ?
                        <main className="block_404">
                            <Canonical title={this.props.intl.formatMessage({ id: 'user_not_found' })} />
                            <img className="img_404" src="/images/404.svg" />
                        </main>
                        :
                        ((+this.state.profile?.role == 1)) ? <>
                            {tutorsView(this.state.profile, this.props.intl, this.props.currentUser, this.contactTutor)}
                            {/* <Reviews currentUser={this.props.currentUser} intl={this.props.intl} /> */}
                            <div className={"order_card"} id="reviews">
                                <div className="profile_info-block">
                                    <h3><FormattedMessage id="reviews" />:</h3>
                                    <div style={{ transition: 'ease 0.5s' }}>
                                        {(Object.keys(reviews)?.length > 0) ?
                                            Object.keys(reviews).map((key) => {
                                                let o = reviews[key];
                                                return <div key={key} className="review_block">
                                                    <div className="row_wrap"><p>{time(+o.date * 1000)}</p><p><i className="star-icon"></i>{o.rate}</p></div>
                                                    <div className="row_wrap"><p>{o.name}</p><p>{o.feedback}</p></div>
                                                </div>
                                            }) : <FormattedMessage id="reviews_not_found" />}
                                    </div>
                                </div>
                            </div>
                        </> : userView(this.state.profile, this.props.intl, this.props.currentUser, this.contactTutor)}
                </div>
                {!this.state.close_popup[0] ? <TutorOrder currentTutor={this.state.profile} close={this.state.close_popup} searchState={this.state} /> : ""}
            </main>
        )
    }
}

const mapStateToProps = state => ({
    currentUser: state.currentUser
})

const mapDispatchToProps = dispatch => ({
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Profile));