import React, { Component, useState, useEffect } from 'react';
import constants from '../Constants';
import Canonical from '../Canonical';
import { IntlProvider, FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { userPostTutor, userPostAvatar } from '../redux/actions';
import { Link } from 'react-router-dom';
import SelectSearch from '../SelectSearch';
import InputCheck from './InputCheck'
import { userSelectSearch } from '../redux/actions';
import { Redirect } from 'react-router-dom';
import EditPass from './EditPass';

function isNumber(n) { return !isNaN(parseFloat(n)) && !isNaN(n - 0) }

class UserData extends Component {

  constructor(props) {
    super(props);
  }

  state = {
    jwt: this.props.currentUser.jwt,
    editname: false,
    editpass: false,
    name: "",
    lastname: "",
    email: "",
    phone: "",
    role: 0,//this.props.currentUser.role,
    submitted: false,

    confirm: { email: true, phone: true },
    formErrors: { name: '', lastname: '', email: '', phone: '' },
    formValids: { valid: false, name: false, lastname: false, email: false, phone: false },
    submitErrors: this.props.error ? this.props.error : "",

    numOfsubjects: this.props.currentUser.subjects_json ? (Object.keys(this.props.currentUser.subjects_json).length - 1) : 0,
    numOfsubjectsArr: this.props.currentUser.subjects_json ? Object.keys(this.props.currentUser.subjects_json) : ["0"],
    numOfcities: this.props.currentUser.cities_json ? (Object.keys(this.props.currentUser.cities_json).length - 1) : 0,
    numOfcitiesArr: this.props.currentUser.cities_json ? Object.keys(this.props.currentUser.cities_json) : ["0"],
    avatar: {
      src: "../images/avatar_area_box.png",
      upload: null,
      name: "user image"
    },
    tutorcity_check1: false,
    tutorcity_check2: false,
    tutorcity_check3: false,
    sex: 0,
    files: [],
    diplomas: [],
    classesschedule: '',
    validProps: {},
    validPropsState: true,
    validPropsDidUpdate: true,
    confirm_email_mess: false,
    input_confirm: ''
  }

  // fileSelectedHandler = (e) => {
  //   var files = [...this.state.diplomas, ...e.target.files];
  //   console.log(files)
  //   if (files.length > 5) files.length = 5
  //   console.log(files)
  //   this.setState({ files: files })
  //   console.log(this.props.currentUser)
  //   this.setData(0, "files", files)
  // }
  // fileDelete(i) {
  //   var files = this.state.diplomas
  //   console.log("delete: " + i)
  //   files.splice(i, 1);
  //   this.setState({ files: files })
  //   this.setData(0, "files", files)
  // }
  fileSelectedHandler = (e) => {
    var old_ = this.state.diplomas
    if (old_?.length >= 5) return false;
    var new_ = e.target.files;
    // console.log(new_)
    var old_l = old_?.length
    for (let i = 0; i < (5 - old_l); i++) {
      // console.log("new_[" + i + "]======")
      if (new_?.[i]?.name) {
        var fileReader = new FileReader();
        fileReader.onload = (fileLoadedEvent) => {
          var data = {}
          data = { jwt: this.props.currentUser.jwt, name: new_[i].name, imgBase64: fileLoadedEvent.target.result }
          const fetch_url = constants.location
          fetch(fetch_url + "/handler/diplomas.php", {
            method: "POST",
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
            },
            body: JSON.stringify({ data })
          })
            .then(resp => resp.json())
            .then(data => {
              if (data.error) {
                console.log(data)
              } else {
                console.log(data)
                //url" => $url, "name
                if (data.name) {
                  let file = [{ name: data.name, url: data.url }];
                  var files = [...this.state.diplomas, ...file];
                  this.setState({ diplomas: files })
                }
              }
            }).catch(err => {
              console.log(err)
            })
        };
        fileReader.readAsDataURL(new_[i]);
      } else { return false }
    }
  }
  fileDelete(i) {
    var files = this.state.diplomas
    console.log("delete: " + i)
    var data = {}
    data = { jwt: this.props.currentUser.jwt, name: files[i].name, url: files[i].url }
    const fetch_url = constants.location
    fetch(fetch_url + "/handler/diplomas.php?delete=true", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({ data })
    })
      .then(resp => resp.json())
      .then(data => {
        if (data.error) {
          console.log(data)
        } else {
          console.log(data)
          files.splice(i, 1);
          this.setState({ diplomas: files })
        }
      }).catch(err => {
        console.log(err)
      })
  }

  readImage(fileToLoad) {
    var fileReader = new FileReader();
    fileReader.onload = (fileLoadedEvent) => {
      var data = {}
      data = { jwt: this.props.currentUser.jwt, imgBase64: fileLoadedEvent.target.result }
      this.props.userPostAvatar(data).then(items => {
        this.formError(items);
      });
      //return true;
    };
    fileReader.readAsDataURL(fileToLoad);
  }
  handleDrop = (files) => {
    this.handleSubmitImage(files[0])
  }


  id = console.log("*************this.state*********") + console.log(this.state)
  // state = Object.assign(this.state, this.props.currentUser, this.props.currentUser)
  state = Object.assign(this.state, this.props.currentUser)
  id = console.log("*************this.2.state*********") + console.log(this.state)


  validateField(fieldName, value) {
    const intl = this.props.intl
    var formErrors = this.state.formErrors;
    var formValids = this.state.formValids;
    switch (fieldName) {
      case 'name':
        formValids.name = (value != null && value.length >= 2);
        formErrors.name = formValids.name ? '' : intl.formatMessage({ id: "error.is_too_short" });
        break;
      case 'lastname':
        formValids.lastname = (value != null && value.length >= 2);
        formErrors.lastname = formValids.lastname ? '' : intl.formatMessage({ id: "error.is_too_short" });
        break;
      case 'email':
        formValids.email = value?.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) ? true : false;
        formErrors.email = formValids.email ? '' : intl.formatMessage({ id: "error.is_invalid" });
        break;
      case 'phone':
        formValids.phone = (value != null && value.length >= 10);
        formErrors.phone = formValids.phone ? '' : intl.formatMessage({ id: "error.is_too_short" });
        break;
      default:
        break;
    }
    formValids.valid = (formValids.name && formValids.lastname && formValids.email && formValids.phone)

    // this.setState({ 
    //   validForm: (formValids.name && formValids.lastname && formValids.email && formValids.phone),
    //   formErrors: formErrors,
    //   formValids: formValids
    // });

    console.log(this.state)
    console.log("validForm: formValids.name && formValids.lastname && formValids.email && formValids.phone")
    console.log(formValids.name && formValids.lastname && formValids.email && formValids.phone)
  }

  valid() {
    for (var key in this.state.formValids) {
      console.log(key)
      this.validateField(key, this.state[key])
    }
  }
  formValids = console.log("@@@@@@@@@@@this.state.formValids") + console.log(this.state.formValids) + this.valid()

  setData(id, key, val) {
    var object = this.props.currentUser;
    object[key] = val
    // object[this.props.object] = selected
    this.props.userSelectSearch(object);
    console.log(">>>>>>> this.props.userSelectSearch(object)")
    console.log(this.props.currentUser)
  }
  handleChange = event => {
    this.validateField(event.target.name, event.target.value);
    this.setState({
      [event.target.name]: event.target.value,
      submitErrors: ""
    });
    console.log(event.target.value);
    this.setData(0, event.target.name, event.target.value)
  }
  handleCheck = e => {
    var value = e.target.checked;
    var obj = e.target.name
    this.setState({ [obj]: value });
    this.setData(0, obj, value)
  }
  handleInputChange = e => {
    var value = e.target.value;
    var obj = e.target.name
    this.setState({ [obj]: value });
    this.setData(0, obj, value);
  }
  scheduleCheck = e => {
    var value = e.target.value;
    var check = e.target.checked;
    var name = e.target.name;
    var array = this.state[name];//.split(', ');
    if (check) {
      array.push(value);
    } else {
      const index = array.indexOf(value);
      if (index > -1) {
        array.splice(index, 1);
      }
    }
    console.log(value, check, name);

    // var collection = array.join(', ');
    // array = (collection.charAt(0) == ',') ? collection.substr(2) : collection;

    console.log(array);
    this.setState({ [name]: array });
    this.setData(0, name, array);
  }
  componentDidMount() {
constants.setHeader()
    this.setState();



    let classesschedule = this.state.classesschedule


    if (classesschedule == '' || classesschedule == undefined || JSON.stringify(classesschedule) == '{}' || JSON.stringify(classesschedule) == '[]') {
      this.setState({ classesschedule: [] }); return false
    } else {
      console.log(classesschedule);
      if (!Array.isArray(classesschedule)) {
        this.setState({ classesschedule: classesschedule?.replace(/[^0-9,_]/g, ' ')?.split(', ') })
      }
    }
    console.log(classesschedule);

    //collection=collection.join(', ');
    //return (collection.charAt(0) == ',')?collection.substr(2):collection;
    // const array = this.state.classesschedule.replace(/[^0-9,_]/g, ' ').split(', ');
    // if (array.length < 1) return false;

    // array?.map((key) => {
    //   console.log(key);
    //   return document.getElementById(key).checked = true;
    // })
  }
  classessChedule = e => {
    return this.state.classesschedule.indexOf(e.target.value);
  }

  onMouseEnterHandler = e => {
    var value = e.target.getAttribute("for").split('_');
    document.getElementsByClassName("time-" + value[1])[0].classList.add("active-time");
  }
  onMouseLeaveHandler = e => {
    var value = e.target.getAttribute("for").split('_');
    document.getElementsByClassName("time-" + value[1])[0].classList.remove("active-time");
  }

  preSave = e => {
    let utf8_to_b64 = window.btoa(unescape(encodeURIComponent(JSON.stringify(this.props.currentUser))));//utf8_to_b64('✓ à la mode'); // JTI1dTI3MTMlMjUyMCUyNUUwJTI1MjBsYSUyNTIwbW9kZQ==
    const fetch_url = constants.location;
    const obj = {
      "jwt": this.props.currentUser.jwt,
      "b64": utf8_to_b64
    }
    fetch(fetch_url + "/handler/user_data_error.php", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({ obj })
    })
      .then(resp => resp.json())
      .then(data => {
        if (data.error) {
          console.log(data)
        } else {
          console.log(data)
        }
      }).catch(err => { console.log(err) })
    //return decodeURIComponent(escape(window.atob(str)));//b64_to_utf8('JTI1dTI3MTMlMjUyMCUyNUUwJTI1MjBsYSUyNTIwbW9kZQ=='); // "✓ à la mode"
  }

  handleSubmit = event => {
    event.preventDefault()
    this.preSave();
    if (!this.validProps()) return false;
    this.setState({ submitted: true })
    if (!this.state.formValids.valid) this.setState({ submitted: false });
    if (this.state.formValids.valid) this.props.userPostTutor(this.props.currentUser, this.submitMessage).then(items => {
      this.formError(items);
    });
  }

  submitMessage = e => {
    console.log("submitMessage: " + e);
    if (e) {
      this.state.formValids.valid = false;
      this.setState({ input_confirm: e })
      let timer = setTimeout(() => this.setState({ input_confirm: '' }), 7000)
    }
  }
  formError = event => {
    if (this.props.error) {
      this.setState({ submitted: false, submitErrors: this.props.error })
    };
  }

  handleChangeImage = e => {
    // let avatar=this.state.avatar
    // avatar.src=URL.createObjectURL(e.target.files[0])
    // avatar.upload=this.readImage(e.target.files[0])
    // avatar.name=e.target.files[0].name
    // this.setState({avatar: avatar})
    this.handleSubmitImage(e.target.files[0])
  }
  handleSubmitImage = (e) => {
    let avatar = this.state.avatar
    // for (var i = 0; i < files.length; i++) {
    console.log("(files[0].name)")
    if (e.name) {
      avatar.src = URL.createObjectURL(e)
      avatar.upload = this.readImage(e)
      avatar.name = e.name
    } else { return false }
    this.setState({ avatar: avatar })
    // }
  }

  editName = () => {
    this.setState({ editname: (!this.state.editname) })
  }
  confirm(e) {
    const fetch_url = constants.location
    fetch(fetch_url + "/handler/send_activation.php?auth=" + this.props.currentUser.jwt, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'JWT': `${this.props.currentUser.jwt}`
      }
    })
      .then(resp => resp.json())
      .then(data => {
        if (data.error) {
          // Будет ошибка если token не дествительный
          console.log(data.error)
          this.setState({ formError: data.error })
        } else {
          console.log(data)
          this.setState({ confirm_email_mess: true, emailstatus: true })
        }
      })
      .catch(err => {
        console.log(err)
      })
  }
  editPass = () => {
    this.setState({ editpass: (!this.state.editpass) })
  }
  addNewSubjects = (e) => {
    console.log("addNew_" + e)
    let numOfArr = this.state["numOf" + e + "Arr"], numOf = 1 + this.state["numOf" + e];
    console.log("numOf" + e + "Arr  " + numOfArr)
    console.log(numOf)
    numOfArr.push(numOf);
    // numOfsubjectsArr.push('id_'+numOfsubjects);
    this.setState({ ["numOf" + e]: numOf, ["numOf" + e + "Arr"]: numOfArr })
  }
  deleteSubject(e, key) {
    console.log(" delete_" + e + ": " + key)
    let object = this.props.currentUser;
    let newJson = object[e + "_json"];
    let numOfArr = this.state["numOf" + e + "Arr"]
    console.log(numOfArr);
    const index = numOfArr.indexOf(key);
    if (index > -1) {
      numOfArr.splice(index, 1);
    }
    console.log(numOfArr);
    delete newJson[key]
    object[e + "_json"] = newJson
    this.props.userSelectSearch(object);
    this.setState({ ["numOf" + e + "Arr"]: numOfArr })
  }


  handleFiles = () => {
    var filesToUpload = document.getElementById('input').files;
    var file = filesToUpload[0];

    // Create an image
    var img = document.createElement("img");
    // Create a file reader
    var reader = new FileReader();
    var canvas = document.getElementById('canvas');
    var ctx = canvas.getContext('2d');

    reader.onload = function (e) {
      img.src = e.target.result;
      console.log(e);

      ctx.drawImage(img, 1, 1, 1, 1);
      //         //ctx.drawImage(img, 0, 0);
      // var wrh = img.width / img.height;
      // var newWidth = canvas.width;
      // var newHeight = newWidth / wrh;
      // if (newHeight > canvas.height) {
      //   newHeight = canvas.height;
      //   newWidth = newHeight * wrh;
      // }
      // var xOffset = newWidth < canvas.width ? ((canvas.width - newWidth) / 2) : -10;
      // var yOffset = newHeight < canvas.height ? ((canvas.height - newHeight) / 2) : -10;

      // ctx.drawImage(img, xOffset, yOffset, newWidth, newHeight);

      // get the scale
      var scale = Math.max(canvas.width / img.width, canvas.height / img.height);
      // get the top left position of the image
      var x = (canvas.width / 2) - (img.width / 2) * scale;
      var y = (canvas.height / 2) - (img.height / 2) * scale;
      ctx.drawImage(img, x, y, img.width * scale, img.height * scale);

      var dataurl = canvas.toDataURL("image/png");
      document.getElementById('image').src = dataurl;
      img.src = '';
    };
    // Load files into file reader
    console.log(file.type + '-' + (file.type == "image/*"))
    reader.readAsDataURL(file);

    // Post the data
    /*
    var fd = new FormData();
    fd.append("name", "some_filename.jpg");
    fd.append("image", dataurl);
    fd.append("info", "lah_de_dah");
    */
  }

  validProps = (didUpdate) => {
    if (didUpdate === true) {
      this.setState({ validPropsDidUpdate: false })
    } else {
      this.setState({ validPropsDidUpdate: true })
    }
    console.log(this.props.currentUser)
    const o = this.props.currentUser;
    if (+o.role !== 1) { console.log("+o.role!==1 - " + o.role); return true; }
    const intl = this.props.intl
    let e = {
      "cities": "",
      "subjects": "",
      "myplace": "",
      "place": "",
      "price": "",
      "education": "",
      "degree": ""
    }
    let i = true
    if (o.myplace_bool || o.studentplace || o.online) {
      if (o.myplace_bool) {
        if (o.myplace.length < 3) { e["myplace"] = intl.formatMessage({ id: "error.is_too_short" }); i = false; }
        if (isNumber(o.cities_json[0]?.cities) && isNumber(o.cities_json[0]?.districts)) { } else { e["cities"] = intl.formatMessage({ id: "error.is_invalid" }); i = false; }
      }
      if (o.studentplace) {
        if (isNumber(o.cities_json[0]?.cities) && isNumber(o.cities_json[0]?.districts)) { } else { e["cities"] = intl.formatMessage({ id: "error.is_invalid" }); i = false; }
      }
      // if (o.myplace_bool && o.myplace.length < 3) { e["myplace"] = intl.formatMessage({ id: "error.is_too_short" }); i = false; }
      // if (o.myplace_bool && isNumber(o.cities_json[0]?.cities) && isNumber(o.cities_json[0]?.districts)) { } else { e["cities"] = intl.formatMessage({ id: "error.is_invalid" }); i = false; }
    } else {
      e["place"] = intl.formatMessage({ id: "error.is_invalid" }); i = false;
    }
    if (o.price < 1) { e["price"] = intl.formatMessage({ id: "error.is_invalid" }); i = false; }

    if (o.education_bool && o.education.length < 5) { e["education"] = intl.formatMessage({ id: "error.is_too_short" }); i = false; }

    if (o.degree_bool && o.degree.length < 5) { e["degree"] = intl.formatMessage({ id: "error.is_too_short" }); i = false; }
    //if (isNumber(o.cities_json[0]?.cities) && isNumber(o.cities_json[0]?.districts)) { } else { e["cities"] = intl.formatMessage({ id: "error.is_invalid" }); i = false; }
    if (o.subjects_json[0]?.levels !== '' && isNumber(o.subjects_json[0]?.subjects)) { } else { e["subjects"] = intl.formatMessage({ id: "error.is_invalid" }); i = false; }

    this.setState({ validProps: e, validPropsState: i })
    if (!i) {
      setTimeout(() => this.setState({
        validProps: {
          "cities": "",
          "subjects": "",
          "myplace": "",
          "place": "",
          "price": "",
          "education": "",
          "degree": ""
        }, validPropsState: true
      }), 5000);
    }
    console.log(e)
    return i
  }

  componentDidUpdate(prevProps) {
    console.log("componentDidUpdate(prevProps)")
    if (!this.state.validPropsState && this.state.validPropsDidUpdate) {
      console.log("TRUE --- componentDidUpdate(prevProps)")
      return this.validProps(true)
    }
  }

  renderRedirect = () => {
    console.log("jwt: " + (!!this.props.currentUser.jwt) + " role: " + this.props.currentUser.role + " role!=1: " + (this.props.currentUser.role != 1))
    if (!this.props.currentUser.jwt) {// || this.props.currentUser.role!=1 ) {
      console.log("<Redirect to={`/login} />")
      return <Redirect to={`/${constants.locale}/login`} />
    }
  }

  getClassessChedule = (e) => {
    let arr = [...Array(28).keys()];
    const classesschedule = this.state.classesschedule
    // const valid=(classesschedule !== '' && classesschedule !== undefined && JSON.stringify(classesschedule)!=='{}' && JSON.stringify(classesschedule)!=='[]')
    return arr.map((i) => {
      i = +i + 17
      let check = (Array.isArray(classesschedule)) ? classesschedule.includes(e + "_" + i) : false
      return <div className="schedule-cell">
        <input className="cell-input" name="classesschedule" type="checkbox" id={e + "_" + i} value={e + "_" + i} onChange={this.scheduleCheck} checked={check} />
        <label onMouseEnter={this.onMouseEnterHandler} onMouseLeave={this.onMouseLeaveHandler} for={e + "_" + i}></label>
      </div>
    }
    )
  }

  render() {
    document.title = this.props.intl.formatMessage({ id: 'document.title.user_data' });
    return (
      <>
        {this.renderRedirect()}
        {/* <div id="register" className="table_header"> */}


        <form onSubmit={this.handleSubmit} id="user_data">
          {/* <h1><strong> <FormattedMessage id="app.tutors.register.data.title" /></strong></h1>
            <h2><FormattedMessage id="app.tutors.register.data.text" /></h2> */}

          {/* // CREATE TABLE `fp418402_superprof`.`tutors` ( `id` INT NOT NULL , `jwt` TEXT NOT NULL , `photo` TEXT NULL , `phone` INT NULL , `showprofile` BOOLEAN NULL , `showdate` INT NULL , `price` INT NULL , `experiense` INT NULL , `subjects` JSON NULL , `education` JSON NULL , `degree` JSON NULL , `graduation` INT NULL , `diplomas` JSON NULL , `city` INT NULL , `myplacecheck` BOOLEAN NULL , `myplace` INT NULL , `address` TEXT NULL , `studentplacecheck` BOOLEAN NULL , `studentplace` JSON NULL , `online` BOOLEAN NULL , `shortinfo` TEXT NULL , `longinfo` TEXT NULL , `age` INT NULL , `sex` BOOLEAN NULL , `video` TEXT NULL , `classesschedule` JSON NULL ) ENGINE = MyISAM; */}

          <div className="profile-field">

            <div className="profile-field_flex">
              {/* <form onSubmit={this.handleSubmitImage}> */}
              <DragAndDrop handleDrop={this.handleDrop}>
                <div className="drag_n_drop_img avatar_big" style={this.state.avatar.src ? { backgroundImage: 'url(' + this.state.avatar.src + ')' } : ''}>
                  {/* {this.state.avatar.src?(<div style={{backgroundImage: src(this.state.avatar.src)}} alt={this.state.avatar.name}></div>):''} */}
                  <input type="file" id="img" name="img" accept="image/*" className="w-100" onChange={this.handleChangeImage} />
                </div>
              </DragAndDrop>
              {/* </form> */}
              <div className="profile-field_flex_right">
                <div className="full-name">
                  {this.state.lastname} {this.state.name}
                  <span className="edit_button" onClick={this.editName}></span>
                </div>

                {(this.state.editname || this.state.name == '' || this.state.lastname == '') ? (<>
                  <h3><FormattedMessage id="app.name" /></h3>
                  <FormattedMessage id={'app.name'}>
                    {(msg) => (<input className="profile-field_input"
                      placeholder={msg}
                      id="signup-name"
                      type='text'
                      name='name'
                      value={this.state.name}
                      onChange={this.handleChange}
                      required
                    />)}
                  </FormattedMessage>
                  <div className="input_error">{this.state.formErrors.name}</div>

                  <h3><FormattedMessage id="app.lastname" /></h3>
                  <FormattedMessage id={'app.lastname'}>
                    {(msg) => (<input className="profile-field_input"
                      placeholder={msg}
                      id="signup-lastname"
                      type='text'
                      name='lastname'
                      value={this.state.lastname}
                      onChange={this.handleChange}
                      required
                    />)}
                  </FormattedMessage>
                  <div className="input_error">{this.state.formErrors.lastname}</div>
                </>) : ''}

                <h3><FormattedMessage id="app.email" /></h3>
                <FormattedMessage id={'app.email'}>
                  {(msg) => (<input className="profile-field_input"
                    placeholder={msg}
                    id="signup-email"
                    type='email'
                    name='email'
                    value={this.state.email}
                    onChange={this.handleChange}
                    required
                  />)}
                </FormattedMessage>
                <div className="input_error">{this.state.formErrors.email}</div>
                {+this.state.emailstatus == 1 ? "" : (
                  <div className="input_confirm" onClick={this.confirm.bind(this, "email")}><FormattedMessage id="app.confirm.email" /></div>
                )}
                {this.state.confirm_email_mess ? (
                  <div className="input_confirm"><FormattedMessage id="app.confirm.email_mess" /></div>
                ) : ''}

                <h3><FormattedMessage id="app.phone" /></h3>
                <FormattedMessage id={'app.phone'}>
                  {(msg) => (<input className="profile-field_input"
                    placeholder={msg}
                    id="signup-phone"
                    type='phone'
                    name='phone'
                    value={this.state.phone}
                    onChange={this.handleChange}
                    required
                  />)}
                </FormattedMessage>
                <div className="input_error">{this.state.formErrors.phone}</div>
                {/* {this.state.confirm.phone ? "":(
                  <div className="input_confirm" onClick={this.confirm("phone")}><FormattedMessage id="app.confirm.phone" /></div>
                )} */}

                <div className="input_confirm" onClick={this.editPass}><FormattedMessage id="app.edit.pass" /></div>
                {this.state.editpass ? (<EditPass />) : ''}
              </div>
            </div>


            {(this.state.role == 1) ? <>
              <h3><FormattedMessage id="app.tutors.register.data.text2" /></h3>

              <h3><FormattedMessage id="lessons" /></h3>
              {/* <SelectSearch title="app.what_do_you_want_to_learn" object="subjects" level="levels" checkbox="true" id="0" /> */}

              <ul>{
                this.state.numOfsubjectsArr.map((key, i) =>
                  <li className="subject_field" key={key} data-id={i} data-val={key} style={(key == "search") ? ({ display: 'none' }) : {}}>
                    <SelectSearch title="app.what_do_you_want_to_learn" object="subjects" level="levels" checkbox="true" ids={key} /><div style={(!i) ? ({ display: 'none' }) : {}} className="remove" onClick={this.deleteSubject.bind(this, "subjects", key)}></div>
                  </li>
                )
              }</ul>
              <span className="add_lesson" onClick={this.addNewSubjects.bind(this, "subjects")}><FormattedMessage id="lesson_add_button" /></span>
              <i className="input_confirm">{this.state.validProps?.subjects}</i>


              <h3><FormattedMessage id="experience_title" />:</h3>
              <SelectSearch title="experience_placeholder" object="experience" ids="0" />

              <h3><FormattedMessage id="price_title" />:</h3>
              <SelectSearch title="price_placeholder" object="price" ids="0" />
              <i className="input_confirm">{this.state.validProps?.price}</i>

              <h3><FormattedMessage id="education_title" />:</h3>
              <InputCheck name="education" currentUser={this.props.currentUser} ids="0" />
              <i className="input_confirm">{this.state.validProps?.education}</i>

              <h3><FormattedMessage id="degree_title" />:</h3>
              <InputCheck name="degree" currentUser={this.props.currentUser} ids="0" />
              <i className="input_confirm">{this.state.validProps?.degree}</i>

              <h3><FormattedMessage id="graduationyear_title" />:</h3>
              <FormattedMessage id={'graduationyear_placeholder'}>
                {(msg) => (<input className="profile-field_input"
                  type="number"
                  max="2050"
                  min="1950"
                  maxLength="4"
                  required
                  name="graduation"
                  placeholder={msg}
                  value={this.state.graduation}
                  onChange={this.handleInputChange}
                />)}
              </FormattedMessage>

              <h3><FormattedMessage id="diplomas_and_awards" /></h3>
              <ul>
                <li><FormattedMessage id="acceptable_formats" /> .png, .jpg, .jpeg, .gif</li>
                <li><FormattedMessage id="number_of_files" /> {this.state.diplomas?.length ? this.state.diplomas?.length : 0}-5</li>
              </ul>
              {this.state.diplomas?.length ? (
                <ul>
                  {this.state.diplomas.map((e, i) => {
                    console.log(e.name); return (
                      <li key={i} className="float_horizontal">
                        <div className="image_icon"></div>
                        <p>{this.state.diplomas[i].name}</p>
                        <div className="garbage" onClick={this.fileDelete.bind(this, i++)}></div>
                      </li>)
                  })}
                </ul>
              ) : ''}
              <span className="add_files">
                <input type="file" multiple onChange={this.fileSelectedHandler} accept="image/*" className="w-add_files" />
                <FormattedMessage id="add_files" />
              </span>

              <h3><FormattedMessage id="tutorplaces_title" />:</h3>

              <InputCheck name="myplace" currentUser={this.props.currentUser} selectHide="true" ids="0" />
              <i className="input_confirm">{this.state.validProps?.myplace}</i>
              <i className="input_confirm">{this.state.validProps?.cities}</i>
              {/* {()?<><h3><FormattedMessage id="tutorcity_title" />:</h3>
              <SelectSearch title="app.where" object="cities" level="districts" ids="0" />
              <i className="input_confirm">{this.state.validProps?.cities}</i></>:''} */}


              <label className="checkbox_container">
                <FormattedMessage id={"tutorcity_check2"} />
                <input type="checkbox" hidden onChange={this.handleCheck} name="studentplace" checked={this.state.studentplace} />
                <span className="checkmark"></span>
              </label>
              {this.state.studentplace ? (
                <div>
                  <ul>{
                    this.state.numOfcitiesArr.map((key, i) =>
                      <li className="subject_field" key={key} data-id={i} data-val={key} style={(key == "search") ? ({ display: 'none' }) : {}}>
                        {/* <li className="subject_field" key={key} data-id={i} data-val={key} style={(key == "search" || i == 0) ? ({ display: 'none' }) : {}}> */}
                        <SelectSearch title="app.where" object="cities" level="districts" checkbox="true" ids={key} /><div style={(!i) ? ({ display: 'none' }) : {}} className="remove" onClick={this.deleteSubject.bind(this, "cities", key)}></div>
                      </li>
                    )
                  }</ul>
                  <span className="add_lesson" onClick={this.addNewSubjects.bind(this, "cities")}><FormattedMessage id="cities_add_button" /></span>
                  <i className="input_confirm">{this.state.validProps?.cities}</i>
                </div>
              ) : ""}


              <label className="checkbox_container">
                <FormattedMessage id={"tutorcity_check3"} />
                <input type="checkbox" hidden onChange={this.handleCheck} name="online" checked={this.state.online} />
                <span className="checkmark"></span>
              </label>
              <i className="input_confirm">{this.state.validProps?.place}</i>

              <h3><FormattedMessage id="yourself_and_activities_title" />:</h3>
              <FormattedMessage id={'yourself_and_activities_placeholder'}>
                {(msg) => (<textarea className="profile-field_textarea"
                  type="text"
                  rows="10"
                  name="yourself_and_activities"
                  maxLength="5000"
                  required
                  placeholder={msg}
                  value={this.state.yourself_and_activities?.replace(/&amp;amp;quot;/g, '`')?.replace(/&quot;/g, '"')?.replace(/&amp;/g, '&')}
                  onChange={this.handleInputChange}
                />)}
              </FormattedMessage>
            </> : ''}



            <h3><FormattedMessage id="age_title" />:</h3>
            <SelectSearch title="age_placeholder" object="age" ids="0" />

            <h3><FormattedMessage id="sex_title" />:</h3>
            <div onChange={this.handleInputChange} >
              <label className="checkbox_container">
                <FormattedMessage id={"sex_0"} />
                <input type="radio" hidden name="sex" value="0" checked={(this.state.sex == 0)} readOnly />
                <span className="checkmark"></span>
              </label>
              <label className="checkbox_container">
                <FormattedMessage id={"sex_1"} />
                <input type="radio" hidden name="sex" value="1" checked={(this.state.sex == 1)} readOnly />
                <span className="checkmark"></span>
              </label>
            </div>

            {(this.state.role == 1) ? <>
              <h3><FormattedMessage id="linkvideo_title" />:</h3>
              <input className="profile-field_input"
                type="url"
                name="linkvideo"
                placeholder="https://www.youtube.com/"
                pattern="https://.*"
                size="300"
                value={this.state.linkvideo}
                onChange={this.handleInputChange}
              />

              {/* <h3><FormattedMessage id="classes_schedule" /></h3>
              <div className="schedule">
                <div className="schedule-row title-row">
                  <div className="schedule-cell day-cell"></div>
                  <div className="schedule-cell time-cell time-17 time-18">8:00</div>
                  <div className="schedule-cell time-cell time-19 time-20">9:00</div>
                  <div className="schedule-cell time-cell time-21 time-22">10:00</div>
                  <div className="schedule-cell time-cell time-23 time-24">11:00</div>
                  <div className="schedule-cell time-cell time-25 time-26">12:00</div>
                  <div className="schedule-cell time-cell time-27 time-28">13:00</div>
                  <div className="schedule-cell time-cell time-29 time-30">14:00</div>
                  <div className="schedule-cell time-cell time-31 time-32">15:00</div>
                  <div className="schedule-cell time-cell time-33 time-34">16:00</div>
                  <div className="schedule-cell time-cell time-35 time-36">17:00</div>
                  <div className="schedule-cell time-cell time-37 time-38">18:00</div>
                  <div className="schedule-cell time-cell time-39 time-40">19:00</div>
                  <div className="schedule-cell time-cell time-41 time-42">20:00</div>
                  <div className="schedule-cell time-cell time-43 time-44">21:00</div></div>
                <div className="schedule-row">
                  <div className="schedule-cell day-cell"><span ><FormattedMessage id="mon" /></span></div><div className="schedule-wrapper">
                    {this.getClassessChedule(1)}
                  </div>
                </div>
                <div className="schedule-row">
                  <div className="schedule-cell day-cell"><span ><FormattedMessage id="tue" /></span></div><div className="schedule-wrapper">
                    {this.getClassessChedule(2)}
                  </div>
                </div>
                <div className="schedule-row">
                  <div className="schedule-cell day-cell"><span ><FormattedMessage id="wed" /></span></div><div className="schedule-wrapper">
                    {this.getClassessChedule(3)}
                  </div>
                </div>
                <div className="schedule-row">
                  <div className="schedule-cell day-cell"><span ><FormattedMessage id="thu" /></span></div><div className="schedule-wrapper">
                    {this.getClassessChedule(4)}
                  </div>
                </div>
                <div className="schedule-row">
                  <div className="schedule-cell day-cell"><span ><FormattedMessage id="fri" /></span></div><div className="schedule-wrapper">
                    {this.getClassessChedule(5)}
                  </div>
                </div>
                <div className="schedule-row">
                  <div className="schedule-cell day-cell"><span ><FormattedMessage id="sat" /></span></div><div className="schedule-wrapper">
                    {this.getClassessChedule(6)}
                  </div>
                </div>
                <div className="schedule-row">
                  <div className="schedule-cell day-cell"><span ><FormattedMessage id="sun" /></span></div><div className="schedule-wrapper">
                    {this.getClassessChedule(7)}
                  </div>
                </div>
              </div> */}
              {/* <p><i className="available_active" onClick={this.validProps}></i> - <FormattedMessage id="available_time_for_classes" /></p> */}
            </> : ''}
          </div>

          {/* <img src="" id="image" />
<input id="input" type="file" onChange={this.handleFiles} />
<canvas id="canvas" width="240" height="240" style={{border: "1px solid black"}} /> */}


          <div className="btn-wrap-center">
            {(this.state.validPropsState) ? '' : <i className="input_confirm"><FormattedMessage id="error.not_all_information" /></i>}
            {(this.state.input_confirm == 'ok') ? <i className="input_confirm ok"><FormattedMessage id="profile_saved_successfully" /></i> : ''}
            {(this.state.input_confirm == 'error') ? <i className="input_confirm"><FormattedMessage id="error.server_error" /></i> : ''}
            <button type='submit' className={`b_btn btn-green ${this.state.submitted ? "submitted" : ""}`} disabled={!this.state.formValids.valid}>
              <FormattedMessage id="app.tutors.register.button" />
            </button>
          </div>

          <p><FormattedMessage id="app.tutors.register.comment" /></p>
          <div className="input_error_popup">{this.state.submitErrors}</div>
          {/* <Link to={`/login/`}><FormattedMessage id="app.login"/></Link> */}
        </form>
        <div className="button-wrapper"><span id="go_to_advantages" className="button-down-white"></span></div>
        {/* </div> */}

        {(this.state.role == 1) ? <div className="table-content">
          <h2><FormattedMessage id="app.tutors.register.text1" /></h2>
          <p><FormattedMessage id="app.tutors.register.text2" /></p>
          <p><FormattedMessage id="app.tutors.register.text3" /></p>
          <p><FormattedMessage id="app.tutors.register.text4" /></p>
          <p><FormattedMessage id="app.tutors.register.text5" /></p>
          <p><FormattedMessage id="app.tutors.register.text6" /></p>
          <p><FormattedMessage id="app.tutors.register.text7" /></p>
        </div> : ''}

      </>
    )
  }
}
const mapStateToProps = state => ({
  currentUser: state.currentUser
})

const mapDispatchToProps = dispatch => ({
  userSelectSearch: object => dispatch(userSelectSearch(object)),
  userPostTutor: (userInfo, callback) => dispatch(userPostTutor(userInfo, callback)),
  userPostAvatar: userInfo => dispatch(userPostAvatar(userInfo))
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(UserData));

class DragAndDrop extends Component {
  state = {
    drag: false
  }
  dragCounter = 0
  dropRef = React.createRef()
  handleDrag = (e) => {
    e.preventDefault()
    e.stopPropagation()
  }
  handleDragIn = (e) => {
    e.preventDefault()
    e.stopPropagation()
    this.dragCounter++
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      this.setState({ drag: true })
    }
  }
  handleDragOut = (e) => {
    e.preventDefault()
    e.stopPropagation()
    this.dragCounter--
    if (this.dragCounter == 0) {
      this.setState({ drag: false })
    }
  }
  handleDrop = (e) => {
    e.preventDefault()
    e.stopPropagation()
    this.setState({ drag: false })
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      this.props.handleDrop(e.dataTransfer.files)
      e.dataTransfer.clearData()
      this.dragCounter = 0
    }
  }
  componentDidMount() {
constants.setHeader()
    let div = this.dropRef.current
    div.addEventListener('dragenter', this.handleDragIn)
    div.addEventListener('dragleave', this.handleDragOut)
    div.addEventListener('dragover', this.handleDrag)
    div.addEventListener('drop', this.handleDrop)
  }
  componentWillUnmount() {
    let div = this.dropRef.current
    div.removeEventListener('dragenter', this.handleDragIn)
    div.removeEventListener('dragleave', this.handleDragOut)
    div.removeEventListener('dragover', this.handleDrag)
    div.removeEventListener('drop', this.handleDrop)
  }
  render() {
    return (
      <div
        style={{ display: 'inline-block', position: 'relative' }}
        ref={this.dropRef}
      >
        {this.state.drag ?
          <div
            style={{
              border: 'dashed grey 4px',
              backgroundColor: 'rgba(255,255,255,.8)',
              position: 'absolute',
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              zIndex: 9999
            }}
          >
            <div
              style={{
                position: 'absolute',
                top: '50%',
                right: 0,
                left: 0,
                textAlign: 'center',
                color: 'grey',
                fontSize: 36
              }}
            >
              <div>drop here :)</div>
            </div>
          </div>
          : ''}
        {this.props.children}
      </div>
    )
  }
}
