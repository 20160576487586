import React, { Component } from 'react';
import constants from '../Constants';
import Canonical from '../Canonical';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import SelectSearch from '../SelectSearch';
import { userSelectSearch, getSelectTutors } from '../redux/actions';
function declOfNum(number, words) {
    return words[(number % 100 > 4 && number % 100 < 20) ? 2 : [2, 0, 1, 1, 1, 2][(number % 10 < 5) ? Math.abs(number) % 10 : 5]];
}
function time(unixTimeStamp) {
    let date = new Date(+unixTimeStamp * 1000);
    return ('0' + date.getDate()).slice(-2) + '.' + ('0' + (date.getMonth() + 1)).slice(-2) + '.' + date.getFullYear() + ' ' + ('0' + date.getHours()).slice(-2) + ':' + ('0' + date.getMinutes()).slice(-2);
}
function date_time(date) {
    var now = new Date(date);
    now.setMinutes(now.getMinutes() - now.getTimezoneOffset());
    let first_lesson = now.toISOString().slice(0, 16);
    return first_lesson;
}

function findGetParameter(parameterName) {
    var result = "",
        tmp = [];
    window.location.search
        .substr(1)
        .split("&")
        .forEach(function (item) {
            tmp = item.split("=");
            if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
        });
    return result;
}

class Order extends Component {
    constructor(props) {
        super(props);
    }
    state = {
        unixTime: Math.trunc(+Date.now() / 1000),
        load: false,
        alert: false,
        alertMessage: '',
        current: { name: "", id: "" },
        first_lesson: date_time(+Date.now()),
        write_a_feedback: "",
        rate: "",
        payment: ''
    }

    getIntl = (id) => {
        const { intl } = this.props;
        return intl.formatMessage({ id: id })
    }


    //   getOrder = () => {
    //     // if (this.props.currentUser.role == 2) return false
    //     const fetch_url = constants.location
    //     fetch(fetch_url + "/handler/get_tutor_order.php?id="+findGetParameter("id"), {
    //       method: "GET",
    //       headers: {
    //         'Content-Type': 'application/json',
    //         Accept: 'application/json',
    //         'jwt': `${this.props.currentUser.jwt}`
    //       }
    //     })
    //       .then(resp => resp.json())
    //       .then(data => {
    //         if (data.error) {
    //           // Будет ошибка если token не дествительный
    //           console.log(data.error)
    //           this.setState({ formError: this.props.intl.formatMessage({ id: data.error }) })
    //         } else {
    //             console.log("~~~~~ "+fetch_url + "/handler/get_tutor_order.php?id="+findGetParameter("id"))
    //           console.log(data.message)
    //           console.log(data.order)
    //           this.setState({ load: false, order: data.order })
    //         }
    //       })
    //       .catch(err => {
    //         console.log(err)
    //       })
    //   }

    orderConfirm = (o, name, id) => {
        // this.setState({alert: true, alertMessage: name})
        this.setState({ alert: true, current: { name: name, id: id } })
    }
    orderCancellation = () => {
        this.setState({ alert: false, load: false, current: { name: '', id: '' } })
    }
    orderActions = (o, name) => {
        this.setState({ alert: false, load: true })
        const fetch_url = constants.location;
        const obj = {
            "jwt": this.props.currentUser.jwt,
            "name": name,
            "first_lesson": (this.state.first_lesson) ? Math.trunc(+new Date(this.state.first_lesson) / 1000) : "",
            "feedback": this.state.write_a_feedback,
            "rate": this.state.rate,
            "id": o.id
        }
        console.log(obj)
        fetch(fetch_url + "/handler/order_actions.php", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify({ obj })
        })
            .then(resp => resp.json())
            .then(data => {
                if (data.error) {
                    console.log(data)
                    this.setState({ load: false })
                } else {
                    console.log(data)
                    if (data.status && data.status != undefined) o.status = data.status;
                    if (data.rate && data.rate != undefined) o.rate = data.rate;
                    o.first_lesson = (data.first_lesson) ? data.first_lesson : null
                    this.setState({ load: false })
                }
            }).catch(err => { console.log(err) })
        this.setState({ current: { name: '', id: '', value: '' } })
        this.setState({ load: false })
        console.log("o.status=" + o.status)
    }
    setDate = (event) => {
        console.log(event.target)
        //let value = event.target.valueAsNumber
        let value = event.target.value;
        this.setState({ first_lesson: value });
    }
    setData = (event) => {
        console.log(event.target)
        let value = event.target.value
        this.setState({ first_lesson: value });
    }
    rate = (event) => {
        let value = event.target.value
        this.setState({ rate: value });
    }
    handleChangeText = event => {
        let value = event.target.value
        let len = 500
        let name = event.target.name
        if (value.length > len) {
            value = value.substr(0, len)
        }
        this.setState({
            [name]: value
        });
    }
    getPayment = () => {
        this.setState({ load: true })
        const fetch_url = constants.location
        fetch(fetch_url + "/handler/get_payment.php", {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'JWT': `${this.props.currentUser.jwt}`
            }
        })
            .then(resp => resp.json())
            .then(data => {
                if (data.error) {
                    // Будет ошибка если token не дествительный
                    console.log(data.error)
                    this.setState({ formError: data.error })
                } else {
                    console.log(data)
                    this.setState({ load: false, payment: data?.payment })
                }
            })
            .catch(err => {
                console.log("err")
            })
    }
    control = (o) => {
        let role = this.props.currentUser.role;
        let actions = '';
        if (role == 1) {
            let action = '';
            if (o.status == 1 && +o.first_lesson == 0) {
                // var now = new Date(this.state.first_lesson);
                // now.setMinutes(now.getMinutes() - now.getTimezoneOffset());
                // let first_lesson = now.toISOString().slice(0,16);
                action = <div className="row_wrap dashed_border"><p><FormattedMessage id="indicate_the_date_of_the_first_lesson" />:</p><div>
                    <input className="date_input" id="datetime" type="datetime-local" name="first_lesson" onChange={this.setDate.bind(this)} value={this.state.first_lesson} required />

                    {/* <input className="date_input" type="date" id="first_lesson" name="first_lesson" onChange={this.setDate.bind(this)} value={new Date(this.state.first_lesson).toISOString().split('T')[0]} required /> */}
                    <button className="b_btn btn-green" onClick={(this.state.first_lesson) ? this.orderActions.bind(this, o, "first_lesson") : false} disabled={!this.state.first_lesson} ><FormattedMessage id="send" /></button>
                </div></div>
            }
            if (o.status == -1) {
                actions = <>{action}
                    <button onClick={this.orderConfirm.bind(this, o, "accept", o.id)} className="b_btn btn-green" ><FormattedMessage id="accept" /></button>
                </>;
            } else if (o.status == 0) {
                actions = <>{action}
                    <button onClick={this.orderConfirm.bind(this, o, "accept", o.id)} className="b_btn btn-green" ><FormattedMessage id="accept" /></button>
                    <button onClick={this.orderConfirm.bind(this, o, "refuse", o.id)} className="b_btn btn-green" ><FormattedMessage id="refuse" /></button>
                </>;
            } else if (o.status == 1) {
                let k = (+o.lesson_duration == 0) ? 1 : ((+o.lesson_duration == 1) ? 1.5 : 2);
                actions = <>{action}<div className="dashed_border" style={{ width: "100%" }}>
                    <div className="row_wrap"><p><FormattedMessage id="amount_to_pay" />:</p><p>{(+o.price * 2 * k)} <FormattedMessage id="currency" /></p></div>
                    <div className="row_wrap"><p><FormattedMessage id="payment_details" />:</p><p style={{ whiteSpace: "pre-wrap" }}>{this.state.payment}</p></div>
                    {/* <button onClick={this.orderConfirm.bind(this, o, "pay", o.id)} className="b_btn btn-green" ><FormattedMessage id="pay" /></button> */}
                </div></>;
            } else {
                actions = action;
            }
        }
        if (role == 2 || role == 3) {
            let action = <>
                {(+o.status !== -1 && +o.status !== 2)?<button onClick={this.orderConfirm.bind(this, o, "set_to_free", o.id)} className="b_btn btn-green" ><FormattedMessage id="set_to_free" /></button>:''}
                <button onClick={this.orderConfirm.bind(this, o, "delete", o.id)} className="b_btn btn-green" ><FormattedMessage id="delete" /></button>
            </>;
            if (o.status == 0) {
                actions = <><button onClick={this.orderConfirm.bind(this, o, "block_tutor", o.id)} className="b_btn btn-green" ><FormattedMessage id="block_tutor" /></button>{action}</>;
            } else if (o.status == 1) {
                actions = <><button onClick={this.orderConfirm.bind(this, o, "confirm_payment", o.id)} className="b_btn btn-green" ><FormattedMessage id="confirm_payment" /></button>{action}</>;
            } else {
                actions = action;
            }
        }
        if (role == 0) {
            // if (o.status == 1 && +o.first_lesson == 0) {
            //     actions = <div className="row_wrap dashed_border"><p><FormattedMessage id="indicate_the_date_of_the_first_lesson" />:</p><div>
            //         <input className="date_input" type="date" id="first_lesson" name="first_lesson" onChange={this.setDate.bind(this)} value={new Date(this.state.first_lesson).toISOString().split('T')[0]} required />
            //         <button className="b_btn btn-green" onClick={(this.state.first_lesson) ? this.orderActions.bind(this, o, "first_lesson") : false} disabled={!this.state.first_lesson} ><FormattedMessage id="send" /></button>
            //     </div></div>
            // } else 
            if (o.status == 2) {
                if (+o.rate > 0) {
                    actions = ''
                    // <div className="rating">
                    //     <input name="rating" type="radio" id="star5" checked={+o.rate == 5} value="5" onChange={false} /><label for="star5"></label>
                    //     <input name="rating" type="radio" id="star4" checked={+o.rate == 4} value="4" onChange={false} /><label for="star4"></label>
                    //     <input name="rating" type="radio" id="star3" checked={+o.rate == 3} value="3" onChange={false} /><label for="star3"></label>
                    //     <input name="rating" type="radio" id="star2" checked={+o.rate == 2} value="2" onChange={false} /><label for="star2"></label>
                    //     <input name="rating" type="radio" id="star1" checked={+o.rate == 1} value="1" onChange={false} /><label for="star1"></label>
                    // </div>
                } else {
                    actions = <div className="modal-content dashed_border">
                        <div className="request-wrapper-column">
                            <h2 style={{ margin: "auto" }}><FormattedMessage id="did_you_like_the_lesson" /></h2>
                            <p><FormattedMessage id="rate_the_tutor" /></p>
                            <div className="rating">
                                <input type="radio" id="star5" name="rating" value="5" onChange={this.rate.bind(this)} /><label for="star5"></label>
                                <input type="radio" id="star4" name="rating" value="4" onChange={this.rate.bind(this)} /><label for="star4"></label>
                                <input type="radio" id="star3" name="rating" value="3" onChange={this.rate.bind(this)} /><label for="star3"></label>
                                <input type="radio" id="star2" name="rating" value="2" onChange={this.rate.bind(this)} /><label for="star2"></label>
                                <input type="radio" id="star1" name="rating" value="1" onChange={this.rate.bind(this)} /><label for="star1"></label>
                            </div>
                            <textarea name="write_a_feedback" value={this.state.write_a_feedback} onChange={this.handleChangeText}
                                placeholder={this.props.intl.formatMessage({ id: "write_a_feedback_about_tutor" })} />
                            <button className="b_btn btn-green" onClick={(this.state.rate && this.state.write_a_feedback) ? this.orderActions.bind(this, o, "rate") : false} disabled={!(this.state.rate && this.state.write_a_feedback)} ><FormattedMessage id="send" /></button>
                        </div>
                    </div>
                }
            }
        }
        return actions;

        // "accept":"принять",
        // "refuse":"отказатся",
        // "pay":"оплатить",
        // "block_tutor":"заблокировать репетитора",
        // "confirm_payment":"подтвердить оплату",
        // "bill":"выставить счет"
        // "first_lesson"
        // "rate"
    }


    componentDidMount() {
        constants.setHeader()
        this.getPayment()
    }
    render() {
        let role = this.props.currentUser.role;
        let o = {};
        let id = findGetParameter("id")
        if (this.props.orders?.find(arr => +arr.id === +id)?.id === id) {
            o = this.props.orders?.find(arr => +arr.id === +id)
        } else {
            return <div className="order_card"><FormattedMessage id="data_not_found" /></div>
        }
        // console.log("o");
        // console.log(o);
        console.log(this.state);
        let start = Math.trunc((+this.state.unixTime - +o.start) / (1 * 24 * 60 * 60));
        let first_lesson = (o.first_lesson > 1) ? Math.trunc((+this.state.unixTime - +o.first_lesson) / (1 * 24 * 60 * 60)) : 0;
        if (+o.taken == 0) o.taken = +this.state.unixTime;
        if (+o.end == 0) o.end = +this.state.unixTime;
        let taken = (o.status > 0) ? Math.trunc((+this.state.unixTime - +o.taken) / (1 * 24 * 60 * 60)) : '';
        let end = (o.status > 1) ? Math.trunc((+this.state.unixTime - +o.end) / (1 * 24 * 60 * 60)) : '';
        let color = (+o.status == 0) ? ((+start > 3) ? ((+start > 7) ? "red" : "yelow") : "green") : ((+o.status == 1) ? ((+taken > 3) ? ((+taken > 7) ? "red" : "yelow") : "green") : "green");
        let first_color = (+first_lesson > 3) ? ((+first_lesson > 7) ? "red" : "yelow") : "green";
        return (<>
            <div className="order_card">
                <strong>
                    <FormattedMessage id="order" /> №<Link to={"/order?id=" + o.id}>{o.id}</Link> <FormattedMessage id="from" /> {time(o.start)} - ({start} <FormattedMessage id={declOfNum(start, ['day', 'of_the_day', 'days'])} />)
                    {(o.status == 2 && o.rate > 0) ? <span><i className="star-icon"></i>{o.rate}</span> : ''}
                    <span className={"new_link_users_attr bgcolor_" + color}><FormattedMessage id={"order_status." + o.status} /></span>
                </strong>
                {(+o?.tutur > 0) ? <div className="row_wrap"><p><FormattedMessage id="tutor" />:</p><p><Link to={`/${constants.locale}/profile/id${o.tutor}`} >{o.tutor_name}</Link>
                    {(+role != 1) ? <Link to={"./dashboard?tab=chat&id=" + o.tutor} className="link_users_chat" title={this.getIntl("to_write")} onClick={this.props.clickTab?.bind(this, 'chat', o.tutor)}></Link> : ''}
                </p></div> : ''}
                {(o.status > 0) ? (<div className="row_wrap"><p><FormattedMessage id={"order_status.1"} />:</p><p className={"color_ " + color}>{time(o.taken)} - ({taken} <FormattedMessage id={declOfNum(taken, ['day', 'of_the_day', 'days'])} />)</p></div>) : ''}

                {(o.first_lesson > 1) ? (<div className="row_wrap"><p><FormattedMessage id={"first_lesson"} />:</p><p className={"color_ " + first_color}>{time(o.first_lesson)} - ({first_lesson} <FormattedMessage id={declOfNum(first_lesson, ['day', 'of_the_day', 'days'])} />)</p></div>) : ''}
                {/* {(o.first_lesson > 1) ? (<div className="row_wrap"><p><FormattedMessage id={"first_lesson"} />:</p><p>{time(o.first_lesson)}</p></div>) : ''} */}

                {(o.status > 1) ? (<div className="row_wrap"><p><FormattedMessage id={"order_status.2"} />:</p><p>{time(o.end)} - ({end} <FormattedMessage id={declOfNum(end, ['day', 'of_the_day', 'days'])} />)</p></div>) : ''}
                {/* {(o.taken_tutor) ?
                    <div className="row_wrap"><p><FormattedMessage id="tutor" />:</p><p><Link to={`/${constants.locale}/profile/id${o.taken_tutor}`} >{o.taken_tutor}</Link></p></div> : ''
                } */}

                {/* at_the_student: "0"
    at_the_tutor: "0"
    cities: "4"
    districts: "91"
    email: "email@gmail.com"
    end: "0"
    help_text: ""
    id: "7"
    lesson_duration: "0"
    levels: "29"
    name: "Ian"
    online: "1"
    orders_street: ""
    periodicity: "1"
    phone: "0989802631"
    start: "1636197124419"
    status: "0"
    subjects: "0"
    times_text: "kjhg"
    tutor: "54"
    wishes_text: ""
    taken 
    price
    first_lesson
    */}
                <div className="row_wrap"><p><FormattedMessage id="customer" />:</p><p>
                    <Link to={`/${constants.locale}/profile/id${o.student}`} > {o.name}.  </Link>
                    {(+role != 0) ? <Link to={"./dashboard?tab=chat&id=" + o.student} className="link_users_chat" title={this.getIntl("to_write")} onClick={this.props.clickTab?.bind(this, 'chat', o.student)}></Link> : ''}
                </p></div>
                {(o?.email && o.status > 0) ? <div className="row_wrap dashed_border"><p><FormattedMessage id="app.email" />:</p><p>{o?.email}</p></div> : ''}
                {(o?.phone && o.status > 0) ? <div className="row_wrap dashed_border"><p><FormattedMessage id="app.phone" />:</p><p>{o?.phone}</p></div> : ''}


                {(o?.tutor > 0) ? <div className="row_wrap"><p><FormattedMessage id="tutor" />:</p><p><Link to={`/${constants.locale}/profile/id${o?.tutor}`} >{o?.tutor_name} </Link>
                    {(+role != 1) ? <Link to={"./dashboard?tab=chat&id=" + o?.tutor} className="link_users_chat" title={this.getIntl("to_write")} onClick={this.props.clickTab?.bind(this, 'chat', o?.tutor)} ></Link> : ''}
                </p></div> : ''}
                {(o?.tutor_email && o.status > 0) ? <div className="row_wrap dashed_border"><p><FormattedMessage id="app.email" />:</p><p>{o?.tutor_email}</p></div> : ''}
                {(o?.tutor_phone && o.status > 0) ? <div className="row_wrap dashed_border"><p><FormattedMessage id="app.phone" />:</p><p>{o?.tutor_phone}</p></div> : ''}

                <div className="row_wrap"><p><FormattedMessage id="price_placeholder" />:</p><p><FormattedMessage id={"price." + o.price} /></p></div>
                <div className="row_wrap"><p><FormattedMessage id="item" />:</p><p><FormattedMessage id={"subjects." + o.subjects} /></p></div>
                <div className="row_wrap"><p><FormattedMessage id="level_of_preparation" />:</p><p><FormattedMessage id={"levels." + o.levels} /></p></div>
                <div className="row_wrap"><p><FormattedMessage id="location_of_classes" />:</p><p>
                    {(+o.at_the_tutor == 1) ? <><FormattedMessage id="at_the_tutor" /><br /></> : ''}
                    {(+o.online == 1) ? <><FormattedMessage id="online" /><br /></> : ''}
                    {(+o.at_the_student == 1) ? <><FormattedMessage id="at_the_student" /><br /></> : ''}
                </p></div>


                <div className="row_wrap"><p><FormattedMessage id="periodicity" />:</p><p><FormattedMessage id={"periodicity." + o.periodicity} /></p></div>
                <div className="row_wrap"><p><FormattedMessage id="lesson_duration" />:</p><p><FormattedMessage id={"lesson_duration." + o.lesson_duration} /></p></div>


                {(o.wishes_text) ? <div className="row_wrap"><p><FormattedMessage id="other_wishes" />:</p><p>{o.wishes_text}</p></div> : ''}
                {(o.times_text) ? <div className="row_wrap"><p><FormattedMessage id="class_time" />:</p><p>{o.times_text}</p></div> : ''}
                {(o.help_text) ? <div className="row_wrap"><p><FormattedMessage id="need_help_with" />:</p><p>{o.help_text}</p></div> : ''}


                <div className="row_wrap_end">{this.control(o)}</div>
                {/* <Link className="search-button_green" to={"./dashboard?tab=order&id=" + o.id} onClick={this.props.clickTab?.bind(this, 'order', o.id)}>
                    <FormattedMessage id="more_details" />
                </Link> */}
            </div>
            {(this.state.alert) ? <div className="alert_popup">
                <h2><FormattedMessage id="are_you_sure" />?</h2>
                <div className="row_wrap">
                    <button onClick={this.orderActions.bind(this, o, this.state.current.name)} className="b_btn btn-green" ><FormattedMessage id={this.state.current.name} /></button>
                    <button onClick={this.orderCancellation.bind(this)} className="b_btn btn-green" ><FormattedMessage id="cancellation" /></button>
                </div>
            </div> : ""}

        </>)
    }
}
const mapStateToProps = state => ({
    currentUser: state.currentUser
})

const mapDispatchToProps = dispatch => ({
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Order));