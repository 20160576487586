
import constants from '../Constants';

const fetch_url = constants.location;//"https://goal.org.ua";//constants.location //"http://localhost:8888" //constants.location

export const userSignupFetch = user => {
    console.log("start userSignupFetch")
    console.log(JSON.stringify({ user }))
    return dispatch => {
        return fetch(fetch_url + "/handler/signup.php", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify({ user })
        })
            .then(resp => resp.json())
            .then(data => {
                if (data.error) {
                    // console.log(data.message)
                    dispatch(error(data.error))
                    //Тут прописываем логику
                } else {
                    console.log(data)
                    localStorage.setItem("token", data.user.jwt)
                    dispatch(loginUser(data.user))
                    dispatch(message(data.message))
                }
            })
            .catch(err => {
                console.log(err)
                if (err) dispatch(error("error.server_error"))
            })
    }
}

export const userLoginFetch = user => {
    return dispatch => {
        return fetch(fetch_url + "/handler/login.php", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify({ user })
        })
            .then(resp => resp.json())
            .then(data => {
                if (data.error) {
                    dispatch(error(data.error))
                    //тут ваша логика
                } else {
                    localStorage.setItem("token", data.user.jwt)
                    dispatch(loginUser(data.user))
                    dispatch(message(data.message))
                }
            })
            .catch(err => {
                console.log(err)
                if (err) dispatch(error("error.server_error"))
            })
    }
}
const loginUser = userObj => ({
    type: 'LOGIN_USER',
    payload: userObj
})

export const userPostTutor = (user, callback) => {
    console.log("start userPostTutor")
    console.log(JSON.stringify({ user }))
    return dispatch => {
        return fetch(fetch_url + "/handler/tutors.php", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify({ user })
        })
            .then(resp => resp.json())
            .then(data => {
                if (data.error) {
                    // console.log(data.message)
                    dispatch(error(data.error))
                    //Тут прописываем логику
                    callback("error");
                } else {
                    console.log(data)
                    console.log("callback data.message");
                    callback(data.message);
                    // localStorage.setItem("token", data.user.jwt)
                    // dispatch(loginUser(data.user))
                }
            })
            .catch(err => {
                callback("error");
                console.log(err)
                if (err) dispatch(error("error.server_error"))
            })
    }
}
export const userPostAvatar = user => {
    console.log("start userPostAvatar")
    console.log(JSON.stringify({ user }))
    return dispatch => {
        return fetch(fetch_url + "/handler/image_upload.php", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify({ user })
        })
            .then(resp => resp.json())
            .then(data => {
                if (data.error) {
                    // console.log(data.message)
                    dispatch(error(data.error))
                    //Тут прописываем логику
                } else {
                    console.log(data)
                    dispatch(avatar(data.image))
                    dispatch(message(data.message))
                    // localStorage.setItem("token", data.user.jwt)
                    // dispatch(loginUser(data.user))
                }
            })
            .catch(err => {
                console.log(err)
                if (err) dispatch(error("error.server_error"))
            })
    }
}
const avatar = userObj => ({
    type: 'AVATAR',
    payload: userObj
})

export const userSelectSearch = (object) => {
    return dispatch => {
        //localStorage.setItem("token", data.user.jwt)
        dispatch(searchTutorForm(object))
    }
}
const searchTutorForm = userObj => ({
    type: 'TUTOR_FORM',
    payload: userObj
})

export const userSelectSearchError = (object) => {
    return dispatch => {
        //localStorage.setItem("token", data.user.jwt)
        dispatch(searchTutorFormError(object))
    }
}
const searchTutorFormError = userObj => ({
    type: 'TUTOR_FORM_ERROR',
    payload: userObj
})



export const getSelectTutors = tutor => {
    return dispatch => {
        return fetch(fetch_url + "/handler/select_tutors.php", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify({ tutor })
        })
            .then(resp => resp.json())
            .then(data => {
                if (data.error) {
                    dispatch(error(data.error))
                } else {
                    dispatch(selectTutors(data.tutors))
                    console.log(data)
                    dispatch(message(data.message))
                }
            })
            .catch(err => {
                console.log(err)
                if (err) dispatch(error("error.server_error"))
            })

        // let data = {
        //     "message": "ok",
        //     "tutors": [
        //         ["41", "120", "Игорь", "Морганюк", "", null, null, "150", "0", "[{\"levels\": \"3, 28\", \"subjects\": \"0\"}, {\"levels\": \"28, 3\", \"subjects\": \"3\"}, {\"levels\": \"29\", \"subjects\": \"5\"}]", "0", "0", "2000", "\"{\"aaa \":\"asdas\"}\"", "[{\"cities\": \"2\", \"districts\": \"517\"}, {\"cities\": \"3\", \"districts\": \"548, 154, 66\"}, {\"cities\": \"0\", \"districts\": \"548\"}]", "1", "Одесской", "Одесской", "1", "\"1\"", "0", "", "Метод unshift не является привязанным к типу; этот метод может быть вызван или применён к объектам, напоминающим массив. Объекты, не содержащие свойство length, отражающее последний элемент в серии последовательных числовых, начинающихся с нуля, свойств, могут повести себя неправильным образом.", "0", "1", "https:\/\/www.youtube.com\/watch?v=N_swnvNug10", "\"1_17, 1_18\"", "5, 9"],
        //         ["51", "150", "Р?РіРѕСЂСЊ", "РњРѕСЂРіР°РЅСЋРє", " ", null, null, "300", "1", "[{\"levels\": \"3, 28, 2\", \"subjects\": \"0\"}]", "608", "5", "1950", "[]", "[{\"cities\": \"1\", \"districts\": \"229\"}, {\"cities\": \"2\", \"districts\": \"548, 518\"}]", "1", "Одесской", "Одесской", "1", "true", "1", " ", "Я не уверен, что вы этого хотите, но это может вам помочь.\n1. Более быстрый, но ограниченный вариант (зависит от количества данных), только если вы уверены в сигнатуре объектов, порядок свойств важен:", "1", "1", "https:\/\/www.youtube.com\/watch?v=N_swnvNug10", "\"2_25, 3_26, 4_26, 5_26, 6_26, 6_34, 5_34, 5_33, 4_33, 3_34\"", "50, 12"]
        //     ]
        // };
        // dispatch(selectTutors(data.tutors))
        // console.log(data)
        // dispatch(message(data.message))
    }
}
const selectTutors = userObj => ({
    type: 'SELECT_TUTORS',
    payload: userObj
})

export const postTutorOrder = obj => {

    console.log("start postTutorOrder")
    console.log(JSON.stringify({ obj }))
    return dispatch => {

        return fetch(fetch_url + "/handler/tutor_order.php", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify({ obj })
        })
            .then(resp => resp.json())
            .then(data => {
                if (data.error) {
                    // console.log(data.message)
                    dispatch(error(data.error))
                    //Тут прописываем логику
                } else {
                    console.log(data)
                    dispatch(getOrder(data.order))
                    dispatch(message(data.message))
                }
            })
            .catch(err => {
                console.log(err)
                if (err) dispatch(error("error.server_error"))
            })

        // let data={"order": obj.unixTime, "message":"ok"}
        // // dispatch(getOrder(data.order))
        // dispatch(message(data.message))
        // function sss(){
        //   console.log("load")
        //   dispatch(getOrder(data.order))
        // }
        // window.setTimeout(sss, 5000)
    }
}
export const postTutorOrderText = obj => {

    console.log("start postTutorOrder")
    console.log(JSON.stringify({ obj }))
    return dispatch => {

        return fetch(fetch_url + "/handler/tutor_order_text.php", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify({ obj })
        })
            .then(resp => resp.json())
            .then(data => {
                if (data.error) {
                    // console.log(data.message)
                    dispatch(error(data.error))
                    //Тут прописываем логику
                } else {
                    console.log(data)
                    dispatch(getOrder(data.order))
                    dispatch(message(data.message))
                }
            })
            .catch(err => {
                console.log(err)
                if (err) dispatch(error("error.server_error"))
            })

        // let data={"order": 123,"message":"ok"}
        // dispatch(getOrder(data.order))
        // dispatch(message(data.message))
    }
}
const getOrder = id => ({
    type: 'USER_ORDERS',
    payload: id
})

// export const userPostForgot = user => {
//     return dispatch => {
//         return fetch(fetch_url + "/handler/forgot.php", {
//             method: "POST",
//             headers: {
//                 'Content-Type': 'application/json',
//                 Accept: 'application/json',
//             },
//             body: JSON.stringify({ user })
//         })
//             .then(resp => resp.json())
//             .then(data => {
//                 if (data.error) {
//                     //Тут прописываем логику
//                 } else {
//                     //localStorage.setItem("token", data.jwt)
//                     //dispatch(loginUser(data.user))
//                 }
//             })
//             .catch(err => {
//                 console.log(err)
//                 if (err) dispatch(error("error.server_error"))
//             })
//     }
// }

export const userEditPassFetch = user => {
    return dispatch => {
        return fetch(fetch_url + "/handler/edit_pass.php", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify({ user })
        })
            .then(resp => resp.json())
            .then(data => {
                if (data.error) {
                    dispatch(error(data.error))
                    //тут ваша логика
                } else {
                    dispatch(message(data.message))
                }
            })
            .catch(err => {
                console.log(err)
                if (err) dispatch(error("error.server_error"))
            })
    }
}

export const getProfileFetch = (token = localStorage.token) => {
    return dispatch => {
        if (token) {
            return fetch(fetch_url + "/handler/profile.php?auth=" + token + "", {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    'auth': `${token}`
                }
            })
                .then(resp => resp.json())
                .then(data => {
                    if (data.error) {
                        // Будет ошибка если token не дествительный
                        localStorage.removeItem("token")
                    } else {
                        console.log("data.user")
                        console.log(data.user)
                        dispatch(loginUser(data.user))
                        dispatch(message(data.message))
                    }
                })
                .catch(err => {
                    dispatch(error("error.server_error"))
                })
        }
    }
}

export const logoutUser = () => ({
    type: 'LOGOUT_USER'
})

export const switchMenu = () => ({
    type: 'SWITCH_MENU'
})

export const clearMessage = user => {
    return dispatch => {
        dispatch(error(''))
        dispatch(message(''))
    }
}
const error = userObj => ({
    type: 'ERROR',
    payload: userObj
})
const message = userObj => ({
    type: 'MESSAGE',
    payload: userObj
})
