import React, { Component } from 'react';
import constants from '../Constants';
import Canonical from '../Canonical';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import SelectSearch from '../SelectSearch';
import { userSelectSearch, getSelectTutors } from '../redux/actions';

function time(unixTimeStamp) {
    let date = new Date(+unixTimeStamp);
    return ('0' + date.getDate()).slice(-2) + '.' + ('0' + (date.getMonth() + 1)).slice(-2) + '.' + date.getFullYear() + ' ' + ('0' + date.getHours()).slice(-2) + ':' + ('0' + date.getMinutes()).slice(-2);
}
function findGetParameter(parameterName) {
    var result = "",
        tmp = [];
    window.location.search
        .substr(1)
        .split("&")
        .forEach(function (item) {
            tmp = item.split("=");
            if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
        });
    return result;
}

class Chat extends Component {
    constructor(props) {
        super(props);
    }
    state = {
        timer: 0,
        message: '',
        num: 0,
        chat: [],
        from: [],
        error: '',
        scroll: true
    }

    getIntl = (id) => {
        const { intl } = this.props;
        return intl.formatMessage({ id: id })
    }
    handleTextareaChange = e => {
        var value = e.target.value;
        var obj = e.target.name
        this.setState({ [obj]: value });
    }
    handleSend = () => {
        if (!this.state.message) return false;
        const fetch_url = constants.location;
        const obj = {
            "jwt": this.props.currentUser.jwt,
            "from": this.props.currentUser.id,
            "to": findGetParameter("id"),
            "message": this.state.message
        }
        fetch(fetch_url + "/handler/post_chat.php", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify({ obj })
        })
            .then(resp => resp.json())
            .then(data => {
                if (data.error) {
                    console.log(data.error)
                } else {
                    console.log(data)
                    this.setState({ message: '' })
                    this.getChat();
                    this.setState({ scroll: true })
                }
            }).catch(err => { console.log(err) })
    }
    getChat = () => {
        console.log('Hello, World!')
        const fetch_url = constants.location;
        const obj = {
            "jwt": this.props.currentUser.jwt,
            "from": this.props.currentUser.id,
            "to": findGetParameter("id"),
            "num": this.state.num
        }
        fetch(fetch_url + "/handler/get_chat.php", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify({ obj })
        })
            .then(resp => resp.json())
            .then(data => {
                if (data.error) {
                    console.log(data.error)
                    this.setState({ error: data.error })
                } else {
                    console.log(data)
                    if (data.chat) {
                        console.log(data)
                        let chat = this.state.chat.concat(data.chat);
                        // let chat = data.chat.concat(this.state.chat);
                        this.setState({ num: data.num, chat: chat, from: data.from })
                    } else {
                        this.setState({ from: data.from })
                    }
                }
            }).catch(err => { console.log(err) })
    }
    replacer = (e) => {
        e = e.replace(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gm, ' email ');//(?<!\.)
        // e.replace(/(\+{0,})(\d{0,})([(]{1}\d{1,3}[)]{0,}){0,}(\s?\d+|\+\d{2,3}\s{1}\d+|\d+){1}[\s|-]?\d+([\s|-]?\d+){1,2}(\s){0,}/gm, ' phone ');
        e = e.replace(/\+?[0-9][0-9()-\s+.]{4,20}[0-9()-\s+.]/gm, ' phone ');
        return e;
    }
    messages = () => {
        return this.state.chat?.map((o, a) =>
            <div className={"message_card message_" + ((o.from == this.props.currentUser.id) ? "right" : "left") + ((!o.status) ? " nowrite" : "")}>
                <small>{time(o.time * 1000)}</small>
                <p>{constants.renderHTML(this.replacer(o.message))}</p>
            </div>)
        // from: "53"
        // id: "2"
        // message: "Hello!"
        // status: "0"
        // time: "1636901923"
        // to: "65"
    }
    getTimerChat = () => {
        let i = +this.state.timer + 1
        this.setState({ timer: i })
        console.log("timer=" + i);
        this.getChat()
    }
    timer = null;
    componentDidMount() {
constants.setHeader()
        this.getChat()
        this.timer = setInterval(
            () => this.getTimerChat(),
            8000
        );
    }

    componentDidUpdate() {
        if (this.state.scroll) {
            this.scrollToBottom()
            this.setState({ scroll: false })
        }
    }

    scrollToBottom() {
        //     const messages = document.getElementById('textdivmessages');
        //     let shouldScroll = messages.scrollTop + messages.clientHeight === messages.scrollHeight;
        //     if (!shouldScroll) { messages.scrollTop = messages.scrollHeight; }
        //     // this.el.scrollIntoView({ behavior: 'smooth' });
        let e = setTimeout(() => {
            this.el?.scrollIntoView({ block: "end", behavior: "auto" });
        }, 100);

    }
    componentWillUnmount() {
        clearInterval(this.timer);
    }
    render() {
        console.log("render chat")
        console.log(this.state)

        if (this.state?.from?.id === undefined) {
            return <div className="order_card"><FormattedMessage id="data_not_found" /></div>
        }

        return (<>
            <div className="order_card_fixed">
                <div className="order_card order_card_fixed_inner message_box">
                    <div className="profile_chat">
                        <Link to={`/${constants.locale}/profile/id${this.state?.from?.id}`} >
                            <span className="avatar_block">
                                <div className="avatar_preview" style={{ backgroundImage: "url('" + this.state?.from?.photo + "')" }} alt={this.state?.from?.name + ' ' + this.state?.from?.lastname} />
                                {this.state?.from?.name + ' ' + this.state?.from?.lastname}
                            </span>
                        </Link>
                        <span className="close_button" onClick={this.props.clickTab?.bind(this, 'chats', '')}></span>
                    </div>
                    <div className="message_block" id="chatdiv">
                        <div id="textdivmessages" className="message_block_inner" >
                            {(this.state.chat.length > 0) ? this.messages() : <div className="order_card"><FormattedMessage id="data_not_found" /></div>}
                        </div>
                        <div ref={el => { this.el = el; }} />
                    </div>
                    <FormattedMessage id={'write_message'}>
                        {(msg) => (<textarea className="text_area"
                            type="text"
                            rows="2"
                            name="message"
                            maxLength="500"
                            required
                            placeholder={msg}
                            value={this.state.message}
                            onChange={this.handleTextareaChange.bind(this)}
                            tabIndex="0"
                            onKeyDown={e => {
                                console.log("e.keyCode: " + e.keyCode);
                                if ((e.ctrlKey || e.metaKey) && (e.keyCode == 13 || e.keyCode == 10)) {
                                    e.preventDefault(); this.setState({ message: this.state.message + "\n" })
                                } else if (e.keyCode == 13 || e.keyCode == 10) {
                                    console.log("handleSend")
                                    e.preventDefault(); 
                                    this.handleSend();
                                }
                            }
                            }
                        />)}
                    </FormattedMessage>
                    {/* 
                    
                    $('textarea#streamWriter').keydown(function (e) {
    if (e.keyCode === 13 && e.ctrlKey) {
        //console.log("enterKeyDown+ctrl");
        $(this).val(function(i,val){
            return val + "\n";
        });
    }
}).keypress(function(e){
    if (e.keyCode === 13 && !e.ctrlKey) {
        alert('submit');
        return false;  
    } 
});


onKeyPress={e => {
  if(e.key === 'Enter')
     e.preventDefault()
  }}
                    
                    
                    SELECT
    `id`,
    `time`,
    `status`,
    `from`,
    `from_name`,
    `to`,
    `to_name`,
    `message`
FROM
    `chat`
WHERE
    1 */}

                    <div className="row_wrap_space-between">
                        <Link to={`/${constants.locale}/profile/id${this.state?.from?.id}`} >
                            {(this.state?.from?.photo) ?
                                <div className="avatar_preview" style={{ backgroundImage: "url('" + this.state?.from?.photo + "')" }} alt={this.state?.from?.name + ' ' + this.state?.from?.lastname} /> :
                                <img className="avatar_preview" src={`${constants.location}/images/users_info_box_no_images-min.png`} />}
                        </Link>
                        <button className="b_btn btn-green" onClick={this.handleSend.bind(this)}><FormattedMessage id="send" /></button>
                        <Link to={`/${constants.locale}/profile/id${this.props.currentUser.id}`} >
                            {(this.props.currentUser.avatar.src) ?
                                <div className="avatar_preview" style={{ backgroundImage: "url('" + this.props.currentUser.avatar.src + "')" }} /> :
                                <img className="avatar_preview" src={`${constants.location}/images/users_info_box_no_images-min.png`} />}
                        </Link>
                    </div>
                </div>
            </div>
        </>)
    }
}
const mapStateToProps = state => ({
    currentUser: state.currentUser
})

const mapDispatchToProps = dispatch => ({
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Chat));