import React, {Component} from 'react';
import constants from '../Constants';
import Canonical from '../Canonical';
import {FormattedMessage, injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import SelectSearch from '../SelectSearch';
import {userSelectSearch} from '../redux/actions';

class InputCheck extends Component {  
    constructor(props) {
      super(props);
    }
    state = {
      selected: this.props.currentUser[this.props.name+"_bool"]?true:false,
      value: (this.props.currentUser[this.props.name]===null)?'':(!isNaN(this.props.currentUser[this.props.name])&&this.props.currentUser[this.props.name]!=='')?(this.props.intl.formatMessage({id: this.props.name+'.'+this.props.currentUser[this.props.name]}) ):this.props.currentUser[this.props.name]
    }
    setData(id, key, val){
        var object = this.props.currentUser;
        object[key] = val
        // object[this.props.object] = selected
        this.props.userSelectSearch(object);
        console.log(">>>>>>> this.props.userSelectSearch(object)")
        console.log(this.props.currentUser)
    }
    handleCheck = event => {
        let selected = event.target.checked
        this.setState({
            selected: selected,
            value: (this.props.currentUser[this.props.name]===null)?'':(!isNaN(this.props.currentUser[this.props.name])&&this.props.currentUser[this.props.name]!=='')?(this.props.intl.formatMessage({id: this.props.name+'.'+this.props.currentUser[this.props.name]}) ):this.props.currentUser[this.props.name]
        });
        this.setData(0, this.props.name+"_bool", selected);
    }
    handleInputChange = e => {
      var value = e.target.value;
      this.setState({value: value});
      this.setData(0, this.props.name, value);
    }
    render (){
        const intl = this.props.intl;
      return (
        <>
          {!this.props.selectHide?(
              this.state.selected?(
                <input className="profile-field_input" 
                    type="text" 
                    name={this.props.name} 
                    placeholder={intl.formatMessage({id: this.props.name+'_placeholder'})} 
                    value={this.state.value}
                    onChange={this.handleInputChange}
                    maxLength="200"
                    required
                    />
                ):(
                    <SelectSearch title={this.props.name+"_placeholder"} object={this.props.name} ids={this.props.ids} />
                )
          ):""}
          <label className="checkbox_container">
            <FormattedMessage id={this.props.name+"_checkbox"}/>
            <input type="checkbox" hidden onChange={this.handleCheck} checked={this.state.selected}/>
            <span className="checkmark"></span>
          </label>
          {(this.state.selected && this.props.selectHide)?(<>
            
            {(this.props.name=="myplace")?<><h3><FormattedMessage id="tutorcity_title" />:</h3>
            <SelectSearch title="app.where" object="cities" level="districts" ids="0" />
            <i className="input_confirm">{this.state.validProps?.cities}</i></>:''}

          <input className="profile-field_input" 
            type="text" 
            name={this.props.name} 
            placeholder={intl.formatMessage({id: this.props.name+'_placeholder'})} 
            value={this.state.value}
            onChange={this.handleInputChange}
            maxLength="200"
            required
            />
          </>):''}
        </>
      )
    }
  }
  const mapStateToProps = state => ({
    currentUser: state.currentUser
  })
  
  const mapDispatchToProps = dispatch => ({
    userSelectSearch: object => dispatch(userSelectSearch(object))
  })
  
  export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(InputCheck));