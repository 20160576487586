import React from 'react';
import { Helmet } from "react-helmet";



const constants = (function () {
    function getPathLocale() {
        let path = window.location.pathname,
            search = window.location.search,
            hash = window.location.hash,
            regex = /^\/([^?\/]+)\/?(.*)/,
            defaultLocale = localStorage['locale'] ? localStorage['locale'] : 'uk';
        let str = (path !== '/') ? path.match(regex)[1] : "";
        let path_str = (path !== '/') ? path.match(regex) : "";
        console.log("!~path_str: ", path_str);
        console.log("~~~getPath: ", path, " search: ", search, " hash: ", hash, " str: ", str)
        return (['en', 'ru', 'uk'].includes(str)) ? str : (
            console.log("~~set /" + defaultLocale + path),
            window.location = "/" + defaultLocale + path + search + hash,
            defaultLocale
        );
    }
    const canonical_get = async () => {
        let json = {};
        // const pathname = window.location.pathname;
        // console.log("pathname: ", pathname);
        // // return json;
        // const fetch_url = constants.location;
        // var canonical = document.querySelector("link[rel='canonical']").getAttribute("href") || document.querySelector("link[rel='canonical']").href;
        // console.log("set canonical:" + (canonical !== fetch_url + pathname) + " ", canonical, " == ", fetch_url + pathname);
        // if (canonical !== fetch_url + pathname) {
        //     let response = await fetch(fetch_url + "/handler/canonical_get.php?url=" + pathname);
        //     if (response.ok) {
        //         json = await response.json();
        //     } else {
        //         console.log("Ошибка HTTP: " + response.status);
        //     }
        //     if (json?.url) {
        //         <Helmet>
        //             <meta charSet="utf-8" />
        //             <title>{json?.canonical?.title}</title>
        //             <link rel="canonical" href={window.location.protocol + '//' + window.location.host + window.location.pathname} />
        //             <meta name="description" content={json?.canonical?.description} />
        //         </Helmet>
        //         return json;
        //     }
        // }
        return json;
        // var link = !!document.querySelector("link[rel='canonical']") ? document.querySelector("link[rel='canonical']") : document.createElement('link');
        // link.setAttribute('rel', 'canonical');
        // link.setAttribute('href', window.location.protocol + '//' + window.location.host + window.location.pathname);
        // console.log("~~~setHeader: ", window.location.protocol + '//' + window.location.host + window.location.pathname)
        // document.head.appendChild(link);
    };
    async function canonical_set(obj) {
        //  const obj = {
        //     "jwt": e.jwt,
        //     "canonical":e.canonical,
        //     "title":e.title, 
        //     "description":e.description
        // }

        let json = {};
        // const fetch_url = constants.location;
        // let response = await fetch(fetch_url + "/handler/canonical_set.php", {
        //     method: "POST",
        //     headers: {
        //         'Content-Type': 'application/json',
        //         Accept: 'application/json',
        //     },
        //     body: JSON.stringify({ obj })
        // });
        // if (response.ok) {
        //     json = await response.json();
        // } else {
        //     console.log("Ошибка HTTP: " + response.status);
        // }
        return json;
    }


    // let dataTranslate = async () => {
    //     console.log("dataTranslate start: ");
    //     const res = await fetch("https://test.goal.org.ua/handler/dataTranslate.json"
    //     //, {
    //         // method: "POST",
    //         // headers: {
    //         //     'Content-Type': 'application/json',
    //         //     Accept: 'application/json',
    //         // },
    //         // body: JSON.stringify({ e })
    //     // }
    //     );
    //     const data = await res.json();
    //     console.log("dataTranslate data: ", data);
    //     if (data) {
    //         console.log("dataTranslate ok: ", data);
    //         return data
    //     } else {
    //         console.log("dataTranslate error: ", data);
    //         return {};
    //     }

    // }
    async function data_Translate() {
        let json = {};

        console.log("dataTranslate start ");
        const fetch_url = window.location.protocol + "//" + window.location.host;
        let response = await fetch("https://test.goal.org.ua/handler/dataTranslate.json"//fetch_url + "/handler/dataTranslate.json"
        
        );
        if (response.ok) {
            json = await response.json();
            console.log("dataTranslate: ", json, response);
        } else {
            console.log("dataTranslate Ошибка HTTP: ", response);
        }
        console.log("dataTranslate end ", json);
        return json;
    };

    console.log("~~~getPathLocale: ", getPathLocale(), " intl ")
    return {
        location: window.location.protocol + "//" + window.location.host,//"https://goal.org.ua",//window.location.protocol + "//" + window.location.host,
        baseLocale: '/:locale(en|ru|uk)?',
        locale: getPathLocale(),
        renderHTML: (rawHTML) => React.createElement("span", { dangerouslySetInnerHTML: { __html: rawHTML } }),
        setHeader: canonical_get,
        canonical_set: canonical_set(),
        dataTranslate: data_Translate(),
        replacer: (e) => {
            console.log("replacer: ",e);
            e = e.replace(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gm, ' ');//(?<!\.)
            // e.replace(/(\+{0,})(\d{0,})([(]{1}\d{1,3}[)]{0,}){0,}(\s?\d+|\+\d{2,3}\s{1}\d+|\d+){1}[\s|-]?\d+([\s|-]?\d+){1,2}(\s){0,}/gm, ' phone ');
            // e = e.replace(/\+?[0-9][0-9()-\s+.]{4,20}[0-9()-\s+.]/gm, ' {phone} ');
            return e;
        }
    }
})();
console.log("constants.location: ", constants.location)
export default constants;