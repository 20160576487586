import React, { Component } from 'react';
import constants from '../Constants';
import Canonical from '../Canonical';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';

class PaymentDetails extends Component {
    state = {
        load: false,
        payment: '',
        paymentValids: false
    }

    handleChange = event => {
        this.setState({
            payment: event.target.value,
            paymentValids: true
        });
        console.log(this.state.payment)
    }


//     Номер карты:
// 0000 0000 0000 0000
// Назначение платежа:
// За информационные услуги по заявке №(номер заявки)


    handleSubmit = () => {
        console.log((!this.state.payment), (!this.state.paymentValids))
        if (!this.state.paymentValids) return false;
        this.setState({load: true})
        const fetch_url = constants.location;
        const obj = {
            "jwt": this.props.currentUser.jwt,
            "payment": this.state.payment
        }
        fetch(fetch_url + "/handler/post_payment.php", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify({ obj })
        })
            .then(resp => resp.json())
            .then(data => {
                if (data.error) {
                    console.log(data.error)
                } else {
                    console.log(data)
                    this.setState({ load: false, payment: data?.payment, paymentValids: false })
                }
            }).catch(err => { console.log(err) })
    }
    getPayment = () => {
        this.setState({load: true})
        const fetch_url = constants.location
        fetch(fetch_url + "/handler/get_payment.php", {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'JWT': `${this.props.currentUser.jwt}`
            }
        })
            .then(resp => resp.json())
            .then(data => {
                if (data.error) {
                    // Будет ошибка если token не дествительный
                    console.log(data.error)
                    this.setState({ formError: data.error })
                } else {
                    console.log(data)
                    this.setState({ load: false, payment: data?.payment, paymentValids: false })
                }
            })
            .catch(err => {
                console.log("err")
            })
    }

    componentDidMount() {
constants.setHeader()
        this.getPayment()
    }

    render() {
        const intl = this.props.intl;
        return (<div id="user_data">
            {(this.state.load) ? <span className="load_submitted"></span> : ''}

            <h3><FormattedMessage id="requisites" />:</h3>
              <FormattedMessage id={'payment'}>
                {(msg) => (<textarea className="profile-field_textarea"
                  type="text"
                  rows="10"
                  name="payment"
                  maxLength="500"
                  required
                  placeholder={msg}
                  value={this.state.payment}
                  onChange={this.handleChange}
                />)}
              </FormattedMessage>

            <div className="btn-wrap">
                <button onClick={this.handleSubmit} type='submit' className={`b_btn btn-green ${this.state.submitted ? "submitted" : ""}`} disabled={!this.state.paymentValids}>
                    <FormattedMessage id="save" />
                </button>
            </div>
        </div>)
    }
}

const mapStateToProps = state => ({
    currentUser: state.currentUser
})

const mapDispatchToProps = dispatch => ({})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(PaymentDetails));