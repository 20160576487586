import React, { Component } from 'react';
import constants from '../Constants';
import { injectIntl } from 'react-intl';
import ReactQuill, { Quill } from 'react-quill';
import './RichTextEditor.css';

// import 'react-quill/dist/quill.snow.css';//bubble//core//snow
// function MyComponent() {
//   const [value, setValue] = useState('');
//   return <>
//   {console.log("ReactQuill: ",value)}
//   <ReactQuill theme="snow" value={value} onChange={setValue} />
//   </>;
// }

class RichTextEditor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: this.props.value  ?? '',
        }//.replace(/\s/g,'')
    }

    modules = {
        toolbar: [
            [{ 'header': 1 }, { 'header': 2 }],
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            ['bold', 'italic', 'underline', 'strike'],
            ['blockquote', 'code-block'],
            [{ 'script': 'sub' }, { 'script': 'super' }],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }, { 'align': [] }],
            [{ 'color': [] }, { 'background': [] }],

            ['link', 'video'],//'image',

            ['clean']
        ],
    }

    formats = [
        'header',
        'bold', 'italic', 'underline', 'strike', 'blockquote', 'code', 'code-block', 'size',
        'list', 'bullet', 'indent',
        'script',
        'color', 'background',
        'align',

        'link', 'video'// 'image',
    ]
    // modules = {
    //     toolbar: [
    //       [{ 'header': [1, 2, false] }],
    //       ['bold', 'italic', 'underline','strike', 'blockquote'],
    //       [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
    //       ['link', 'image'],
    //       ['clean']
    //     ],
    //   }

    //   formats = [
    //     'header',
    //     'bold', 'italic', 'underline', 'strike', 'blockquote',
    //     'list', 'bullet', 'indent',
    //     'link', 'image'
    //   ]

    setValue = e => {
        this.setState({ value: e})
        this.props.onChange(e)
    }


    render() {
        return (
            <div className="text-editor">
                {console.log("ReactQuill: ", this.state.value)}
                <ReactQuill theme="snow"
                    value={this.state.value} onChange={this.setValue}
                    modules={this.modules}
                    formats={this.formats}>
                </ReactQuill>
            </div>
        );
    }
}

export default injectIntl(RichTextEditor);