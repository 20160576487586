import React, { Component } from 'react';
import constants from './Constants';
import Canonical from './Canonical';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import './SelectSearch.css';
import { userSelectSearch } from './redux/actions';
import PathParameter from './components/PathParameter';

// function PathParameter.find(parameterName) {
//   var result = "",
//     tmp = [];
//   window.location.search
//     .substr(1)
//     .split("&")
//     .forEach(function (item) {
//       tmp = item.split("=");
//       if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
//     });
//   return result;
// }
class SelectSearch_from_search extends Component {
  constructor(props) {
    super(props);
  }
  id = ((this.props.ids !== "" && this.props.ids !== undefined) ? this.props.ids : "search");
  id_json = (this.id !== "search") ? "_json" : "_search";
  // objData = new Object(this.props.currentUser[this.props.object+this.id_json]?this.props.currentUser[this.props.object+this.id_json]:{});
  // objData = this.objData[this.id]?this.objData[this.id]:{};
  objData = new Object(this.props.currentUser[this.props.object + this.id_json]?.[this.id]);
  object = this.props.object ? this.props.object : '';
  level = this.props.level ? this.props.level : '';

  state = {
    id: this.id,//this.props.ids?this.props.ids:0,
    query: "",
    queryNext: "",
    data: [],
    filteredData: [],
    dataNextLevel: [],
    filteredDataNextLevel: [],
    showSelect: false,
    showNextSelects: (PathParameter.find(this.props.object) !== "" && this.props.level) ? true : false,
    showNextCheckbox: (this.props.checkbox) ? true : false,
    showNextSelect: false,
    //selectedId: (PathParameter.find(this.props.object))?"":((this.objData[this.props.object+"_id"])?this.objData[this.props.object+"_id"]:""),
    // nextSelectedId: (this.props.currentUser[this.props.level+"_id"])?this.props.currentUser[this.props.level+"_id"]:"",
    // selected: (this.props.currentUser[this.props.object])?this.props.currentUser[this.props.object]:"",
    // nextSelected: (this.props.currentUser[this.props.level])?this.props.currentUser[this.props.level]:"",
    //nextSelectedId: (this.objData[this.props.level+"_id"])?this.objData[this.props.level+"_id"]:"",
    selected: (PathParameter.find(this.props.object) !== "") ? PathParameter.find(this.props.object) : '',// ((this.objData[this.props.object]+1>0) ? this.objData[this.props.object] : ""),
    nextSelected: (PathParameter.find(this.props.level) !== "") ? PathParameter.find(this.props.level) : '',// ((this.objData[this.props.level]+1>0) ? this.objData[this.props.level] : ""),
    // selected: (PathParameter.find(this.props.object)) ? PathParameter.find(this.props.object) : ((this.objData[this.props.object]) ? this.objData[this.props.object] : ""),
    // nextSelected: (PathParameter.find(this.props.level)) ? PathParameter.find(this.props.level) : ((this.objData[this.props.level]) ? this.objData[this.props.level] : ""),
    json: this.objData//(this.props.currentUser[this.props.object+this.id_json])?this.props.currentUser[this.props.object+this.id_json]:{}
  };

  handleInputChange = event => {
    const intl = this.props.intl;
    const query = event.target.value;
    // console.log("++++++++++"+query)
    // console.log("++++++++++")
    this.setState(prevState => {
      const filteredData = prevState.data.filter(element => {
        element = this.object + '.' + element;
        // console.log(element)
        // console.log(intl.formatMessage({id: element}).toLowerCase())
        return intl.formatMessage({ id: element }).toLowerCase().includes(query.toLowerCase());
      });
      return {
        query,
        filteredData
      };
    });
  };

  handleNextInputChange = event => {
    const intl = this.props.intl;
    const queryNext = event.target.value;
    this.setState(prevState => {
      const filteredDataNextLevel = prevState.dataNextLevel.filter(element => {
        //return element.toLowerCase().includes(query.toLowerCase());
        element = this.level + '.' + element;
        // console.log(element)
        // console.log(intl.formatMessage({id: element}).toLowerCase())
        return intl.formatMessage({ id: element }).toLowerCase().includes(queryNext.toLowerCase());
      });
      return {
        queryNext,
        filteredDataNextLevel
      };
    });
  };

  getData = () => {
    const object = this.props.object;
    fetch(`/data/` + object + `.json`)
      .then(response => response.json())
      .then(data => {
        var filteredData = data;
        // console.log("%%%%%%%%%%%% "+object)
        // console.log(data)
        // console.log(filteredData)
        this.setState({
          data,
          filteredData
        });
      });
  };

  setData(id, key, val) {
    var object = this.props.currentUser;
    object[key] = val
    var json = this.state.json
    if (!object[this.props.object + this.id_json]) object[this.props.object + this.id_json] = {}
    var newJson = object[this.props.object + this.id_json]
    if (!newJson[id]) newJson[id] = {}
    json[key] = val
    newJson[id] = json
    this.setState({ json: json });
    // console.log("this.state.json")
    // console.log(this.state.json)
    object[this.props.object + this.id_json] = newJson


    // object[this.props.object] = selected
    this.props.userSelectSearch(object);
    console.log("ZZZZ >>>>>>> this.props.userSelectSearch(object)")
    console.log(this.props.currentUser)
    if (typeof this.props.rerender === 'function') {
      // if (val && typeof this.props.rerender === 'function') {
      this.props.rerender(object)
      console.log("ZZZZ 1 >>>>>>> this.props.rerender()")
    } else {
      console.log("ZZZZ 2 >>>>>>> this.props.rerender()")
    }

  }
  openSelect = () => {
    this.setState({ showSelect: !this.state.showSelect });
    // console.log(this.data)
  }
  openNextSelect = () => {
    this.setState({ showNextSelect: !this.state.showNextSelect });
  }
  chooseSelect = (e) => {
    var selected = e.target.getAttribute("data-val");
    this.setState({ selected: selected });
    this.setState({ nextSelected: '' });
    this.setState({ showSelect: !this.state.showSelect });
    this.setData(this.state.id, this.props.object, selected)
    // this.setData(this.state.id, this.props.object+"_id", selectedId)
    if (this.props.level) {
      this.setData(this.state.id, this.props.level, '')
      // this.setData(this.state.id, this.props.level+"_id", '')
    }
    this.getNextLevel(selected)
  }
  chooseSelectNextLevel = (e) => {
    var selected = e.target.getAttribute("data-val");
    this.setState({ nextSelected: selected });
    this.setState({ showNextSelect: !this.state.showNextSelect });
    // var object = this.props.currentUser;
    // object[this.props.level] = selectedId
    // this.props.userSelectSearch(object);
    this.setData(this.state.id, this.props.level, selected)
  }
  toggleCheck(collection, item) {
    var idx = collection.indexOf(item);
    if (idx !== -1) {
      collection.splice(idx, 1);
    } else {
      collection.push(item);
    }
    collection = collection.join(', ');
    return (collection.charAt(0) == ',') ? collection.substr(2) : collection;
  }
  chooseSelectNextLevelCheck = (e) => {
    var selected = e.target.getAttribute("data-val");//this.props.intl.formatMessage({id: e.target.getAttribute("data-val")}).toLowerCase();
    var select = this.toggleCheck(this.state.nextSelected.split(', '), selected);
    this.setState({ nextSelected: select });
    // console.log("$$$$$$$$$$$$$ selected")
    // console.log(select)

    this.setData(this.state.id, this.props.level, select)
  }

  getNextLevel = (selected) => {
    // console.log("~~~~~~~~~~~~")
    // console.log((!selected && !this.props.level))
    // console.log(selected)
    // console.log(this.props.level)
    // console.log("~~~~~~~~~~~~")
    if (this.props.level) {
      // if (!selected) return false
    } else return false;

    // console.log(`+------/data/`+this.props.object+`_`+this.props.level+`.json`)
    const intl = this.props.intl;
    const level = this.props.level;
    fetch(`/data/` + this.props.object + `_` + this.props.level + `.json`)
      .then(response => response.json())
      .then(data => {
        // console.log("------data ")
        // console.log(data)
        //data = Object.values(data);
        console.log("getNextLevel = (selected) => ",selected)
        data = data[selected];//Object.keys(data);
        // console.log(data)
        if (data === undefined || data.length < 1) {
          this.setState({ showNextSelects: false }); return false
        } else {
          this.setState({ showNextSelects: true });
        };
        // console.log("data.length")
        // console.log(data.length)
        // var datas = []
        // data.forEach(function(item, i, arr) {
        //   datas.push(level+'.'+item)
        // });
        // console.log(data)
        // console.log("-=-=-=-=datas")
        // console.log(data)
        const filteredData = data.filter(element => {
          return intl.formatMessage({ id: this.level + '.' + element }).toLowerCase();
        });
        // console.log("-=-=-=-=filteredData")
        // console.log(filteredData)

        this.setState({
          dataNextLevel: data,
          filteredDataNextLevel: filteredData
        });
      });
  }
  nextSelectedCheck() {
    const intl = this.props.intl;
    var string = ''
    this.state.nextSelected.split(', ').filter((item, i, arr) => {
      string += intl.formatMessage({ id: this.level + '.' + item }) + ((arr.length > 1 + i) ? ", " : "");
    })
    return string
  }

  // componentWillMount() {
  componentDidMount() {
    constants.setHeader()
    console.log("***************************", this.objData)
    this.getData();
    this.getNextLevel(this.state.selected);
  }
  onPopState = (event) => {
    console.log("SelectSearch onpopstate")
    this.setData(this.state.id, this.props.object, PathParameter.find(this.props.object))
    this.setData(this.state.id, this.props.level, PathParameter.find(this.props.level))
    this.setState({
      selected: PathParameter.find(this.props.object),
      nextSelected: PathParameter.find(this.props.level)
    });
  }

  render() {
    window.onpopstate = (event) => {
      this.onPopState(event)
    };
    const intl = this.props.intl;

    // console.log("!this.props.level&&!this.state.selected")
    // console.log(!this.props.level||!this.state.selected)
    // console.log(!this.props.level)
    // console.log(!this.state.selected)
    console.log("*********************** this.objData " + this.props.object + " - " + this.id)
    console.log(this.objData)
    return (
      <div className={"search_form " + (this.props.hide ? "hide" : "")}>
        <span onClick={this.openSelect} className={(this.state.showSelect ? "active" : "")} data-id={this.state.selected !== '' ? this.state.selected : ""} data-name={this.state.selected !== '' ? this.state.selected : ""}><FormattedMessage id={this.state.selected !== '' ? this.object + '.' + this.state.selected : this.props.title} /></span>
        <div className={"select_search_close " + (this.state.showSelect ? "show" : "hide")} onClick={this.openSelect}></div>
        <div className={"select_search " + (this.state.showSelect ? "show" : "hide")}>
          <input
            placeholder={intl.formatMessage({ id: 'app.search' })}
            value={this.state.query}
            onChange={this.handleInputChange}
          />
          <ul>
            {this.state.selected !== '' ? <li onClick={this.chooseSelect.bind(this)} data-id={''} data-val={''} ><FormattedMessage id={this.props.title} /> </li> : ""}
            {this.state.filteredData.map((key, i) => <li key={key} onClick={this.chooseSelect.bind(this)} className={(this.state.selected == key && this.state.selected !== '') ? "selected" : ""} data-id={i} data-val={key} ><FormattedMessage id={this.object + '.' + key} /> </li>)}</ul>
        </div>
        {this.state.showNextSelects ? (
          (this.state.showNextCheckbox) ? (
            <div>
              <span onClick={this.openNextSelect} className={(this.state.showNextSelect ? "active" : "")} data-id={this.state.nextSelected !== '' ? this.state.nextSelected : ""} data-name={this.state.nextSelected !== '' ? this.state.nextSelected : ""}>
                {this.state.nextSelected !== '' ? (this.nextSelectedCheck()) : (<FormattedMessage id={"app." + this.props.level} />)}
              </span>
              <div className={"select_search_close " + (this.state.showNextSelect ? "show" : "hide")} onClick={this.openNextSelect}></div>
              <div className={"select_search " + (this.state.showNextSelect ? "show" : "hide")}>
                <input
                  placeholder={intl.formatMessage({ id: 'app.search' })}
                  value={this.state.queryNext}
                  onChange={this.handleNextInputChange}
                />
                <ul>{this.state.filteredDataNextLevel.map((key, i) => <li key={key} onClick={this.chooseSelectNextLevelCheck.bind(this)} className={"checkbox " + (((this.state.nextSelected.split(', ').indexOf(key.toString())) !== -1) ? "checked" : "")} data-id={i} data-val={key} ><FormattedMessage id={this.level + '.' + key} /> </li>)}</ul>
              </div>
            </div>
          ) : (
            <div>
              <span onClick={this.openNextSelect} className={(this.state.showNextSelect ? "active" : "")} data-id={this.state.nextSelected !== '' ? this.state.nextSelected : ""} data-name={this.state.nextSelected !== '' ? this.state.nextSelected : ""}><FormattedMessage id={this.state.nextSelected !== '' ? this.level + '.' + this.state.nextSelected : ("app." + this.props.level)} /></span>
              <div className={"select_search_close " + (this.state.showNextSelect ? "show" : "hide")} onClick={this.openNextSelect}></div>
              <div className={"select_search " + (this.state.showNextSelect ? "show" : "hide")}>
                <input
                  placeholder={intl.formatMessage({ id: 'app.search' })}
                  value={this.state.queryNext}
                  onChange={this.handleNextInputChange}
                />
                <ul>
                  {this.state.nextSelected !== '' ? <li onClick={this.chooseSelectNextLevel.bind(this)} data-id={''} data-val={''} ><FormattedMessage id={"app." + this.props.level} /> </li> : ""}
                  {this.state.filteredDataNextLevel.map((key, i) => <li key={key} onClick={this.chooseSelectNextLevel.bind(this)} className={(this.state.nextSelected == key && this.state.nextSelected !== '') ? "selected" : ""} data-id={i} data-val={key} ><FormattedMessage id={this.level + '.' + key} /> </li>)}</ul>
              </div>
            </div>
          )
        ) : ''}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  currentUser: state.currentUser
})

const mapDispatchToProps = dispatch => ({
  userSelectSearch: object => dispatch(userSelectSearch(object))
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(SelectSearch_from_search));

