import React, { Component } from 'react';
import constants from '../Constants';
import Canonical from '../Canonical';
import { injectIntl } from 'react-intl';
import './PaginatedItems.css';

const svg_arrow = <svg viewBox="0 0 24 24"><path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z"></path></svg>;
const dots = "•••"

class PaginatedItems extends Component {
    click = e => {
        console.log("~Paginated Click: ", e, (+this.props.current + e))
        let page=+this.props.current + e;
        if(page===0||page>Math.ceil(this.props.total / this.props.count))return false;
        this.props.callback(+this.props.current + e)
    }
    clickNum = e => {
        if (e === dots) return false
        this.props.callback(e)
    }
    inner = () => {
        const intl = this.props.intl;
        let c = (+this.props.current) ? +this.props.current : 1,
            t = + Math.ceil(this.props.total / this.props.count),
            n = 3;

        console.log("PaginatedItems c:" + c + " t:" + t)
        if (this.props?.style === 'dots') {

            if (t > 1) {
                const t_arr = Array(t).fill(null).map((_, i) => 1 + i);
                console.log("t_arr: ", t_arr)
                if (t > 10) {
                    const numm_arr = t_arr.map(v => {
                        if (v === 1) return 1;
                        if (v === t) return t;

                        if (c < n && v < n + 3) {//12345...20
                            return v < n + 2 ? v : dots
                        }
                        if (c > t - n && v > t - (n + 3)) {//1...15 16 17 18 19 20
                            return v > t - (n + 2) ? v : dots
                        }
                        if (v < c + n && v > c - n) {
                            return v;
                        }
                        if (v === c + n || v === c - n) {
                            console.log(dots, v)
                            return dots;
                        }

                    }).filter(e => e !== undefined);

                    console.log("numm_arr: ", numm_arr)
                    return (<>
                        <div className={`pagination_prev${(c === 1) ? " disabled" : ""}`} onClick={this.click.bind(this, -1)}>{svg_arrow} </div>
                        {numm_arr?.map(i => <div className={`${(c === i) ? "current" : (i === dots) ? "dot" : ""}`} onClick={this.clickNum.bind(this, i)}>{i}</div>)}
                        <div className={`pagination_next${(c === t) ? " disabled" : ""}`} onClick={this.click.bind(this, +1)}>{svg_arrow} </div>
                    </>)

                } else {
                    return t_arr?.map(i => <div className={`${(c === i) ? " current" : ""}`} onClick={this.clickNum.bind(this, i)}>{i}</div>)
                }
            }

        } else if (this.props?.style === 'more') {
            if (c < t) return (<div onClick={this.click.bind(this, +1)} className={"pagination_more"}>{svg_arrow}{intl.formatMessage({ id: "pagination_more" })}</div>)
        } else if (t <= 1) {
            return ('')
        } else if (c === 1) {
            return (<div onClick={this.click.bind(this, +1)} className="pagination_next">{intl.formatMessage({ id: "pagination_next" })}{svg_arrow}</div>);
        } else if (c >= t) {
            return (<>
                <div onClick={this.click.bind(this, -1)} className="pagination_prev">{intl.formatMessage({ id: "pagination_prev" })}</div>
                <div className="pagination_cuppent">{intl.formatMessage({ id: "pagination_page" })} {this.props.current}</div>
            </>);
        } else if (c < t) {
            return (<>
                <div onClick={this.click.bind(this, -1)} className={"pagination_prev"}>{svg_arrow}</div>
                <div className="pagination_cuppent">{intl.formatMessage({ id: "pagination_page" })} {this.props.current}</div>
                <div onClick={this.click.bind(this, +1)} className={"pagination_next"}>{svg_arrow}</div>
            </>);
        }
    }
    render() {
        if (this.props.total > this.props.count)
            return (
                <div className={`pagination_container ${this.props?.style}`}>
                    {this.inner()}
                </div>
            )
        return ('')
    }
}
export default injectIntl(PaginatedItems);