import React, { Component } from 'react';
import constants from './Constants';
import Canonical from './Canonical';
import { IntlProvider, FormattedMessage } from 'react-intl';
import { BrowserRouter as Router, Switch, NavLink, Link, Route, useRouteMatch } from 'react-router-dom';
import { connect } from 'react-redux';
import './Main.css';


class Main extends Component {
  componentDidMount = () => {
  }
  render() {
    return (
      <div className="main_wrapper">

        <div className="header_image_block">
          <figure>
            <img src={`${constants.location}/images/header_1.svg`} alt="" />
            <figcaption>
              <FormattedMessage id="app.online_lesson" />
            </figcaption>
          </figure>
          <figure>
            <img src={`${constants.location}/images/header_2.svg`} alt="" />
            <figcaption>
              <FormattedMessage id="app.personal_lesson" />
            </figcaption>
          </figure>
        </div>

        <div className="index_main_block">
          <div className="why-wrapper">
            <div className="why-content">
              <p className="why-item">
                <span>
                  <FormattedMessage id="app.main.about_1" /></span>
              </p>
              <p className="why-item">
                <span>
                  <FormattedMessage id="app.main.about_2" /></span>
              </p>
            </div>
          </div>

          <h1><FormattedMessage id="app.how_it_works" /></h1>

          <div className="how-wrapper">
            <figure>
              <img src={`${constants.location}/images/how_it_work_1.svg`} alt="" />
              <figcaption>
                <FormattedMessage id="app.how_it_work_text_1" />
              </figcaption>
            </figure>
            <figure>
              <img src={`${constants.location}/images/how_it_work_2.svg`} alt="" />
              <figcaption>
                <FormattedMessage id="app.how_it_work_text_2" />
              </figcaption>
            </figure>
            <figure>
              <img src={`${constants.location}/images/how_it_work_3.svg`} alt="" />
              <figcaption>
                <FormattedMessage id="app.how_it_work_text_3" />
              </figcaption>
            </figure>
          </div>
        </div>

        <div className="index_main_block">
          <div className="why-wrapper">
            <h2 className="heading"><FormattedMessage id="app.main.why" /></h2>
            <div className="why-content">
              <span className="why-item">
                <span>
                  <h2><FormattedMessage id="app.main.why.title_1" /></h2>
                  <FormattedMessage id="app.main.why.text_1" /></span>
              </span>
              <span className="why-item">
                <span>
                  <h2><FormattedMessage id="app.main.why.title_2" /></h2>
                  <FormattedMessage id="app.main.why.text_2" /></span>
              </span>
              <span className="why-item">
                <span>
                  <h2><FormattedMessage id="app.main.why.title_3" /></h2>
                  <FormattedMessage id="app.main.why.text_3" /></span>
              </span>
              <span className="why-item">
                <span>
                  <h2><FormattedMessage id="app.main.why.title_4" /></h2>
                  <FormattedMessage id="app.main.why.text_4" /></span>
              </span>
            </div>
            <h2 className="heading"><FormattedMessage id="app.main.find_tutor" /></h2>
            <a className="search-button_green" href="#root"><FormattedMessage id="app.main.search" /></a>
          </div>
        </div>

        <div className="index_main_block">
          <h2 className="about_text"><FormattedMessage id="app.main.about_text" /></h2>
          <div className="earn-with-us">

            <figure>
              <img src={`${constants.location}/images/earn-with-us.svg`} alt="" />
              <figcaption>
                <h3 className="heading"><FormattedMessage id="app.main.earn-title" /></h3>
                <a className="search-button_green" href={`/${constants.locale}/register`}><FormattedMessage id="app.main.earn-button" /></a>
                <div className="text-wrapper">
                  <p>
                    <FormattedMessage id="app.main.earn-text_1" /><br />
                    <FormattedMessage id="app.main.earn-text_2" /><br />
                    <FormattedMessage id="app.main.earn-text_3" />
                  </p>
                </div>
              </figcaption>
            </figure>

            {/* <div className="earn-text">
                <h3 className="heading"><FormattedMessage id="app.main.earn-title" /></h3>
                <a className="search-button_green" href="#block_9240"><FormattedMessage id="app.main.earn-button" /></a>
                <div className="text-wrapper">
                  <p>
                    <FormattedMessage id="app.main.earn-text_1" /><br />
                    <FormattedMessage id="app.main.earn-text_2" /><br />
                    <FormattedMessage id="app.main.earn-text_3" />
                  </p>
                </div>
              </div>
              <div className="register-img"></div> */}
          </div>
        </div>
        <Canonical seo={true} />
        {/* 
        <div className="main_wrapper_block">
          <div className="main_wrapper_block_content">

          </div>
        </div> */}



      </div>
    );
  }
}



const mapStateToProps = state => ({
  currentUser: state.currentUser
  //currentUser: state.reducer.currentUser
})

const mapDispatchToProps = dispatch => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(Main);

