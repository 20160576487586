import React, { Component } from 'react';
import constants from '../Constants';
import Canonical from '../Canonical';
import { FormattedMessage, injectIntl, IntlProvider } from 'react-intl';
import { connect } from 'react-redux';
import DocumentMeta from 'react-document-meta';

class PrivacyPolicy extends Component {
        constructor(props) {
                super(props);
        }
        componentDidMount() {
                // constants.setHeader()
        }
        render() {
                const meta = {
                        title: this.props.intl.formatMessage({ id: 'document.title.privacy_policy' }),
                        canonical: constants.location + window.location.pathname,
                        meta: {
                                charset: 'utf-8',
                                name: {
                                        robots: "noindex",
                                        googlebot: "noindex",
                                        keywords: '',
                                }
                        }
                };
                return (
                        <div className="main_wrapper">
                                <DocumentMeta {...meta} />
                                <div className="main_wrapper_block">
                                        <div className="main_wrapper_block_content_stretch public_text">
                                                <h1><FormattedMessage id="privacy_policy" /></h1>
                                                <p><FormattedMessage id="privacy.0" /></p>
                                                <h2><FormattedMessage id="privacy.1" /></h2>
                                                <p><FormattedMessage id="privacy.1.1" /></p>
                                                <p><FormattedMessage id="privacy.1.1.1" /></p>
                                                <p><FormattedMessage id="privacy.1.1.2" /></p>
                                                <p><FormattedMessage id="privacy.1.1.3" /></p>
                                                <p><FormattedMessage id="privacy.1.1.4" /></p>
                                                <p><FormattedMessage id="privacy.1.1.5" /></p>
                                                <p><FormattedMessage id="privacy.1.1.6" /></p>
                                                <h2><FormattedMessage id="privacy.2" /></h2>
                                                <p><FormattedMessage id="privacy.2.1" /></p>
                                                <p><FormattedMessage id="privacy.2.2" /></p>
                                                <p><FormattedMessage id="privacy.2.3" /></p>
                                                <p><FormattedMessage id="privacy.2.4" /></p>
                                                <h2><FormattedMessage id="privacy.3" /></h2>
                                                <p><FormattedMessage id="privacy.3.1" /></p>
                                                <p><FormattedMessage id="privacy.3.2" /></p>
                                                <p><FormattedMessage id="privacy.3.2.1" /></p>
                                                <p><FormattedMessage id="privacy.3.2.2" /></p>
                                                <p><FormattedMessage id="privacy.3.2.3" /></p>
                                                <p><FormattedMessage id="privacy.3.2.4" /></p>
                                                <p><FormattedMessage id="privacy.3.2.5" /></p>
                                                <p><FormattedMessage id="privacy.3.2.6" /></p>
                                                <h2><FormattedMessage id="privacy.4" /></h2>
                                                <p><FormattedMessage id="privacy.4.1" /></p>
                                                <p><FormattedMessage id="privacy.4.1.1" /></p>
                                                <p><FormattedMessage id="privacy.4.1.2" /></p>
                                                <p><FormattedMessage id="privacy.4.1.3" /></p>
                                                <p><FormattedMessage id="privacy.4.1.4" /></p>
                                                <p><FormattedMessage id="privacy.4.1.5" /></p>
                                                <p><FormattedMessage id="privacy.4.1.6" /></p>
                                                <p><FormattedMessage id="privacy.4.1.7" /></p>
                                                <h2><FormattedMessage id="privacy.5" /></h2>
                                                <p><FormattedMessage id="privacy.5.1" /></p>
                                                <p><FormattedMessage id="privacy.5.2" /></p>
                                                <p><FormattedMessage id="privacy.5.3" /></p>
                                                <p><FormattedMessage id="privacy.5.4" /></p>
                                                <p><FormattedMessage id="privacy.5.5" /></p>
                                                <p><FormattedMessage id="privacy.5.6" /></p>
                                                <h2><FormattedMessage id="privacy.6" /></h2>
                                                <p><FormattedMessage id="privacy.6.1" /></p>
                                                <p><FormattedMessage id="privacy.6.1.1" /></p>
                                                <p><FormattedMessage id="privacy.6.1.2" /></p>
                                                <p><FormattedMessage id="privacy.6.2" /></p>
                                                <p><FormattedMessage id="privacy.6.2.1" /></p>
                                                <p><FormattedMessage id="privacy.6.2.2" /></p>
                                                <p><FormattedMessage id="privacy.6.2.3" /></p>
                                                <p><FormattedMessage id="privacy.6.2.4" /></p>
                                                <h2><FormattedMessage id="privacy.7" /></h2>
                                                <p><FormattedMessage id="privacy.7.1" /></p>
                                                <p><FormattedMessage id="privacy.7.2" /></p>
                                                <p><FormattedMessage id="privacy.7.2.1" /></p>
                                                <p><FormattedMessage id="privacy.7.2.2" /></p>
                                                <p><FormattedMessage id="privacy.7.2.3" /></p>
                                                <h2><FormattedMessage id="privacy.8" /></h2>
                                                <p><FormattedMessage id="privacy.8.1" /></p>
                                                <p><FormattedMessage id="privacy.8.2" /></p>
                                                <p><FormattedMessage id="privacy.8.3" /></p>
                                                <p><FormattedMessage id="privacy.8.4" /></p>
                                                <h2><FormattedMessage id="privacy.9" /></h2>
                                                <p><FormattedMessage id="privacy.9.1" /></p>
                                                <p><FormattedMessage id="privacy.9.2" /></p>
                                                <p><FormattedMessage id="privacy.9.3" /></p>
                                                <p><FormattedMessage id="privacy.9.4" /></p>
                                        </div>
                                </div>
                        </div>
                );
        }
}

const mapStateToProps = state => ({
        currentUser: state.currentUser
})

const mapDispatchToProps = dispatch => ({
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(PrivacyPolicy));



