import React, { Component } from 'react';
import constants from '../Constants';
import Canonical from '../Canonical';
import { IntlProvider, FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router-dom';

function time(unixTimeStamp) {
    let date = new Date(+unixTimeStamp);
    return ('0' + date.getDate()).slice(-2) + '.' + ('0' + (date.getMonth() + 1)).slice(-2) + '.' + date.getFullYear() + ' ' + ('0' + date.getHours()).slice(-2) + ':' + ('0' + date.getMinutes()).slice(-2);
}
function findGetParameter(parameterName) {
    var result = "",
        tmp = [];
    window.location.search
        .substr(1)
        .split("&")
        .forEach(function (item) {
            tmp = item.split("=");
            if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
        });
    return result;
}

class Managers extends Component {
    state = {
        close: [false, false, false],
        managers: [],
        selectManager: []
    }

    handleChange = event => {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleSubmit = event => {
        event.preventDefault()
        this.setState({ submitted: true })
        this.props.userPostForgot(this.state)
    }

    renderRedirect = () => {
        console.log("jwt: " + (!!this.props.currentUser.jwt) + " role: " + this.props.currentUser.role + " role!=1: " + (this.props.currentUser.role != 3))
        if (!this.props.currentUser.jwt || this.props.currentUser.role != 3) {
            console.log("<Redirect to={`/login} />")
            return <Redirect to={`/${constants.locale}/login`}
            />
        }
    }

    handleSend = () => {
        if (!this.state.message) return false;
        const fetch_url = constants.location;
        const obj = {
            "jwt": this.props.currentUser.jwt,
            "from": this.props.currentUser.id,
            "to": findGetParameter("id"),
            "message": this.state.message
        }
        fetch(fetch_url + "/handler/post_chat.php", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify({ obj })
        })
            .then(resp => resp.json())
            .then(data => {
                if (data.error) {
                    console.log(data.error)
                } else {
                    console.log(data)
                    this.setState({ message: '' })
                    this.getChat();
                    this.setState({ scroll: true })
                }
            }).catch(err => { console.log(err) })
    }
    getManagers = () => {
        const fetch_url = constants.location
        fetch(fetch_url + "/handler/get_managers.php?offset=0", {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'JWT': `${this.props.currentUser.jwt}`
            }
        })
            .then(resp => resp.json())
            .then(data => {
                if (data.error) {
                    // Будет ошибка если token не дествительный
                    console.log(data.error)
                    this.setState({ formError: data.error })
                } else {
                    console.log(data)
                    this.setState({ load: false, managers: data?.managers })
                }
            })
            .catch(err => {
                console.log("err")
            })
    }
    showPopup = (i, n) => {
        let close = this.state.close
        close[i] = !close[i]
        this.setState({ selectManager: n, close: close })
    }

    componentDidMount() {
constants.setHeader()
        this.getManagers()
    }

    render() {
        return (<>
            {this.renderRedirect()}
            <div className="container" >
                <button className="b_btn btn-green" onClick={this.showPopup.bind(this, 0)}><FormattedMessage id="add_manager" /></button>
                {(this.state.close[0]) ? <AddManager act="add" intl={this.props.intl} close={this.showPopup.bind(this, 0)} currentUser={this.props.currentUser} /> : ''}
                {(this.state.close[1]) ? <AddManager act="change" o={this.state.selectManager} intl={this.props.intl} close={this.showPopup.bind(this, 1)} currentUser={this.props.currentUser} /> : ''}

            </div>
            {(this.state.managers?.length < 1) ? <div className="order_card"><FormattedMessage id="data_not_found" /></div> : <>
                {this.state.managers?.map((o, a) => {
                    return <div className="order_card">
                        <Link to={`/${constants.locale}/profile/id${o.id}`} >
                            <span className="avatar_block">
                                <div className="avatar_preview" style={{ backgroundImage: "url('" + o.photo + "')" }} alt={o.name + ' ' + o.lastname} />
                                {o.name + ' ' + o.lastname + ' '}
                                ( <FormattedMessage id="manager" /> )
                                <Link to={"./dashboard?tab=chat&id=" + o?.id} className="new_link_users_attr" onClick={this.props.clickTab?.bind(this, 'chat', o?.id)}><FormattedMessage id="to_write" /></Link>
                            </span>
                        </Link>
                        <div className="row_wrap"><p><FormattedMessage id="cities" />:</p><p>
                             {o.city?.split(', ').map((item, i, arr) => <><FormattedMessage id={'cities.'+item} /> {(arr.length > 1 + i) ? ", " : ""}</>)} 
                        </p></div>
                        <div className="row_wrap"><p><FormattedMessage id="online" />:</p><p>{o?.online}</p></div>
                        <div className="row_wrap"><p><FormattedMessage id="email" />:</p><p>{o?.login}</p></div>
                        <div className="row_wrap"><p><FormattedMessage id="phone" />:</p><p>{o?.phone}</p></div>
{/* age: "0"
city: "0, 1, 2, 3, 4"
emailstatus: "0"
id: "72"
lastname: "Морганюк"
login: "2.3dpu..a@gmail.com"
name: "managers"
phone: "+380989802631"
photo: "../images/avatar_area_box.png"
rating: "0"
role: "2"
sex: "0"
showprofile: "0"
status: "1" */}
                        <button className="b_btn btn-green" onClick={this.showPopup.bind(this, 1, o)}><FormattedMessage id="change" /></button>
                        <div className="row_wrap_space-between"></div>
                    </div>
                })}</>}
        </>
        )
    }
}
class AddManager extends Component {
    state = {
        name: (this.props.o?.name) ? this.props.o.name : "",
        lastname: (this.props.o?.lastname) ? this.props.o.lastname : "",
        email: (this.props.o?.login) ? this.props.o.login : "",
        role: "3",
        phone: (this.props.o?.phone) ? this.props.o.phone : "",
        submitted: false,
        formErrors: { name: '', lastname: '', email: '' },
        nameValid: (this.props.o?.name) ? true : false,
        lastnameValid: (this.props.o?.lastname) ? true : false,
        emailValid: (this.props.o?.login) ? true : false,
        formValid: (this.props.o?.name && this.props.o?.login && this.props.o?.lastname) ? true : false,
        submitErrors: "",
        load: false,
        showNextSelect: false,
        nextSelected: (this.props.o?.city) ? this.props.o.city : '',
        dataNextLevel: null,
        filteredDataNextLevel: null,
        online: (this.props.o?.online) ? this.props.o?.online : false,
    }

    validateField(fieldName, value) {
        const intl = this.props.intl
        var fieldValidationErrors = this.state.formErrors;
        var nameValid = this.state.nameValid;
        var lastnameValid = this.state.lastnameValid;
        var emailValid = this.state.emailValid;
        switch (fieldName) {
            case 'name':
                nameValid = value.length >= 2;
                fieldValidationErrors.name = nameValid ? '' : intl.formatMessage({ id: "error.is_too_short" });
                break;
            case 'lastname':
                lastnameValid = value.length >= 2;
                fieldValidationErrors.lastname = lastnameValid ? '' : intl.formatMessage({ id: "error.is_too_short" });
                break;
            case 'email':
                emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) ? true : false;
                fieldValidationErrors.email = emailValid ? '' : intl.formatMessage({ id: "error.is_invalid" });
                break;
            default:
                break;
        }
        this.setState({
            formErrors: fieldValidationErrors,
            nameValid: nameValid,
            lastnameValid: lastnameValid,
            emailValid: emailValid,
            formValid: nameValid && lastnameValid && emailValid,
            submitErrors: ""
        });
    }

    handleChange = event => {
        var value = event.target.value.trim()
        this.validateField(event.target.name, value);
        this.setState({
            [event.target.name]: value,
            submitErrors: ""
        });
    }

    handleSubmit = event => {
        event.preventDefault()
        this.setState({ submitted: true })
        if (!this.state.formValid) this.setState({ submitted: false });
        if (this.state.formValid && this.props.act == "add") {
            this.setState({ load: true })
            const fetch_url = constants.location;
            const user = {
                "jwt": this.props.currentUser.jwt,
                name: this.state.name,
                lastname: this.state.lastname,
                email: this.state.email,
                password: "7777777",
                phone: this.state.phone,
                role: 2,
                online: this.state.online,
                cities: this.state.nextSelected
            }
            console.log(user);
            fetch(fetch_url + "/handler/signup.php?admin=" + this.props.currentUser.jwt, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                },
                body: JSON.stringify({ user })
            })
                .then(resp => resp.json())
                .then(data => {
                    if (data.error) {
                        console.log(data.error)
                        this.setState({ load: false, submitErrors: data.error })
                    } else {
                        console.log(data)
                        this.setState({ load: false })
                        this.close.bind(this)
                    }
                }).catch(err => { console.log("err") })
        } else if (this.props.act == "change") {
            this.setState({ load: true })
            const fetch_url = constants.location;
            const user = {
                "jwt": this.props.currentUser.jwt,
                id: this.props.id,
                name: this.state.name,
                lastname: this.state.lastname,
                email: this.state.email,
                phone: this.state.phone,
                role: 2,
                online: this.state.online,
                cities: this.state.nextSelected
            }
            fetch(fetch_url + "/handler/post_change_manager.php", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                },
                body: JSON.stringify({ user })
            })
                .then(resp => resp.json())
                .then(data => {
                    if (data.error) {
                        console.log(data.error)
                        this.setState({ load: false, submitErrors: data.error })
                    } else {
                        console.log(data)
                        this.setState({ load: false })
                        this.close.bind(this)
                    }
                }).catch(err => { console.log("err") })
        }
    }
    openNextSelect = () => {
        this.setState({ showNextSelect: !this.state.showNextSelect });
    }
    handleNextInputChange = event => {
        const intl = this.props.intl;
        const queryNext = event.target.value;
        this.setState(prevState => {
            const filteredDataNextLevel = prevState.dataNextLevel.filter(element => {
                element = 'cities' + '.' + element;
                return intl.formatMessage({ id: element }).toLowerCase().includes(queryNext.toLowerCase());
            });
            return {
                queryNext,
                filteredDataNextLevel
            };
        });
    };
    toggleCheck(collection, item) {
        var idx = collection.indexOf(item);
        if (idx !== -1) {
            collection.splice(idx, 1);
        } else {
            collection.push(item);
        }
        collection = collection.join(', ');
        return (collection.charAt(0) == ',') ? collection.substr(2) : collection;
    }
    chooseSelectNextLevelCheck = (e) => {
        var selected = e.target.getAttribute("data-val");//this.props.intl.formatMessage({id: e.target.getAttribute("data-val")}).toLowerCase();
        var select = this.toggleCheck(this.state.nextSelected.split(', '), selected);
        this.setState({ nextSelected: select });
    }
    getNextLevel = (selected) => {
        fetch(`/data/` + selected + `.json`)
            .then(response => response.json())
            .then(data => {
                var filteredData = data;
                this.setState({
                    dataNextLevel: data,
                    filteredDataNextLevel: filteredData
                });
            });
    }
    nextSelectedCheck() {
        const intl = this.props.intl;
        var string = ''
        this.state.nextSelected.split(', ').filter((item, i, arr) => {
            string += intl.formatMessage({ id: 'cities.' + item }) + ((arr.length > 1 + i) ? ", " : "");
        })
        return string
    }

    handleCheck = e => {
        var value = e.target.checked;
        var obj = e.target.name
        this.setState({ [obj]: value });
    }
    componentDidMount() {
constants.setHeader()
        this.getNextLevel('cities');
    }

    close = () => {
        this.props.close()
    }
    render() {
        const intl = this.props.intl;
        return (<form className="form_fixed" onSubmit={this.handleSubmit}>
            <span onClick={this.close.bind(this)} className="close_button"></span>
            {(this.state.load) ? <span className="load_submitted"></span> : ''}

            <div className="input-wrap">
                <input className="input"
                    id="signup-name"
                    type='text'
                    name='name'
                    placeholder='name'
                    value={this.state.name}
                    onChange={this.handleChange}
                    required
                />
                <label className="placeholder" htmlFor="signup-name"><FormattedMessage id="app.name" /></label>
                <div className="input_error">{this.state.formErrors.name}</div>
            </div>

            <div className="input-wrap">
                <input className="input"
                    id="signup-lastname"
                    type='text'
                    name='lastname'
                    placeholder='lastname'
                    value={this.state.lastname}
                    onChange={this.handleChange}
                    required
                />
                <label className="placeholder" htmlFor="signup-lastname"><FormattedMessage id="app.lastname" /></label>
                <div className="input_error">{this.state.formErrors.lastname}</div>
            </div>

            <div className="input-wrap">
                <input className="input"
                    id="signup-email"
                    type='email'
                    name='email'
                    placeholder='Email'
                    value={this.state.email}
                    onChange={this.handleChange}
                    required
                />
                <label className="placeholder" htmlFor="signup-email"><FormattedMessage id="app.email" /></label>
                <div className="input_error">{this.state.formErrors.email}</div>
            </div>

            <div className="input-wrap">
                <input className="input"
                    id="signup-phone"
                    type='phone'
                    name='phone'
                    placeholder='phone'
                    value={this.state.phone}
                    onChange={this.handleChange}
                    required
                />
                <label className="placeholder" htmlFor="signup-phone"><FormattedMessage id="app.phone" /></label>
                <div className="input_error">{this.state.formErrors.phone}</div>
            </div>

            <label className="checkbox_container">
                <FormattedMessage id={"tutorcity_check3"} />
                <input type="checkbox" hidden onChange={this.handleCheck} name="online" checked={this.state.online} />
                <span className="checkmark"></span>
            </label>

            <div className="search_form">
                <span onClick={this.openNextSelect} className={(this.state.showNextSelect ? "active" : "")} data-id={this.state.nextSelected ? this.state.nextSelected : ""} data-name={this.state.nextSelected ? this.state.nextSelected : ""}>
                    {this.state.nextSelected ? (this.nextSelectedCheck()) : (<FormattedMessage id={"app.where"} />)}
                </span>
                <div className={"select_search_close " + (this.state.showNextSelect ? "show" : "hide")} onClick={this.openNextSelect}></div>
                <div className={"select_search " + (this.state.showNextSelect ? "show" : "hide")}>
                    <input
                        placeholder={intl.formatMessage({ id: 'app.search' })}
                        value={this.state.queryNext}
                        onChange={this.handleNextInputChange}
                    />
                    <ul>{this.state.filteredDataNextLevel?.map((key, i) => <li key={key} onClick={this.chooseSelectNextLevelCheck.bind(this)} className={"checkbox " + (((this.state.nextSelected.split(', ').indexOf(key.toString())) !== -1) ? "checked" : "")} data-id={i} data-val={key} ><FormattedMessage id={'cities' + '.' + key} /> </li>)}</ul>
                </div>
            </div>

            <div className="btn-wrap">
                <button type='submit' className={`b_btn btn-green ${this.state.submitted ? "submitted" : ""}`} disabled={!this.state.formValid}>
                    <FormattedMessage id="app.tutors.register.button" />
                </button>
            </div>
            {(this.state.submitErrors) ? <p>{this.state.submitErrors}</p> : ''}
        </form>)
    }
}



const mapStateToProps = state => ({
    currentUser: state.currentUser
})

const mapDispatchToProps = dispatch => ({})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Managers));