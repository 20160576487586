import React, { Component} from 'react';
import constants from '../Constants';
import Canonical from '../Canonical';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { userSignupFetch } from '../redux/actions';
import UserRedirect from './UserRedirect';
import { Link } from 'react-router-dom';

class Signup extends Component {

  state = {
    username: "",
    email: "",
    password: "",
    password_confirmation: "",
    role: "0",
    submitted: false,
    pass_toggle: {},


    formErrors: { email: '', password: '', password_confirmation: "" },
    emailValid: false,
    passwordValid: false,
    password_confirmationValid: false,
    formValid: false,
    submitErrors: this.props.error ? this.props.error : ""
  }

  validateField(fieldName, value) {
    var fieldValidationErrors = this.state.formErrors;
    var emailValid = this.state.emailValid;
    var passwordValid = this.state.passwordValid;
    var password_confirmationValid = this.state.password_confirmationValid;
    switch (fieldName) {
      case 'email':
        emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) ? true : false;
        fieldValidationErrors.email = emailValid ? '' : ' is invalid';
        break;
      case 'password':
        passwordValid = value.length >= 6;
        fieldValidationErrors.password = passwordValid ? '' : ' is too short';
        password_confirmationValid = value === this.state.password_confirmation;
        fieldValidationErrors.password_confirmation = password_confirmationValid ? '' : ' not equal to password';
        break;
      case 'password_confirmation':
        password_confirmationValid = value === this.state.password;
        fieldValidationErrors.password_confirmation = password_confirmationValid ? '' : ' not equal to password';
        break;
      default:
        break;
    }
    this.setState({
      formErrors: fieldValidationErrors,
      emailValid: emailValid,
      passwordValid: passwordValid,
      password_confirmationValid: password_confirmationValid,
      formValid: emailValid && passwordValid && password_confirmationValid
    });
  }

  handleChange = event => {
    this.validateField(event.target.name, event.target.value);
    this.setState({
      [event.target.name]: event.target.value,
      submitErrors: ""
    });
    console.log(event.target.value);
  }

  handleSubmit = event => {
    event.preventDefault()
    this.setState({ submitted: true })
    if (!this.state.formValid) this.setState({ submitted: false });
    if (this.state.formValid) this.props.userSignupFetch(this.state).then(items => {
      this.formError(items);
    });
  }
  formError = event => {
    if (this.props.error) {
      this.setState({ submitted: false, submitErrors: this.props.error })
    };
  }
  passToggle = event => {
    event.preventDefault()
    let pass_toggle = this.state.pass_toggle
    pass_toggle[event.target.name] = !pass_toggle[event.target.name]
    this.setState({ pass_toggle: pass_toggle })
  }

  render() {
    return (
      <div>
        <UserRedirect />
        <div id="register" className="table_header">
          <form onSubmit={this.handleSubmit}>
            <h1><strong> <FormattedMessage id="app.sign_up" /></strong></h1>
            {/* <div className="input-wrap" style={{display: 'none'}}>
        <input
          id="signup-username"
          name='username'
          placeholder='Username'
          value={this.state.username}
          onChange={this.handleChange}
          required
          />
          <label className="placeholder" htmlFor="signup-username">Username</label>
        </div> */}
            <div className="input-wrap">
              <div id="wrap_radio" >
                <label>
                  <input name="role" type="radio" value="0" checked={this.state.role === "0"} onChange={this.handleChange} />
                  <div className="wrap_radio__bg"></div>
                  <span className="wrap_radio__label"><FormattedMessage id="app.apprentice" /></span>
                </label>
                <label>
                  <input name="role" type="radio" value="1" checked={this.state.role === "1"} onChange={this.handleChange} />
                  <div className="wrap_radio__bg"></div>
                  <span className="wrap_radio__label"><FormattedMessage id="app.tutor" /></span>
                </label>
              </div>
            </div>

            <div className="input-wrap">
              <input className="input"
                id="signup-email"
                type='email'
                name='email'
                placeholder='Email'
                value={this.state.email}
                onChange={this.handleChange}
                required
              />
              <label className="placeholder" htmlFor="signup-email"><FormattedMessage id="app.email" /></label>
              <div className="input_error">{this.state.formErrors.email}</div>
            </div>

            <div className="input-wrap">
              <input className="input"
                id="signup-password"
                type={this.state.pass_toggle["signup-password"] ? 'text' : 'password'}
                name='password'
                placeholder='Password'
                value={this.state.password}
                onChange={this.handleChange}
                required
              />
              <label className="placeholder" htmlFor="signup-password"><FormattedMessage id="app.password" /></label>
              <input className={"pass_toggle" + (this.state.pass_toggle["signup-password"] ? ' active' : '')} type="button" name="signup-password" onClick={this.passToggle} value="" />
              <div className="input_error">{this.state.formErrors.password}</div>
            </div>

            <div className="input-wrap">
              <input className="input"
                id="signup-password_confirmation"
                type={this.state.pass_toggle["signup-password_confirmation"] ? 'text' : 'password'}
                name='password_confirmation'
                placeholder='Password again'
                value={this.state.password_confirmation}
                onChange={this.handleChange}
                required
              />
              <label className="placeholder" htmlFor="signup-password_confirmation"><FormattedMessage id="app.password_again" /></label>
              <input className={"pass_toggle" + (this.state.pass_toggle["signup-password_confirmation"] ? ' active' : '')} type="button" name="signup-password_confirmation" onClick={this.passToggle} value="" />
              <div className="input_error">{this.state.formErrors.password_confirmation}</div>
            </div>

            <div className="btn-wrap">
              <button type='submit' className={`b_btn btn-green${this.state.submitted ? " submitted" : ""}`} disabled={!this.state.formValid}>
                <FormattedMessage id="app.sign_up" />
              </button>
            </div>
            <div className="btn-wrap">
              <FormattedMessage id="or" /> <Link to={`/${constants.locale}/login`}><FormattedMessage id="app.login" /></Link>
            </div>
            <div className="input_error_popup">{this.state.submitErrors}</div>
          </form>
        </div>
      </div>
    )
  }
}
const mapStateToProps = state => ({
  error: state.error
})

const mapDispatchToProps = dispatch => ({
  userSignupFetch: userInfo => dispatch(userSignupFetch(userInfo))
})

export default connect(mapStateToProps, mapDispatchToProps)(Signup);